import { Component, OnChanges, OnInit } from '@angular/core';
import { DotButton, DotButtonType, DotPage } from 'dotsdk';
import * as _ from 'lodash';

import { RecommendationService } from '@app/core/recommendation';
import { AccessibilityService } from '@core/accessibility';
import {
  AvailabilityService,
  ComboService,
  ContentValidationService,
  DotButtonService,
} from '@core/acrelec-content';
import { AppSettingsService, ContentService } from '@core/acrelec-external';
import { AllergensService } from '@core/allergens';
import { AnimationsService } from '@core/animations';
import { BasketService } from '@core/basket';
import { DropdownService } from '@core/dropdown';
import { ModalService, ModalType } from '@core/modal';
import { NavigationService, Page, ScreenType } from '@core/navigation';
import { TranslationService } from '@core/translation';
import { NavigationAvailabilityPipe } from '@pipes';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

@Component({
  selector: 'app-order-area',
  templateUrl: './order-area.component.pug',
  styleUrls: ['./order-area.component.scss'],
})
export class OrderAreaComponent implements OnInit, OnChanges {
  private static readonly INACTIVE_BUTTON = '-1';
  private _dropdownButtons: DotButton[] = [];
  private _currentNavItem = -1;
  private readonly isHomePage = true;
  private _page: DotPage;
  private _displayReco = false;

  constructor(
    private _sessionService: AccessibilityService,
    private _allergensService: AllergensService,
    private _appSettings: AppSettingsService,
    private _animation: AnimationsService,
    private _basketService: BasketService,
    private _comboService: ComboService,
    private _contentService: ContentService,
    private _db: DotButtonService,
    private _dropdownService: DropdownService,
    private _modalService: ModalService,
    private _navigationService: NavigationService,
    private _navPipe: NavigationAvailabilityPipe,
    private _popUpService: PopUpService,
    private _t: TranslationService,
    private _contentValidationService: ContentValidationService,
    private _availabilityService: AvailabilityService,
    private _recommendationService: RecommendationService
  ) {}

  ngOnInit(): void {
    this._navigationService.navigationStack.subscribe((page) => {
      if (page.content instanceof DotPage) {
        this._page = page.content;
        this.setNavItemAnimation(this._page);
      } else {
        // TODO: manage errors
      }
      // eslint-disable-next-line no-unused-expressions
      this.title;
    });
  }

  ngOnChanges(): void {
    this._dropdownService.hideDropdown();
  }

  /**
   * Used to specify if the button is hidden
   */
  private get activeDropdownButton(): string {
    return this._dropdownService.activeDropdownButton;
  }

  /**
   * Used to specify if the button is hidden
   * @return true if the page stack has more than one page;
   */
  private get backButtonVisibility(): boolean {
    return (
      !this._navigationService.isNewOrderStack &&
      !(this.title === this.menuTitle)
    );
  }

  /**
   * Check if PMR mode is enabled
   */
  private get isAccessibilityActivated(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  private get isRecoEnabled(): boolean {
    return (
      this._appSettings.isRecommendationEnabled && this.title === this.menuTitle
    );
  }
  /**
   * Get the menu title
   */
  private get menuTitle(): string {
    return this._contentService.mainPage.getTitle(this._t.language);
  }

  /**
   * Get the navigation buttons
   */
  private get navigationButtons(): DotButton[] | null {
    if (this._page) {
      const navButtons = this._page.NavigationButtons.filter(
        (button) => button.Link !== '0'
      );
      return this._navPipe.transform(navButtons);
    }
  }

  /**
   * Get the title
   */
  private get title(): string {
    return this._page.getTitle(this._t.language);
  }

  /**
   * Used to animate the item to the basket
   * @param picture
   * @param event
   */
  private animateItemToBasket(picture: string, event: any): void {
    // Prevent event duplication
    event.stopPropagation();
    const pathToParent =
      event.composedPath().length > 0 ? event.composedPath() : event.path;
    if (!pathToParent) return;

    // find the related button image parent
    const path = pathToParent.find(
      (t: HTMLElement) =>
        t.className &&
        (t.className.includes('ButtonContainer') ||
          t.className.includes('MenuSelection'))
    ) as HTMLElement;

    // This method returns a DOMRect object with eight properties: left, top, right, bottom, x, y, width, height.
    const pictureElements = path.getElementsByClassName('Picture');
    if (!pictureElements.length) return;
    const container = pictureElements[0].getBoundingClientRect();
    if (!container) return;

    // Create a new element
    const newElement = document.createElement('img');
    const parent = document.getElementById('app');

    // Give the new element same informations than its parent
    newElement.style.left = container.x + 'rem';
    newElement.style.top = container.y + 'rem';
    newElement.style.width = container.width + 'rem';
    newElement.src = picture;
    newElement.className = 'NewItemToBasket';

    // Associate child and parent
    parent.appendChild(newElement);

    // Remove child avec animation
    setTimeout(() => {
      parent.removeChild(newElement);
    }, 1000);
  }

  /**
   * Used to display an allergen alert
   */
  private displayAllergensAlert(): // button: DotButton,
  // event: Event
  Promise<void> {
    return new Promise((resolve, reject) => {
      this._popUpService.open({
        component: MessagePopUpComponent,
        componentData: this._t.translate(
          39,
          'Please note this product contains one or several allergens that you selected'
        ),
        rightButtonContent: this._t.translate(28, 'continue'),
        rightButtonCallback: () => {
          this._popUpService.close();
          resolve();
          // this.setAction(button, event);
        },
        leftButtonContent: this._t.translate(23, 'cancel'),
        leftButtonCallback: () => {
          this._dropdownService.hideDropdown();
          this._popUpService.close();
          reject();
        },
      });
    });
  }

  private getNavigationItemFromButton(
    button: DotButton
  ): { button: DotButton; index: number } | null {
    let navItem = null;
    let navItemIndex = null;

    if (!button) return null;

    // check if we have clicked on a nav item button
    navItem = this.navigationButtons.find(
      (navButton) => navButton.Link === button.Link
    );
    navItemIndex = this.navigationButtons.findIndex(
      (navButton) => navButton.Link === button.Link
    );

    // check if we have clicked on the home button
    const mainPage = this._contentService.getPageByButtonLink(button.Link);
    if (mainPage === this._contentService.mainPage) {
      navItem = button;
      navItemIndex = -1;
    }

    // if neither nav item nor home button, return null
    if (!navItem) return null;
    return {
      button: navItem,
      index: navItemIndex,
    };
  }

  private onBack(): void {
    this._dropdownService.hideDropdown();
    this._navigationService.popStack();
  }

  private onNavigationItemClick(event: {
    button: DotButton;
    index: number;
  }): void {
    const navItemLink = event.button && event.button.Link;
    this._dropdownService.hideDropdown();
    this._currentNavItem = event.index;
    let page = this._contentService.mainPage;
    if (navItemLink) {
      page = this._contentService.getPageByButtonLink(navItemLink);
    }
    if (page) {
      const stackPage: Page = {
        type: ScreenType.ORDER_AREA,
        content: page,
      };
      this._navigationService.addStack(stackPage);
    }
  }

  private async onProductButtonClick(click: {
    button: DotButton;
    event: Event;
  }): Promise<void> {
    if (this._db.isDropdown(click.button)) {
      this._dropdownButtons = this._dropdownService.setDropdown(
        click.button,
        this.visibleButtons
      );
      return;
    }
    if (this._allergensService.checkButtonAllergen(click.button)) {
      await this.displayAllergensAlert();
    }
    this.setAction(click.button, click.event);
    this._dropdownService.hideDropdown();
  }

  /**
   * Performs a different action in the navigation depending on the button type
   * @param button button on which the action will be based
   */
  private setAction(button: DotButton, event: Event): void {
    switch (button.ButtonType) {
      case DotButtonType.MENU_BUTTON:
        this.setComboAction(button);
        break;
      case DotButtonType.ITEM_BUTTON:
        this.setItemAction(button, event);
        break;
      case DotButtonType.PAGE_BUTTON:
        this.setPageAction(button);
        break;
      default:
        break;
    }
  }

  private setComboAction(button: DotButton): void {
    this._modalService.setModal({ type: ModalType.PRODUCT, data: button });
  }

  private setItemAction(button: DotButton, event: Event): void {
    const productCopy = _.cloneDeep(button);
    // We are changing the picture and caption to the dropdown parent to avoid displaying the icon
    if (this._dropdownService.isDropdownChild(productCopy)) {
      this._dropdownService.setDropdownChildValues(productCopy);
    }
    this._dropdownService.hideDropdown(); // clearing parent values in any case as we are not ordering the dropdown child's product
    this._modalService.setModal({ type: ModalType.PRODUCT, data: productCopy });
  }

  /**
   * Check if the page displayed is a navigation item. If yes, start the navigation bar's animation.
   * @param page Page taht holds the data to display on the order area
   */
  private setNavItemAnimation(page: DotPage) {
    if (!this.navigationButtons) return;
    const navButton = this.navigationButtons.find(
      (button) => button.Link === this._page.ID
    );
    let navItem = this.getNavigationItemFromButton(navButton);
    if (!navItem && page === this._contentService.mainPage) {
      const mainButton = this._contentService.getProductByLink(page.ID);
      navItem = { button: mainButton, index: -1 };
    }
    if (navItem) this.onNavigationItemClick(navItem);
  }

  // TODO: Verifier avec Clement si cette animation a encore une raison d'etre
  private setSingleItemAction(button: DotButton, event: Event): void {
    button.Selected = true;
    button.quantity = 1;
    // test si le produit a des allergens
    // si oui => affiche pop-up menu
    // si non => ajoute au panier direct
    this._basketService.addProductToBasket(button);
    // declenchement de l'animation  si le produit a ete ajoute
    this.animateItemToBasket(this._db.getPicturePath(button), event);
  }

  private setPageAction(button: DotButton): void {
    // We need to manually start the switch animation as Angular does not start animation
    // when navigation to the same route
    if (!this._appSettings.disabledOrangeTransition) {
      this._animation.pageSwitchTransition();
      setTimeout(() => {
        this._navigationService.addStack({
          type: ScreenType.ORDER_AREA,
          content: button.Page,
        });
      }, 400);
    } else {
      this._navigationService.addStack({
        type: ScreenType.ORDER_AREA,
        content: button.Page,
      });
    }
  }

  private get visibleButtons(): DotButton[] {
    const buttons = this._page.Buttons;
    return buttons.filter(
      (button) =>
        this._contentValidationService.isButtonValid(button) &&
        this._availabilityService.isButtonAvailable(button)
    );
  }
}
