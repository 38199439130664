import { Component, OnInit } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-sat-counter',
  templateUrl: './sat-counter.component.pug',
  styleUrls: ['./sat-counter.component.scss']
})
export class SatCounterComponent implements OnInit {

  constructor(private _t: TranslationService) { }

  ngOnInit(): void {
  }

}
