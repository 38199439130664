import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, Output, EventEmitter } from '@angular/core';
import { DotButton } from 'dotsdk';

import { ModalService, ModalType } from '@app/core/modal';
import { AccessibilityService } from '@core/accessibility';
import { ComboService, DotButtonService } from '@core/acrelec-content';
import { Animations } from '@core/animations';
import { BasketService } from '@core/basket';
import { NavigationService, ScreenType } from '@core/navigation';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

// FIXME: fix the animation when hiding the basket content
@Component({
  selector: 'app-order-footer',
  templateUrl: './order-footer.component.pug',
  styleUrls: ['./order-footer.component.scss'],
  animations: [
    trigger('hideBasketContent', [
      transition(':leave', [useAnimation(Animations.hideBasketContent)]),
    ]),
  ],
})
export class OrderFooterComponent {
  @Output()
  onDisplayCancelOrder: EventEmitter<void> = new EventEmitter();

  private _displayOrder = false;

  constructor(
    private _accessibility: AccessibilityService,
    private _basketService: BasketService,
    private _db: DotButtonService,
    private _navigationService: NavigationService,
    private _sessionService: SessionService,
    private _popUpService: PopUpService,
    private _t: TranslationService
  ) { }

  /**
   * Get the contents of the basket
   */
  private get basketContent(): DotButton[] {
    return this._basketService.products;
  }

  /**
   * Get the quantity of the product
   */
  private get basketQuantity(): number {
    return this._basketService.basketItemsCount;
  }

  /**
   * Adds classes as needed if it
   * is open or in PMR mode
   */
  private get footerClasses(): object {
    return {
      Open: this._displayOrder,
      Pmr: this._accessibility.isAccessibilityActivated,
    };
  }

  /**
   * Get the total price of the order
   */
  private get totalPrice(): number {
    return this._basketService.totalDisplayPrice;
  }

  /**
   * Launches the pop-up warning of order cancellation
   */
  private cancelOrder() {
    this._popUpService.open({
      component: MessagePopUpComponent,
      componentData: this._t.translate(
        38,
        'do you really want to cancel your order ?'
      ),
      rightButtonContent: this._t.translate(33, 'yes'),
      rightButtonCallback: () => {
        this._popUpService.close();
        this._navigationService.addStack({
          type: ScreenType.SCREENSAVER,
          content: null,
        });
      },
      leftButtonContent: this._t.translate(32, 'no'),
      leftButtonCallback: () => {
        this._popUpService.close();
      },
    });
  }

  /**
   * Confirm the order
   */
  private confirmOrder() {
    this._navigationService.addStack({
      type: ScreenType.ORDER_REVIEW,
      content: null,
    });
  }

  /**
   * Prevents visibility of the basket if it is empty
   * @param isEmpty Basket is empty or not
   */
  private isBasketEmpty(isEmpty: boolean | null): void {
    if (isEmpty === null) return;
    if (isEmpty) this._displayOrder = !isEmpty;
  }

  /**
   * Toggle visibility order
   */
  private onDisplayOrder() {
    this._displayOrder = !this._displayOrder;
  }
}
