import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { DOTPlatform, IMockConfiguration } from 'dotsdk';

import { AppModule } from './app/app.module';
import configJson from './assets/config.json';
import { environment } from './environments/environment';

Sentry.init({
  dsn:
    'https://40186b6cc46d4462bedc8c059c82600b@o462546.ingest.sentry.io/5466059',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', '127.0.0.1'],
      routingInstrumentation: Sentry.routingInstrumentation,
      traceXHR: false,
    }),
    new CaptureConsole({
      levels: ['warning', 'error'],
    }),
  ],

  tracesSampleRate: 1.0,
});


if (environment.production) {
  enableProdMode();
}
const environmentMocksPath = '/assets/mocks/atp-environment/';
const payMocksPath = '/assets/mocks/atp-pay/';
const posMocksPath = '/assets/mocks/pos-injector/';

console.log(configJson);
const appConfig = configJson[environment.production ? 'production' : 'development'];

const useMocksForEnvironment = appConfig.useMocksForEnvironment;
const useMocksForPay = appConfig.useMocksForPay;
const useMocksForPos = appConfig.useMocksForPos;

const mockConfiguration: IMockConfiguration = {
  useMocksForEnvironment,
  environmentMocksPath,
  useMocksForPay,
  payMocksPath,
  useMocksForPos,
  posMocksPath,
}

DOTPlatform(mockConfiguration)
.then(value =>
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log('Bootstrap success'))
  .catch(err => console.error(err))
);
