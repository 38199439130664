import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { AnimationsModule } from '@core/animations';
import { BasketModule } from '@core/basket';
import { ModalModule } from '@core/modal';
import { RecommendationModule } from '@core/recommendation';
import { SessionModule } from '@core/session';
import { SuggestiveSellingModule as SuggestiveSellingCoreModule } from '@core/suggestive-selling';
import { TranslationModule } from '@core/translation';
import { PipesModule } from '@pipes';
import { ModifiersModule } from '@ui/modifiers';
import { PopUpModule } from '@ui/pop-up';
import { RecommendationSellingModule } from '@ui/recommendation-selling';
import { SharedModule } from '@ui/shared';
import { SuggestiveSellingModule } from '@ui/suggestive-selling';

import {
  AddComboButtonComponent,
  ComboButtonComponent,
  ComboComponent,
  ComboFooterComponent,
  ComboHeaderComponent,
  ComboListComponent,
} from './components/combo';
import { ProductModalComponent } from './components/product-modal.component';
import { ProductTileComponent } from './components/product/product-tile/product-tile.component';
import { ProductComponent } from './components/product/product.component';

@NgModule({
  declarations: [
    AddComboButtonComponent,
    ComboButtonComponent,
    ComboComponent,
    ComboFooterComponent,
    ComboHeaderComponent,
    ComboListComponent,
    ProductComponent,
    ProductModalComponent,
    ProductTileComponent,
  ],
  imports: [
    AccessibilityModule,
    AcrelecContentModule,
    AcrelecExternalModule,
    AnimationsModule,
    BasketModule,
    CommonModule,
    InlineSVGModule,
    ModalModule,
    ModifiersModule,
    PipesModule,
    PopUpModule,
    RecommendationModule,
    RecommendationSellingModule,
    SessionModule,
    SharedModule,
    SuggestiveSellingModule,
    SuggestiveSellingCoreModule,
    TranslationModule,
  ],
  exports: [ProductModalComponent],
})
export class ProductModalModule { }
