import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SuggestiveSellingModule } from '@core/suggestive-selling';
import { PipesModule } from '@pipes';
import { ModifiersModule } from '@ui/modifiers';
import { SharedModule } from '@ui/shared';

import { RecommendationSellingComponent } from './components/recommendation-selling.component';

@NgModule({
  declarations: [RecommendationSellingComponent],
  imports: [
    CommonModule,
    ModifiersModule,
    PipesModule,
    SharedModule,
    SuggestiveSellingModule,
  ],
  exports: [RecommendationSellingComponent],
})
export class RecommendationSellingModule { }
