import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, Observable } from 'rxjs';

import { Modal } from '../types/Modal';

/**
 * The modal service is simply made of two [BehaviorSubject] that send data to the observers
 * in order to display or hide modals and pop-ups.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _modalSubject = new BehaviorSubject<Modal | null>(null);
  private _popUpSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  /**
   * Returns an Observable to react to the changes in the modal displayed. If null, modal should close if displayed.
   * @returns An Observable to subscribe to
   */
  get modal$(): Observable<Modal | null> {
    return this._modalSubject.asObservable();
  }

  /**
   * Returns an Observable to react to the changes in the popup displayed. If null, pop-ups should close if displayed.
   * @returns An Observable to subscribe to
   */
  get popup$(): Observable<boolean> {
    return this._popUpSubject.asObservable();
  }

  /**
   * Close the modals by sending null to the observers
   */
  closeModal(): void {
    this._modalSubject.next(null);
    Sentry.setContext('modal', null);
  }

  /**
   * Close the pop-ups by sending null to the observers
   */
  closePopUp(): void {
    this._popUpSubject.next(false);
    Sentry.setContext('popup', null);
  }

  /**
   * Displays a modal by sending its type and data to the observers
   * @param modal Modal to display
   */
  setModal(modal: Modal) {
    this._modalSubject.next(modal);
    Sentry.setContext('modal', null);
    Sentry.setContext('modal', {
      name: modal.type,
    });
  }

  /**
   * Displays a pop-up by sending true to the observers
   */
  setPopUp() {
    this._popUpSubject.next(true);
  }
}
