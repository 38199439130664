import { Component, OnDestroy, OnInit } from '@angular/core';

import { PaymentService } from '@core/payment';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.pug',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {
  constructor(
    public _t: TranslationService,
    private _paymentService: PaymentService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}
  /**
   * Get the current order number
   */
  private get orderNumber(): number {
    return this._paymentService.getOrderNumber();
  }
}
