import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { AvailabilityService, DotButtonService } from '@core/acrelec-content';
import { AppSettingsService } from '@core/acrelec-external';
import { NavigationService } from '@core/navigation';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-product-button',
  templateUrl: './product-button.component.pug',
  styleUrls: ['./product-button.component.scss'],
})
export class ProductButtonComponent {
  @Input() index: number;
  @Input() name: string;
  @Input() background: string | null;
  @Input() isRecommendation: boolean;
  @Input() picture: string;
  @Input() price: number;
  @Input() calories?: number;
  @Input() tags?: string[];
  @Input() hasModifiers?: boolean;
  @Input() isSelected: boolean;
  @Input() shouldDisplayBasketAnimation: boolean;
  @Input() buttonDisabled: boolean;

  @Output('buttonClick') onProductButtonClick = new EventEmitter<Event>();

  constructor(
    private _appSettings: AppSettingsService,
    private _db: DotButtonService,
    private _navigationService: NavigationService,
    private _sessionService: AccessibilityService,
    private _availabilityService: AvailabilityService,
    private _t: TranslationService
  ) {}

  /**
   * Used to get the status of the PMR mode
   */
  private get isAccessibilityActivated(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Used to know if the button is selected
   */
  private get isButtonSelected(): boolean {
    return this.isSelected;
  }

  /**
   * Used to know if the button is selected
   */
  private get isButtonDisabled(): boolean {
    return this.buttonDisabled;
  }

  /**
   * @returns the first product's tag if any
   */
  private get firstTag(): string | null {
    const tags = this.tags.filter((tag) => tag !== 'dropdown');
    if (tags.length >= 1) {
      return tags.first();
    }
    return null;
  }

  private get showCaloriesOnItem(): boolean {
    return this._appSettings.showCaloriesOnItem;
  }

  /**
   * Used when a button is clicked
   * @param event
   */
  private onButtonClick(event: Event): void {
    this.onProductButtonClick.emit(event);
  }
}
