import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-basket-product',
  templateUrl: './basket-product.component.pug',
  styleUrls: ['./basket-product.component.scss'],
})
export class BasketProductComponent {
  @Input() isChangeable: boolean;
  @Input() item: string;
  @Input() picture: string;
  @Input() quantity: number;
  @Input() price: number;
  @Input() subItems: string[];

  @Output() onAddOne = new EventEmitter();
  @Output() onRemoveOne = new EventEmitter();
  @Output() onChangeProduct = new EventEmitter();
  @Output() onDeleteProduct = new EventEmitter();

  private _language: string;

  constructor(
    private _accessibilityService: AccessibilityService,
    private _t: TranslationService
  ) {
    this._language = _t.language;
  }

  /**
   * Return the total price of the product quantities
   */
  private get productTotalPrice(): string {
    return (this.quantity * this.price).toFixed(2);
  }

  /**
   * Add one more product
   */
  private addOne(): void {
    this.onAddOne.emit();
  }

  /**
   * Change the product
   */
  private changeProduct(): void {
    this.onChangeProduct.emit();
  }

  /**
   * Delete the product
   */
  private deleteProduct(): void {
    this.onDeleteProduct.emit();
  }

  /**
   * Remove a product
   */
  private removeOne(): void {
    this.onRemoveOne.emit();
  }
}
