import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AvailabilityService } from '@app/core/acrelec-content';
import { AccessibilityService } from '@core/accessibility';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-single-modifier-tile',
  templateUrl: './single-modifier-tile.component.pug',
  styleUrls: ['./single-modifier-tile.component.scss'],
})
export class SingleModifierTileComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() maxQuantity: number;
  @Input() name: string;
  @Input() picture: string;
  @Input() price: string;
  @Input() quantity: number;
  @Input() selected: boolean;
  @Input() buttonDisabled: boolean;

  @Output() onAdd = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  constructor(
    private _sessionService: AccessibilityService,
    private _t: TranslationService,
    private _availabilityService: AvailabilityService
  ) {}

  ngOnInit() {}

  private get buttonSvg() {
    if (this.selected) {
      return '/assets/images/icons/button-minus.svg';
    }
    return '/assets/images/icons/button-plus.svg';
  }

  private get isButtonDisabled(): boolean {
    return this.isDisabled && !this.selected;
  }

  private get isAccessibilityActivated(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * If a product is selected, deselects it, otherwise selects it
   */
  private select() {
    if (this.selected) {
      this.onRemove.emit();
      return;
    }
    if (this.isDisabled) return;
    this.onAdd.emit();
  }
}
