import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DotButton } from 'dotsdk';
import { orderBy } from 'lodash';

import { AccessibilityService } from '@core/accessibility';
import { AvailabilityService, DotButtonService } from '@core/acrelec-content';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-size-dropdown',
  templateUrl: './size-dropdown.component.pug',
  styleUrls: ['./size-dropdown.component.scss'],
})
export class SizeDropdownComponent implements OnInit {
  @Input() menuButtons: DotButton[];
  @Input() buttonDisabled: boolean;

  // @Output() onButtonClick = new EventEmitter<DotButton>();
  @Output() onButtonClick = new EventEmitter<{
    button: DotButton;
    event: Event;
  }>();

  constructor(
    private _a: AccessibilityService,
    private _db: DotButtonService,
    private _t: TranslationService,
    private _availabilityService: AvailabilityService
  ) {}

  ngOnInit(): void {}

  get menuSizeButtons(): DotButton[] {
    const orderedMenuSizeButtons = orderBy(
      this.menuButtons,
      ['Order'],
      ['asc']
    ).filter((button) => this._availabilityService.isButtonAvailable(button));
    return orderedMenuSizeButtons;
  }

  /**
   * Used when a button is click
   * @param event
   * @param button the button
   */
  buttonClick(event: Event, button: DotButton): void {
    this.onButtonClick.emit({ button, event });
  }

  private isButtonDropdownDisabled(button: DotButton): boolean {
    return this._availabilityService.isButtonDisabled(button);
  }
}
