export enum Symbols {
  BracketIn = '(',
  BracketOut = ')',
  LogicalAnd = 'AND',
  LogicalOr = 'OR',
  LogicalNot = 'NOT',
  LogicalMultiply = '*',

  LogicAnd = '&amp;',
  LogicOr = '|',
}
