import { Injectable } from '@angular/core';

import { SessionService } from '@core/session';

/**
 * The AccessibilityService is simply a global boolean shared in the app to set the accessibility mode
 */
@Injectable({
  providedIn: 'root',
})
export class AccessibilityService {
  public isAccessibilityActivated = false;

  /**
   * Subscribes to the session observable and disables the accessibility mode on reset
   */
  constructor(private _sessionService: SessionService) {
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) this.isAccessibilityActivated = false;
    });
  }

  /**
   * Toggle the accessibility mode on or off
   */
  public toggleAccessibility() {
    this.isAccessibilityActivated = !this.isAccessibilityActivated;
  }

  /**
   * @return true if the accessibility mode is enabled
   */
  public get accessibilityStatus() {
    return this.isAccessibilityActivated;
  }

  public resetAccessibility() {
    this.isAccessibilityActivated = false;
  }
}
