import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { EnvironmentEnum } from 'acrelec-recommendation-sdk';
import {
  AtpApplicationSettings,
  AtpEnvironmentService,
  IKioskTags,
  IStoreTags,
  PosServingLocation,
} from 'dotsdk';
import { v4 as generateId } from 'uuid';

import { SessionService } from '@core/session';
import { environment } from '@environment';


@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  // hardcoded configs:
  public readonly environmentMocksPath = '/assets/mocks/atp-environment/';
  public useMocksForEnvironment: boolean;

  public readonly payMocksPath = '/assets/mocks/atp-pay/';
  public useMocksForPay: boolean;

  public readonly posMocksPath = '/assets/mocks/pos-injector/';
  public useMocksForPos: boolean;

  public serviceType: PosServingLocation;

  // App BundleSettings:
  public allergensPosterUrl: string;
  public currentLanguage: string;
  public currency: string;
  public acreBridgeAssets: string;
  public sharedFolderPath: string;
  public isRecommendationEnabled: boolean;
  public isEnrichmentEnabled: boolean;
  public posInjectorPath: string;
  public posInjectorPathTest: string;
  public backupPosInjectorPath: string;
  public backupPosInjectorPathTest: string;
  public kioskId: number;

  public disabledEatIn: boolean;
  public disabledOrangeTransition: boolean;
  public disabledImageBackground: boolean;
  public disableAllergensMore: boolean;
  public digitsNumberSatCommand: number;

  public enableGapPriceCalculation: boolean;
  public showCaloriesOnItem: boolean;
  public showCaloriesOnPage: boolean;

  public hidePaymentMethods: boolean;
  public selectWorkflow: string;
  public enableCounterPayment: boolean;
  public serviceAtTable: boolean;

  public selectFont: string;

  public kioskTags: string[];
  public storeTags: string[];

  public checkInterval: number;

  public inactivityWarningTimer: number;
  public inactivityCloseTimer: number;

  public recommendationEndpoint: string;

  public isEnvironment: EnvironmentEnum;

  public hasManualVouchers: boolean;
  public hasScannerVoucher: boolean;
  public scannerTimeout: number;

  // App BundleSettings that comes from environment details:
  public storeName: string;
  public storeCode: string;

  //
  public storeId: number;
  public publicToken: string;

  constructor(
    private httpClient: HttpClient,
    private _sessionService: SessionService
  ) { }

  /**
   * Initialize app settings
   */
  public async initialize() {
    // Load Apps Settings:
    const configJson = await this.httpClient
      .get<any>('/assets/config.json')
      .toPromise();

    const appConfig =
      configJson[environment.production ? 'production' : 'development'];

    this.useMocksForEnvironment = appConfig.useMocksForEnvironment;
    this.useMocksForPay = appConfig.useMocksForPay;
    this.useMocksForPos = appConfig.useMocksForPos;

    // Load Apps BundleSettings from ATP with the use of AtpEnvironmentService
    // Use MockOptions object to set it's (not) use of mocks:
    // const mocksOptions: MocksOptions = {
    //   useMocks: this.useMocksForEnvironment,
    //   mocksFolder: this.environmentMocksPath,
    // };

    // AtpEnvironmentService.getInstance().mocksOptions = {
    //   useMocks: mocksOptions.useMocks,
    //   mocksFolder: mocksOptions.mocksFolder,
    // };

    // The actual BS load:
    await this.loadBS();

    // Load tags:
    await this.loadTags();

    this._sessionService.initializeAtpSession();
  }

  public async setupSentry() {
    const userId = generateId();

    console.log('Starting new session with id:', userId);

    const environment = await AtpEnvironmentService.getInstance().getEnvironmentDetails();
    const bundleSettings = await AtpEnvironmentService.getInstance().getBundleSettings();

    Sentry.setTag('store_name', environment.StoreDetails.Name);
    Sentry.setTag('store_code', environment.StoreDetails.Code);
    Sentry.setTag('nest_name', environment.NestDetails.Name);
    Sentry.setTag('nest_id', environment.NestIdentifier);

    Sentry.setContext('bundleSettings', bundleSettings);
    Sentry.setContext('environment', environment);

    Sentry.setUser(null);
    Sentry.setUser({
      id: userId,
    });
  }

  /**
   * Called at the beginning of App Load
   */
  protected async loadTags() {
    // atpEnvironmentService.getKioskTags and getStoreTags contains validators and it may return an error, make sure you catch it:
    // for this brief example, we return null. For a production App you will want to log this error
    // const kioskSettings: IKioskSettings = await AtpEnvironmentService.getInstance()
    //   .getKioskTags()
    //   .catch((e: Error) => null);
    const kioskSettings: IKioskTags = AtpApplicationSettings.getInstance()
      .kioskTagsJson;
    // const storeSettings: IStoreSettings = await AtpEnvironmentService.getInstance()
    //   .getStoreTags()
    //   .catch((e: Error) => null);
    const storeSettings: IStoreTags = AtpApplicationSettings.getInstance()
      .storeTagsJson;

    this.kioskTags =
      kioskSettings && kioskSettings.Tags ? kioskSettings.Tags : [];
    // this.storeTags =
    //   storeSettings && storeSettings.Tags ? storeSettings.Tags : [];
    this.storeTags =
      storeSettings && storeSettings.StoreTags ? storeSettings.StoreTags : [];
  }

  /**
   * Called at the begining of App Load and each time BS change
   */
  private async loadBS(): Promise<boolean> {
    // atpEnvironmentService.getBundleSettings contains validators and it may return an error, make sure you catch it:
    // for this brif example, we return null. For a production App you will want to log this error
    // const appBundleSettings: IBundleSettings | void = await AtpEnvironmentService.getInstance()
    //   .getBundleSettings()
    //   .catch((e: Error) => console.error(e.message));
    const appBundleSettings = AtpApplicationSettings.getInstance()
      .bundleSettingsJson;
    if (appBundleSettings) {
      this.allergensPosterUrl = appBundleSettings.allergensPosterUrl
        ? `${this.sharedFolderPath}/${appBundleSettings.allergensPosterUrl}`
        : `${this.sharedFolderPath}/assets/skins/fullHD/DAHComponent/allergens/allergen1.png`;
      this.kioskId = appBundleSettings.kioskId || 1;
      this.currentLanguage = appBundleSettings.defaultLanguage;
      this.currency = appBundleSettings.currency;
      this.serviceAtTable = appBundleSettings.serviceAtTable;
      this.showCaloriesOnItem = appBundleSettings.showCaloriesOnItem;
      this.showCaloriesOnPage = appBundleSettings.showCaloriesOnPage;
      this.hidePaymentMethods = appBundleSettings.hidePaymentMethods;
      this.sharedFolderPath = appBundleSettings.sharedFolderPath;
      this.inactivityWarningTimer = appBundleSettings.inactivityWarningTimer;
      this.inactivityCloseTimer = appBundleSettings.inactivityCloseTimer;
      this.acreBridgeAssets = `${appBundleSettings.sharedFolderPath}/assets`;
      this.enableCounterPayment = appBundleSettings.enableCounterPayment;
      this.posInjectorPath = `${appBundleSettings.posInjectorPath}/pos/transaction`;
      this.backupPosInjectorPath = `${appBundleSettings.backupPosInjectorPath}/pos/transaction`;
      this.posInjectorPathTest = `${appBundleSettings.posInjectorPath}/pos/testconnect`;
      this.backupPosInjectorPathTest = `${appBundleSettings.backupPosInjectorPath}/pos/testconnect`;
      this.isRecommendationEnabled = appBundleSettings.isRecommendationEnabled;
      this.isEnrichmentEnabled = appBundleSettings.isEnrichmentEnabled;
      this.selectWorkflow = appBundleSettings.selectWorkflow || '33-3';
      this.selectFont =
        appBundleSettings.selectFont || 'BK Block Berthold Condensed';
      this.recommendationEndpoint =
        appBundleSettings.recommendationEndpoint || 'http://localhost:6060';
      this.isEnvironment = appBundleSettings.isEnvironment;
      this.enableGapPriceCalculation =
        appBundleSettings.enableGapPriceCalculation;
      this.hasManualVouchers = appBundleSettings.hasManualVouchers;
      this.hasScannerVoucher = appBundleSettings.hasScannerVoucher;
      this.scannerTimeout = appBundleSettings.scannerTimeout;
      this.checkInterval = appBundleSettings.checkInterval || 1000 * 10;
      this.disabledEatIn = appBundleSettings.disabledEatIn;
      this.digitsNumberSatCommand = appBundleSettings.digitsNumberSatCommand;
      this.disabledOrangeTransition =
        appBundleSettings.disabledOrangeTransition;
      this.disabledImageBackground = appBundleSettings.disabledImageBackground;
      this.disableAllergensMore = appBundleSettings.disableAllergensMore;
      this.storeId = appBundleSettings.storeId;
      this.publicToken = appBundleSettings.publicToken;
      const envDetails = AtpApplicationSettings.getInstance()
        .environmentDetailsJson;
      if (envDetails) {
        this.storeName = envDetails.StoreDetails.Name;
        this.storeCode = envDetails.StoreDetails.Code;
      }
      // this.loadFont();
    } else {
      // TODO: Could not get BS, should display error
      return false;
    }
    return true;
  }

  // Load a new font
  private loadFont() {
    document.documentElement.style.setProperty('--bk-font', this.selectFont);
  }
}
