import { Pipe, PipeTransform } from '@angular/core';

import { ContentValidationService } from '@core/acrelec-content';
import { CustomAllergen } from '@core/allergens';

@Pipe({
  name: 'allergensContent'
})
export class AllergensContentPipe implements PipeTransform {

  constructor(private _contentValidationService: ContentValidationService){};

  /**
   * Returns a list of allergens filtered by the validity of their name
   */
  transform(allergens: CustomAllergen[]): CustomAllergen[] {
    return allergens.filter(allergen => this._contentValidationService.isAllergenValid(allergen));
  }

}
