import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecContentModule } from '@core/acrelec-content';
import { AllergensModule } from '@core/allergens';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { PipesModule } from '@pipes';
import { PopUpModule } from '@ui/pop-up';
import { SharedModule } from '@ui/shared';

import { ModifierTileComponent } from './components/modifier-tile/modifier-tile.component';
import { MultipleModifierTileComponent } from './components/modifier-tile/multiple-modifier-tile\
/multiple-modifier-tile.component';
import { SingleModifierTileComponent } from './components/modifier-tile/single-modifier-tile/\
single-modifier-tile.component';
import { ModifiersListComponent } from './components/modifiers-list.component';

@NgModule({
  declarations: [
    ModifierTileComponent,
    MultipleModifierTileComponent,
    SingleModifierTileComponent,
    ModifiersListComponent,
  ],
  imports: [
    AccessibilityModule,
    AcrelecContentModule,
    AllergensModule,
    CommonModule,
    InlineSVGModule,
    PopUpModule,
    SessionModule,
    SharedModule,
    TranslationModule,
    PipesModule,
  ],
  exports: [ModifiersListComponent],
})
export class ModifiersModule {}
