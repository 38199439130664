import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AcrelecExternalModule } from '@core/acrelec-external';
import { ModalModule } from '@core/modal';
import { SessionModule } from '@core/session';

@NgModule({
  declarations: [],
  imports: [
    AcrelecExternalModule,
    CommonModule,
    ModalModule,
    RouterModule,
    SessionModule,
  ],
})
export class NavigationModule {}
