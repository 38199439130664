import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { AllergensModule } from '@core/allergens';
import { AnimationsModule } from '@core/animations';
import { BasketModule } from '@core/basket';
import { DropdownModule } from '@core/dropdown';
import { ModalModule } from '@core/modal';
import { NavigationModule } from '@core/navigation';
import { PaymentModule } from '@core/payment';
import { RecommendationModule } from '@core/recommendation';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { NavigationAvailabilityPipe, PipesModule } from '@pipes';
import { AccessibilityModule as AccessibilityUiModule } from '@ui/accessibility';
import { BasketModule as BasketUiModule } from '@ui/basket';
import { ModifiersModule } from '@ui/modifiers';
import { PopUpModule } from '@ui/pop-up';
import { SharedModule } from '@ui/shared';

import {
  BasketButtonComponent,
  OrderAreaComponent,
  OrderFooterButtonsComponent,
  OrderFooterComponent,
  OrderHeaderComponent,
  OrderInfosComponent,
} from './components/order-area';
import { ProductButtonComponent } from './components/order-area/product-list/product-button/product-button.component';
import { ProductListComponent } from './components/order-area/product-list/product-list.component';
import { RecommendationListComponent } from './components/order-area/recommendation-list/recommendation-list.component';
import { SizeDropdownItemComponent } from './components/order-area/size-dropdown/size-dropdown-item/size-dropdown-item.component';
import { SizeDropdownComponent } from './components/order-area/size-dropdown/size-dropdown.component';
import { OrderComponent } from './components/order.component';
import { OrderReviewComponent } from './components/review/order-review.component';
import { OrderRoutingModule } from './order-routing.module';

@NgModule({
  declarations: [
    BasketButtonComponent,
    SizeDropdownComponent,
    SizeDropdownItemComponent,
    OrderAreaComponent,
    OrderInfosComponent,
    OrderFooterButtonsComponent,
    OrderFooterComponent,
    OrderHeaderComponent,
    OrderReviewComponent,
    ProductButtonComponent,
    ProductListComponent,
    RecommendationListComponent,
    OrderComponent,
  ],
  imports: [
    AccessibilityModule,
    AccessibilityUiModule,
    AcrelecContentModule,
    AcrelecExternalModule,
    AllergensModule,
    AnimationsModule,
    BasketModule,
    BasketUiModule,
    CommonModule,
    DropdownModule,
    InlineSVGModule,
    ModalModule,
    ModifiersModule,
    NavigationModule,
    OrderRoutingModule,
    PipesModule,
    PopUpModule,
    PaymentModule,
    RecommendationModule,
    SessionModule,
    SharedModule,
    TranslationModule,
  ],
  exports: [],
  providers: [NavigationAvailabilityPipe],
})
export class OrderModule { }
