import { transition, trigger, useAnimation } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ContextRequest } from 'acrelec-recommendation-sdk';
import { DotButton, DotButtonType } from 'dotsdk';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { AccessibilityService } from '@core/accessibility';
import {
  ComboService,
  DotButtonService,
  ModifiersService,
  PriceCalculationService,
} from '@core/acrelec-content';
import { AppSettingsService, ContentService } from '@core/acrelec-external';
import { Animations } from '@core/animations';
import { BasketService } from '@core/basket';
import { ModalService, ModalType } from '@core/modal';
import { RecommendationService } from '@core/recommendation';

@Component({
  selector: 'app-basket-products',
  templateUrl: './basket-products.component.pug',
  styleUrls: ['./basket-products.component.scss'],
  animations: [
    trigger('deleteProduct', [
      transition(':leave', [useAnimation(Animations.deleteBasketProduct)]),
    ]),
    trigger('displayBasketProducts', [
      transition(':enter', [useAnimation(Animations.displayBasketProducts)]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [useAnimation(Animations.fadeOut)]),
    ]),
  ],
})
export class BasketProductsComponent implements OnDestroy, OnInit {
  @Input() products: DotButton[];
  @Input() totalPrice: number;
  @Output() isEmpty? = new EventEmitter<boolean | null>(null);
  private readonly RECOMMENDATION_COUNT = 16;
  private recommendationSubject = new BehaviorSubject<DotButton | null>(null);
  private recommendation: DotButton | undefined;

  constructor(
    private _accessibility: AccessibilityService,
    private _appSettings: AppSettingsService,
    private _basketService: BasketService,
    private _comboService: ComboService,
    private _db: DotButtonService,
    private _modalService: ModalService,
    private _priceService: PriceCalculationService,
    private _recommendationService: RecommendationService,
    private _modifiersService: ModifiersService,
    private _contentService: ContentService,
    private _dotButtonService: DotButtonService
  ) {
    this.recommendationSubject.subscribe(
      (recommendation) => (this.recommendation = recommendation)
    );
  }

  async ngOnInit(): Promise<void> {
    this.updateRecommendation();
  }

  async ngOnUpdate(): Promise<void> {
    this.updateRecommendation();
  }

  ngOnDestroy(): void {
    // Needed because the component emits false on ngOnDestroy?
    this.isEmpty.emit(null);
  }

  get recommendation$(): Observable<DotButton | null> {
    return this.recommendationSubject.asObservable();
  }

  private get isRecoEnabled(): boolean {
    return this._appSettings.isRecommendationEnabled;
  }

  private async addBasket(): Promise<void> {
    this.setAction(this.recommendation);
    // this._basketService.addProductToBasket(this.recommendation);
    // this.updateRecommendation();
  }

  /**
   * Check if the product is modifiable
   * @param product Product to check
   */
  private isChangeable(product: DotButton): boolean {
    if (this._db.hasModifiers(product) || this._db.isCombo(product)) {
      return true;
    }
    return false;
  }

  /**
   * Change the product
   * @param product The product has modified
   */
  private changeClick(product: DotButton): void {
    this._modalService.setModal({ type: ModalType.PRODUCT, data: product });
  }

  /**
   * Check if the basket is empty
   */
  private checkIfEmpty(): void {
    // check emptiness only if it matters
    if (!this.isEmpty) return;
    const basketProducts = this._basketService.products;
    if (!basketProducts || basketProducts.length <= 0) {
      this.isEmpty.emit(true);
    }
    this.isEmpty.emit(false);
  }

  /**
   * Decrease the quantity of product
   * @param product the product has modified
   */
  private decreaseQuantity(product: DotButton): void {
    this._basketService.decreaseQuantity(product);
    this.checkIfEmpty();
  }

  /**
   * Removes a product from the order
   * @param product The product removed
   */
  private deleteClick(product: DotButton): void {
    this._basketService.removeBasketItem(product);
    this._recommendationService.updateOrderItem(product);

    this.checkIfEmpty();
  }

  private getModifiersStrings(button: DotButton): string[] | null {
    if (this._db.isCombo(button)) {
      return this._comboService.getModifiersStrings(button);
    }
    return this._modifiersService.getModifierStringsFromProduct(button);
  }

  /**
   * Increase the quantity of product
   * @param product the product has modified
   */
  private increaseQuantity(product: DotButton): void {
    this._basketService.increaseQuantity(product, true);
  }

  private async updateRecommendation(context?: ContextRequest): Promise<void> {
    let recommendation: DotButton;

    try {
      const recommendations = await this._recommendationService.getRecommendationProducts(
        this.RECOMMENDATION_COUNT,
        context
      );
      if (recommendations && recommendations.length) {
        recommendation = recommendations.first();
      }
      this.recommendation = recommendation;
      this.recommendationSubject.next(recommendation);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Performs a different action in the navigation depending on the button type
   * @param button button on which the action will be based
   */
   private setAction(button: DotButton): void {
    switch (button.ButtonType) {
      case DotButtonType.MENU_BUTTON:
        this.setItemAction(button);
        break;
      case DotButtonType.ITEM_BUTTON:
        this.setItemAction(button);
        break;
      default:
        break;
    }
  }

  private setItemAction(button: DotButton): void {
    const parentPage = this._contentService.getPageByButtonLink(
      (button as any).parent
    );

    const productCopy = _.cloneDeep(button);

    this._modalService.closeModal();

    if (parentPage && this._dotButtonService.isDropdownPage(parentPage)) {
      setTimeout(() => {
        this._modalService.setModal({
          type: ModalType.RECOMMENDATION_SELECT,
          data: {
            initialProduct: productCopy,
            products: parentPage.Buttons.map((button) => this._recommendationService.getCatalogValues(button)),
          },
        });
      });
    } else {
      setTimeout(() => {
        this._modalService.setModal({
          type: ModalType.PRODUCT,
          data: productCopy,
        });
      });
    }
  }
}
