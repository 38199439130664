import {
  animateChild,
  query,
  trigger,
  transition,
  useAnimation,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { AppSettingsService } from '@core/acrelec-external';
import { Animations } from '@core/animations';
import { ModalService } from '@core/modal';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-calories-modal',
  templateUrl: './calories-modal.component.pug',
  styleUrls: ['./calories-modal.component.scss'],
  animations: [
    trigger('displayModal', [
      transition(':enter', [
        useAnimation(Animations.inflateModal, { params: { time: '300ms' } }),
        query('@displayChild', animateChild({ delay: '0' })),
      ]),
    ]),
    trigger('displayChild', [
      transition(':enter', [
        useAnimation(Animations.showModalChild, {
          params: { time: '300ms' },
        }),
      ]),
    ]),
  ],
})
export class CaloriesModalComponent implements OnInit {
  constructor(
    private _accessibility: AccessibilityService,
    private _appSettingsService: AppSettingsService,
    private _modalService: ModalService,
    private _t: TranslationService
  ) {}

  ngOnInit() {}

  get caloriesPosterUrl(): string {
    return this._appSettingsService.allergensPosterUrl;
  }

  /**
   * Close calories modal
   */
  close() {
    this._modalService.closeModal();
  }
}
