import { Component } from '@angular/core';
import { DotBanner } from 'dotsdk';

import { ContentService } from '@core/acrelec-external';

@Component({
  selector: 'app-accessibility-banner',
  templateUrl: './accessibility-banner.component.pug',
  styleUrls: ['./accessibility-banner.component.scss'],
})
export class AccessibilityBannerComponent {
  private _navigationItems: DotBanner[];

  constructor(private _contentService: ContentService) {
    this._navigationItems = this._contentService.getBannersData(
      'DisabilityBanner'
    ).filter(slider => !!slider.Image);;
  }
}
