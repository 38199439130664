import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { TranslationModule } from '@core/translation';
import { AccessibilityModule as AccessibilityUiModule } from '@ui/accessibility';
import { SatKeyboardModule } from '@ui/sat-keyboard'

import { SatActivateComponent } from './components/sat-activate/sat-activate.component';
import { SatCounterComponent } from './components/sat-counter/sat-counter.component';
import { SatFlagNumberComponent } from './components/sat-flag-number/sat-flag-number.component';
import { SatSuccessComponent } from './components/sat-success/sat-success.component';
import { SatUnavailableComponent } from './components/sat-unavailable/sat-unavailable.component';
import { SatRoutingModule } from './sat-routing.module';
import { SatComponent } from './sat.component';

@NgModule({
  declarations: [
    SatComponent,
    SatActivateComponent,
    SatFlagNumberComponent,
    SatSuccessComponent,
    SatCounterComponent,
    SatUnavailableComponent
  ],
  imports: [
    AccessibilityModule,
    AccessibilityUiModule,
    AcrelecExternalModule,
    CommonModule,
    InlineSVGModule,
    SatRoutingModule,
    SatKeyboardModule,
    TranslationModule
  ],
  exports: [SatActivateComponent],
})
export class SatModule { }
