import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { AnimationsModule } from '@core/animations';
import { ModalModule } from '@core/modal';
import { SharedModule } from '@ui/shared';

import { CaloriesModalComponent } from './components/calories-modal.component';

@NgModule({
  declarations: [CaloriesModalComponent],
  imports: [
    AccessibilityModule,
    AcrelecExternalModule,
    AnimationsModule,
    CommonModule,
    ModalModule,
    SharedModule,
  ],
  exports: [CaloriesModalComponent],
})
export class CaloriesModule {}
