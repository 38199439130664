import { Injectable } from '@angular/core';
import { DotButton } from 'dotsdk';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { LocalBasketService } from '@core/basket';
import { RecommendationService } from '@core/recommendation';

/**
 * The suggestive selling service has private basket to hold promotion products before adding them
 * to the order basket if the customer wishes so.
 */
@Injectable({
  providedIn: 'root',
})
export class RecommendationSalesService {
  private readonly RECOMMENDATION_COUNT = 16;

  constructor(
    private _localBasket: LocalBasketService,
    private _recommendationService: RecommendationService,
  ) { }

  /**
   * @returns True if suggestive basket is empty
   */
  get isBasketEmpty(): boolean {
    return this._localBasket.isBasketEmpty;
  }

  /**
   * @returns An Observable to watch the products added or removed from the suggestive selling basket
   */
  get productBasket$(): Observable<DotButton[]> {
    return this._localBasket.productBasket$;
  }

  /**
   * Add a DotButton product to the local suggestive sales basket
   * @param product Product to add to the basketProduct
   */
  addProduct(product: DotButton): void {
    this._localBasket.addProduct(product);
  }

  /**
   * Add the products currently in the suggestive selling basket to the global order basket
   * by cloning them to avoid interference with the global product DotButton
   */
  addProductsToBasket(): void {
    this._localBasket.addToGlobalBasket();
  }

  /**
   * Empties suggestive selling basket
   */
  clearBasket(): void {
    this._localBasket.clearBasket();
  }

  /**
   * Get all suggestions, clone them to avoid side-effects * and add them to
   * the local suggestion basket with a quantity of 0
   * @param product Product for which to initialize the suggestions
   */
  async initializeRecommendations(): Promise<boolean> {
    try {
      const recommendationProducts = await this._recommendationService.getRecommendationProducts(
        this.RECOMMENDATION_COUNT
      );
      if (!recommendationProducts || !recommendationProducts.length) return false;
      this._localBasket.products = recommendationProducts.splice(0, 6);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Remove a DotButton product from the local suggestive sales basket
   * @param product Product to remove from the basketProduct
   */
  removeProduct(product: DotButton): void {
    this._localBasket.removeProduct(product);
  }
}
