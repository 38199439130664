import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeAvailabilityService {

  constructor() { }

  /**
   * @param startTime start time of availability period
   * @param stopTime stop time of availability period
   * @param currentTime moment when the availability is check
   *  * Check time availability
   */
  public isTimeAvailable(startTime: string, stopTime: string, currentTime: Date): boolean {
    // check Day Of Week availability
    const timeComp = new Date(currentTime.getTime()).setFullYear(1970, 0, 1);

    const beginTime = this.stringToTime(startTime, timeComp);
    const endTime = this.stringToTime(stopTime, timeComp, false);

    if (beginTime > endTime || timeComp < beginTime) {
      return false;
    }
    if (endTime < timeComp) {
      return false;
    }
    return true;
  }

  protected stringToTime(value: string, currentTime: number, startTime: boolean = true): number {
    const newDate = new Date('1970-01-01');
    if (value) {
      newDate.setHours(parseInt(value[0] + value[1], 10));
      newDate.setMinutes(parseInt(value[2] + value[3], 10));
      return newDate.valueOf();
    } else {
      return (startTime) ?
        newDate.valueOf() :
        currentTime + 60 * 60 * 1000;
    }
  }
}
