import {
  animateChild,
  query,
  trigger,
  transition,
  useAnimation,
} from '@angular/animations';
import { Component, Input } from '@angular/core';

import { AppSettingsService } from '@app/core/acrelec-external';
import { AccessibilityService } from '@core/accessibility';
import { AllergensService, CustomAllergen } from '@core/allergens';
import { Animations } from '@core/animations';
import { ModalService, ModalType } from '@core/modal';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-allergens-modal',
  templateUrl: './allergens-modal.component.pug',
  styleUrls: ['./allergens-modal.component.scss'],
  animations: [
    trigger('displayModal', [
      transition(':enter', [
        useAnimation(Animations.inflateModal, { params: { time: '300ms' } }),
        query('@displayChild', animateChild({ delay: '0' })),
      ]),
    ]),
    trigger('displayChild', [
      transition(':enter', [
        useAnimation(Animations.showModalChild, {
          params: { time: '300ms' },
        }),
      ]),
    ]),
  ],
})
export class AllergensModalComponent {
  public showAllergens = true;

  constructor(
    private _accessibility: AccessibilityService,
    private _appSettings: AppSettingsService,
    private _allergensService: AllergensService,
    private _modalService: ModalService,
    private _t: TranslationService
  ) {}

  /**
   * Return the status of the PMR mode
   */
  get accessibilityStatus(): boolean {
    return this._accessibility.isAccessibilityActivated;
  }

  /**
   * Return all allergens
   */
  get allergens(): CustomAllergen[] {
    return this._allergensService.allergens;
  }

  /**
   * Display a modal with allergens more informations
   */
  private moreAllergensInformation(): void {
    this.showAllergens = !this.showAllergens;
  }

  /**
   * Close the modal
   */
  private close(): void {
    this._modalService.closeModal();
  }

  /**
   * Reset allergen filters
   */
  private reset(): void {
    this._allergensService.resetFilter();
  }
}
