import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-order-title',
  templateUrl: './order-title.component.pug',
  styleUrls: ['./order-title.component.scss'],
})
export class OrderTitleComponent implements OnInit {
  @Input() title: string;
  @Input() isBackButtonVisible: boolean;
  @Input() isRecommendation: boolean;
  @Input() isHomePage: boolean;
  @Output() onBack = new EventEmitter();

  constructor(
    private _t: TranslationService,
    private _accessibility: AccessibilityService,
    ) {}

  ngOnInit() {}

  /**
   * Return the visibility value of the button
   */
  private get backButtonVisibility(): string {
    if (this.isBackButtonVisible) {
      return 'block';
    }
    return 'none';
  }

  /**
   * Return to previous navigation
   */
  private onBackButtonClick() {
    this.onBack.emit();
  }
}
