import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SuggestiveSellingModule as SuggestiveSellingCoreModule } from '@core/suggestive-selling';
import { PipesModule } from '@pipes';
import { ModifiersModule } from '@ui/modifiers';
import { SharedModule } from '@ui/shared';

import { SuggestiveSellingComponent } from './components/suggestive-selling.component';

@NgModule({
  declarations: [SuggestiveSellingComponent],
  imports: [
    CommonModule,
    ModifiersModule,
    PipesModule,
    SharedModule,
    SuggestiveSellingCoreModule,
  ],
  exports: [SuggestiveSellingComponent],
})
export class SuggestiveSellingModule { }
