import { Injectable } from '@angular/core';
import { DotWorkingHours, DotWorkingHoursLoader } from 'dotsdk';

import { StringEval } from './string-evaluator/string-evaluator.model';
import { StringEvaluatorService } from './string-evaluator/string-evaluator.service';

@Injectable({
    providedIn: 'root'
})
export class WorkingHoursService {
    protected workingHours: DotWorkingHours[];

    constructor(private stringEvaluatorService: StringEvaluatorService) {
        const workingHoursList = DotWorkingHoursLoader.getInstance().loadedModel?.WorkingHourList;
        this.workingHours = workingHoursList;
    }

    /**
     * Check working hours availability
     * @param workingHour Product's availability depending on working hours
     */
    public isWorkingHourActive(workingHour: string): boolean {
        if (!workingHour || !this.workingHours || this.workingHours.length === 0) {
            return true;
        }
        const workingHoursDaypartList: StringEval[] = [];
        this.workingHours.forEach(workingHoursDaypart => {
            if (!workingHoursDaypart.Enabled) {
              return;
            }
            const validWorkingHoursDaypartList = workingHoursDaypart.ParameterList.find(
                workingHoursDay => {
                    const isCurrentDay = workingHoursDay.Name === this.getCurrentDay();
                    const isInOpeningHoursInterval = this.isInOpeningHoursInterval(workingHoursDay.Value);
                    return isCurrentDay && isInOpeningHoursInterval;
                });
            if (validWorkingHoursDaypartList) {
                workingHoursDaypartList.push({
                    name: this.getDayPartName(workingHoursDaypart.Name),
                    isEnabled: true,
                    wasUsed: false
                });
            } else {
                workingHoursDaypartList.push({
                    name: '!' + this.getDayPartName(workingHoursDaypart.Name),
                    isEnabled: true,
                    wasUsed: false
                });
            }
        });
        const isDaypartInValidDaypartList = this.stringEvaluatorService.evaluate(
            workingHour,
            workingHoursDaypartList,
            true
        );
        return isDaypartInValidDaypartList;
    }

    /**
     * return day of week, first three characters
     */
    protected getCurrentDay(): string {
        const weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        return weekday[new Date().getDay()];
    }

    /**
     * return working hour name
     * @param sectionListName workingHour name prefixed with 'DayPart.'
     */
    protected getDayPartName(sectionListName: string): string {
        return sectionListName.toLowerCase().split('daypart.').pop();

    }

    /**
     * Check if product if currently available
     * @param timeInterval product availability interval
     */
    protected isInOpeningHoursInterval(timeInterval: string): boolean {
        const currentTime = new Date();
        const beginHour = this.stringToDate(timeInterval.split('|')[0]);
        const endHour = this.stringToDate(timeInterval.split('|')[1]);
        if (currentTime >= beginHour && currentTime <= endHour) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Transform data in string format to data format
     * @param dStr Date in string format
     */
    protected stringToDate(dStr: string): Date {
        const now = new Date();
        const hourNow = dStr.substr(0, dStr.indexOf(':'));
        if (hourNow && hourNow && isNaN(parseInt(hourNow, 10))) {
            now.setHours(0);
        } else {
            now.setHours(parseInt(hourNow, 10));
        };
        const minuteNow = dStr.substr(dStr.indexOf(':') + 1);
        if (minuteNow && isNaN(parseInt(minuteNow, 10))) {
            now.setMinutes(0);
        } else {
            now.setMinutes(parseInt(minuteNow, 10));
        }
        now.setSeconds(0);
        return now;
    }
}
