import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppSettingsService } from '@core/acrelec-external';
import { PaymentService, PaymentSteps } from '@core/payment';

import { PaymentType } from './types/PaymentType';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.pug',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  private _language: string;

  constructor(
    private _appSettingsService: AppSettingsService,
    private _paymentService: PaymentService,
    private _router: Router
  ) {}

  ngOnInit() {
    // Changes the navigation when a payment status is changed
    this._paymentService.paymentStatusObservable.subscribe((paymentStatus) => {
      switch (paymentStatus) {
        case PaymentSteps.Failure:
          this._router.navigate(['/payment/remote/failure']);
          break;
        case PaymentSteps.Proceed:
          this._router.navigate(['/payment/remote']);
          break;
        case PaymentSteps.Waiting:
          this._router.navigate(['/payment/remote/waiting']);
          break;
        case PaymentSteps.Success:
          if (this._paymentService.tentNumber) {
            this._router.navigate(['/sat/success']);
          } else {
            this._router.navigate(['/payment/remote/success']);
          }
          break;
        // TODO: Fix me
        case PaymentSteps.Waiting:
          this._router.navigate(['/payment/remote']);
          break;
      }
    });
  }
}
