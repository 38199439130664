import { Component, OnDestroy, OnInit } from '@angular/core';
import { DotBanner } from 'dotsdk';

import { RecommendationService } from '@app/core/recommendation';
import {
  AppSettingsService,
  ContentService,
  PeripheralsService,
} from '@core/acrelec-external';
import { NavigationService, ScreenType } from '@core/navigation';
import { PaymentService } from '@core/payment';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-screen-saver',
  templateUrl: './screen-saver.component.pug',
  styleUrls: ['./screen-saver.component.scss'],
})
export class ScreenSaverComponent implements OnInit, OnDestroy {
  private _navigationItems: DotBanner[];
  private _testPOSStatus: boolean;
  private _testPrinterStatus: boolean;
  private _testPaymentStatus: boolean;
  private _posTask: number;
  private _recommendationTask: number;

  constructor(
    private _contentService: ContentService,
    private _navigationService: NavigationService,
    private _appSettingsService: AppSettingsService,
    private _paymentService: PaymentService,
    private _sessionService: SessionService,
    private _peripheralsService: PeripheralsService,
    private _recommendationService: RecommendationService,
    private _t: TranslationService
  ) {
    this._navigationItems = this._contentService
      .getBannersData('Slide')
      .filter((slider) => !!slider.Image);
  }

  ngOnInit(): void {
    this.checkPosConnectionStatus();
    this.checkRecommendations();
    this._sessionService.resetSession();
  }

  ngOnDestroy(): void {
    if (this._posTask) {
      clearTimeout(this._posTask);
    }
    if (this._recommendationTask) {
      clearTimeout(this._recommendationTask);
    }
  }

  /**
   * Start a new order
   */
  startOrder(): void {
    this._navigationService.addStack({
      type: ScreenType.SERVICE,
      content: null,
    });
  }

  /**
   * Check the connection status before giving the button text
   */
  private get buttonContent(): string {
    if (!this._testPOSStatus) {
      return this._t.translate(56, 'POS connection failed');
    }
    if (!this._testPaymentStatus) {
      return this._t.translate(100, 'Payment not available');
    }
    if (!this._testPrinterStatus) {
      return this._t.translate(101, 'Printer not available');
    }
    return this._t.translate(15, 'Touch to order');
  }

    /**
   * Check if the connection status is successful
   */
     private async checkRecommendations(): Promise<void> {
      if (this._recommendationService.authenticated) {
        this._recommendationService.updatePopularProducts();
        this._recommendationTask = window.setTimeout(() => {
          this.checkRecommendations();
        }, 5000);
      } else {
        this._recommendationTask = window.setTimeout(() => {
          this.checkRecommendations();
        }, 1000);
      }
    }

  /**
   * Check if the connection status is successful
   */
  private async checkPosConnectionStatus(): Promise<void> {
    await this._paymentService
      .testConnection()
      .then((response) => {
        this._testPOSStatus = response.Success;
        if (!response.Success) {
          throw new Error('POS connection failed');
        }
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
    this._testPrinterStatus = await this._peripheralsService
      .getPrinterStatus()
      .catch(() => false);
    this._testPaymentStatus = await this._peripheralsService
      .isPaymentAvailable()
      .catch(() => false);

    this._posTask = window.setTimeout(() => {
      this.checkPosConnectionStatus();
    }, this._appSettingsService.checkInterval);
  }
}
