import {
  trigger,
  transition,
  useAnimation,
  query,
  animateChild,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { DotButton } from 'dotsdk';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { AccessibilityService } from '@core/accessibility';
import { DotButtonService } from '@core/acrelec-content';
import { AppSettingsService } from '@core/acrelec-external';
import { Animations } from '@core/animations';
import { BasketService } from '@core/basket';
import { ModalService } from '@core/modal';
import { SuggestiveSalesService, RecommendationSalesService } from '@core/suggestive-selling';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.pug',
  styleUrls: ['./product-modal.component.scss'],
  animations: [
    trigger('displayModal', [
      transition(':enter', [
        useAnimation(Animations.inflateModal, { params: { time: '300ms' } }),
        query('@displayChild', animateChild({ delay: '0' })),
      ]),
    ]),
    trigger('displayChild', [
      transition(':enter', [
        useAnimation(Animations.showModalChild, {
          params: { time: '300ms' },
        }),
      ]),
    ]),
  ],
})
export class ProductModalComponent implements OnInit {
  @Input() product: DotButton;
  private productClone: DotButton;
  private displayContentSubject: BehaviorSubject<string>;
  private showSuggestiveSells = false;
  private showRecommendationSells = false;

  constructor(
    private _accessibility: AccessibilityService,
    private _appSettingsService: AppSettingsService,
    private _basketService: BasketService,
    private _db: DotButtonService,
    private _modalService: ModalService,
    private _recommendationSalesService: RecommendationSalesService,
    private _suggestiveSalesService: SuggestiveSalesService,
    private _t: TranslationService
  ) {
    this.displayContentSubject = new BehaviorSubject<string>('')
  }

  ngOnInit(): void {
    this.productClone = _.cloneDeep(this.product);
  }

  /**
   * Check if PMR mode is enabled
   */
  private get accessibilityStatus(): boolean {
    return this._accessibility.isAccessibilityActivated;
  }

  private get displayBlock$(): Observable<string> {
    return this.displayContentSubject.asObservable();
  }

  /**
   * Check if it's a combo
   */
  private get isCombo(): boolean {
    return this._db.isCombo(this.productClone);
  }

  /**
   * Close the modal
   */
  private close(): void {
    this._modalService.closeModal();
  }

  /**
   * Adds the product to the basket, and displays sales suggestions if available
   * @param product
   */
  private async finishProductNavigation(product: DotButton): Promise<void> {
    this._basketService.addProductToBasket(product);

    if (this._appSettingsService.isRecommendationEnabled) {
      const status = await this._recommendationSalesService.initializeRecommendations();
      if (status) {
        this.displayContentSubject.next('recommendation-selling');
        return;
      }
    }

    const hasSuggestions = this._suggestiveSalesService.hasSuggestions(product);
    if (!hasSuggestions) this.close();
    this.displayContentSubject.next('suggestive-selling');
  }
}
