import { Injectable } from '@angular/core';
import { DotButton, addToCartEvent, removeFromCartEvent } from 'dotsdk';
import * as _ from 'lodash';
import * as uuid from 'uuid';

import { ComboService, DotButtonService, PriceCalculationService } from '@core/acrelec-content';
import { ContentService } from '@core/acrelec-external';
import { RecommendationService } from '@core/recommendation';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  firstBasketProductDate: Date;
  private _products: DotButton[] = [];

  constructor(
    private _contentService: ContentService,
    private _comboService: ComboService,
    private _db: DotButtonService,
    private _popupService: PopUpService,
    private _priceService: PriceCalculationService,
    private _recommendationService: RecommendationService,
    private _sessionService: SessionService,
    private _translationService: TranslationService
  ) {
    // Subscribes to the session observable and reset basket on session reset
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) this.resetBasket();
    });
  }

  /**
   * @returns the number of products in the basket
   */
  get basketItemsCount(): number {
    const quantity = this.products.reduce(
      (previousQuantity: number, currentItem: DotButton) => {
        return previousQuantity + currentItem.quantity;
      },
      0
    );
    return quantity;
  }

  /**
   * Checks the maximum number of promotions globally allowed and compare it with the number of promotions in the basket
   */
  get isMaximumGlobalPromotionAmount(): boolean {
    const promoData = this._contentService.promotionData;
    const promoCommon = promoData.Common;
    if (!promoCommon) return false;
    const maxPromos = promoCommon.MaxPromo;
    return maxPromos && this.promotionsAmount >= maxPromos;
  }

  /**
   * @returns all products in the basket
   */
  get products(): DotButton[] {
    return this._products;
  }

  /**
   * @returns promotions products amount from basket
   */
  get promotionsAmount(): number {
    return this._products.reduce((quantity: number, button: DotButton) => {
      if (this._db.isPromo(button)) quantity += button.quantity;
      return quantity;
    }, 0);
  }

  /**
   * @returns price calculated from all products in the basket
   */
  get totalPrice(): number {
    return this.calculateTotalPrice(this._products);
  }

  /**
   * Get the total displayed price (divided by 100) of the basket
   */
  get totalDisplayPrice(): number {
    return this.totalPrice / 100;
  }

  /**
   * Add or update a product in the basket
   * @param product The product to add or update in the basket
   */
  addProductToBasket(product: DotButton): void {
    if (!product) return;
    // If the exact same product exists in the basket, remove it, then add the modified product
    // UUID is not set if product has not been already added
    if (!this.firstBasketProductDate) {
      this.firstBasketProductDate = new Date();
    }
    if (product.uuid) {
      this.removeBasketItem(product);
    }
    // The new SDK uses ComputedPrice not Price anymore. In case the field is empty, we fix this.
    if (!product.ComputedPrice) {
      product.ComputedPrice = this._priceService.getPrice(product);
    }

    const similarProduct = this._products.find((existing) =>
      this._db.isSameButton(existing, product)
    );
    if (similarProduct) {
      this.increaseQuantity(product, false);
    } else {
      product.uuid = uuid.v4();
      this.createBasketItem(product);
    }

    addToCartEvent.emit(this._products);
  }

  /**
   * Decreases a basket product's quantity by one and remove it if quantity === 0
   * @param product product whose quantity will be decreased
   */
  decreaseQuantity(product: DotButton): void {
    const currentProduct = this._products.find((existing) =>
      this._db.isSameButton(product, existing)
    );
    if (!currentProduct) return;
    currentProduct.quantity--;
    if (!currentProduct.quantity) {
      this.removeBasketItem(product);
    }
    this._recommendationService.updateOrderItem(currentProduct);

    removeFromCartEvent.emit(this._products);
  }

  /**
   * If product is a combo, returns the button's caption followed by the main combo product's caption.
   * Else returns the button's caption.
   * @param button button to get the caption from
   */
  getProductName(product: DotButton): string {
    let caption = this._db.getCaption(product);
    if (
      this._db.isCombo(product) &&
      !this._comboService.isSingleStep(product)
    ) {
      const visibleSteps = this._comboService.getVisibleComboSteps(product);
      const selectedProduct = this._comboService.getSelectedProduct(
        visibleSteps[0]
      );
      if (selectedProduct) caption = this._db.getCaption(selectedProduct);
      caption += ` ${this._db.getCaption(product)}`;
    }
    return caption;
  }

  /**
   * If product is a combo, returns the main combo product's picture.
   * Else returns the products picture
   * @param button product to get the picture from
   */
  getPicture(button: DotButton): string {
    let basketProduct = button;
    if (this._db.isCombo(basketProduct)) {
      const visibleSteps = this._comboService.getVisibleComboSteps(
        basketProduct
      );
      const selectedProduct = this._comboService.getSelectedProduct(
        visibleSteps[0]
      );
      if (selectedProduct) basketProduct = selectedProduct;
    }
    return this._db.getPicturePath(basketProduct);
  }

  /**
   * Increase an existing product quantity by sending a new product with the quantity field set to required value.
   * If the singleIncrement flag is set to true, increase instead by 1.
   * @param new product whose quantity will be increased
   * @param singleIncrement if single increment is set to true, increase by one
   */
  increaseQuantity(newProduct: DotButton, singleIncrement = false): void {
    const existingProduct = this._products.find((existing) =>
      this._db.isSameButton(newProduct, existing)
    );
    if (!newProduct || !existingProduct) return;
    if (this._db.isPromo(newProduct) && this.isMaximumPromotionAmount) {
      // check if maximum amount of promotions is reached => yes ? return

      this._popupService.open({
        component: MessagePopUpComponent,
        componentData: this._translationService.translate(
          57,
          'Maximum amount of promotions reached'
        ),
        leftButtonContent: this._translationService.translate(24, 'close'),
        leftButtonCallback: () => {
          this._popupService.close();
        },
      });
      return;
    }

    let quantity = newProduct.quantity ? newProduct.quantity : 1;
    quantity = singleIncrement ? 1 : quantity;
    existingProduct.quantity += quantity;

    this._recommendationService.updateOrderItem(existingProduct);

    addToCartEvent.emit(this._products);
  }

  /**
   * Checks the maximum number of specific promotion products allowed
   * @returns true if under the maximum product allowed
   */
  isMaximumPromotionAmount(promoProduct: DotButton): boolean {
    return false;
  }

  /**
   * Removes a product from the basket using its UUID
   * @param product product to remove
   */
  removeBasketItem(product: DotButton): void {
    product.quantity = 0;
    _.remove(
      this._products,
      (button: DotButton) => button.uuid === product.uuid
    );
    this._recommendationService.updateOrderItem(product);
  }

  /**
   * Resets the basket by emptying it and setting the date to null
   */
  resetBasket(): void {
    this.firstBasketProductDate = null;
    this._products = [];
  }

  /**
   * @param buttons buttons to calculate total price from
   */
  private calculateTotalPrice(buttons: DotButton[]): number {
    return this._products.reduce((subTotal: number, button: DotButton) => {
      const price = button.ComputedPrice;
      return subTotal + button.quantity * price;
    }, 0);
  }

  /**
   * Adds a product to the basket and set quantity to 1 if necessary
   * @param button product to add to the basket
   */
  private createBasketItem(button: DotButton): void {
    if (!button.quantity) button.quantity = 1;
    this._products.unshift(button);
    this._recommendationService.updateOrderItem(button);
  }
}
