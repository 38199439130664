import { Injectable } from '@angular/core';

import { StringEval } from './string-evaluator.model';
import { Symbols } from './symbol.enum'

@Injectable({
  providedIn: 'root'
})
export class StringEvaluatorService {
    protected enumSymbols: string[] = [];
    protected varsList: string[] = [];

    constructor() { }

    public evaluate(expression: string, generics: StringEval[], generalCase: boolean = false): boolean {

        if (!expression) {
            return false;
        }
        expression = expression.toLowerCase();

        generics = this.resetGenerics(generics);

        // other changes to formula (ex multiply)
        if (generalCase === false) {
            expression = this.normalizeExpression(expression);
        }
        // Load variables from expression to StringList
        this.loadVars(expression, generalCase);

        // Try to convert variables to boolean values
        expression = this.convertVarsToBools(this.varsList, expression, generics);

        // Rename logical operators
        expression = this.renameOperatorsFromSymbolToWord(expression);

        let wordBool = false;
        try {
            /* eslint no-eval: 0 */
            wordBool = eval(expression);
        } catch {
            wordBool = false;
        }

        return wordBool;
    }

    protected loadSymbols() {
        this.enumSymbols = [];
        for (const value in Symbols) {
            if (typeof Symbols[value] === 'string') {
                this.enumSymbols.push(Symbols[value]);
            }
        }
    }

    protected loadVars(expression: string, generalCase: boolean = false) {
        this.varsList = [];

        if (!expression) {
            return;
        }
        this.loadSymbols();
        let lExpression = expression.toLowerCase();
        if (generalCase === false) {
            lExpression = expression.split(' ').join('');
        }
        this.enumSymbols.forEach((element) => {
            lExpression = lExpression.split(element).join('|');
        });
        while (lExpression.indexOf('||') !== -1) {
            lExpression = lExpression.split('||').join('|');
        }
        if (lExpression[0] === '|') {
            lExpression = lExpression.replace('|', '');
        }
        if (lExpression[lExpression.length - 1] === '|') {
            lExpression = lExpression.slice(0, -1);
        }
        this.varsList = lExpression.split('|');
    }

    protected resetGenerics(lGenerics: StringEval[]): StringEval[] {
        return lGenerics.map(x => {
            if (x.wasUsed) {
               x.wasUsed = false;
            }
            return x;
        });
    }

    // change multiplication to serial &
    // 3 * I333 => I333 & I333 & I333
    protected normalizeExpression(expression: string): string {
        let lResult = expression.replace(/ /g, '');
        if (lResult === '') {
            return lResult;
        }

        while (lResult.indexOf(Symbols.LogicalMultiply) !== -1) {
            let lVar1 = '';
            let lVar = '';
            let countSymbol: number ;
            for (let i = 0; i < lResult.length; i++) {
                countSymbol = 0;
                this.enumSymbols.forEach((value) => {
                    if (value === lResult[i + 1]) {
                        countSymbol++;
                    }
                });
                if (countSymbol === 0) { // lResult doesn't contains any Symbols
                    lVar = lVar + lResult[i + 1];
                } else {
                    if (lVar === '') {
                        continue;
                    }
                    if (lResult[i + 1] === Symbols.LogicalMultiply) {
                        lVar1 = lVar;
                    } else {
                        if (lVar1 !== '') {
                          break;
                        }
                    }
                    lVar = '';
                }
            }
            if (lVar1 !== '' && lVar !== '') {
                lResult = this.replaceMultiplier(lResult, lVar1, lVar);
            }
        }
        return lResult;
    }

    protected replaceMultiplier(aOriginal: string, aString1: string, aString2: string): string {
        let lToMultiply = aString1;
        let lMultiplier = parseInt(aString1, 10);
        if (!isNaN(lMultiplier)) {
            lToMultiply = aString2;
        } else {
            lMultiplier = parseInt(aString2, 10);
        }

        let lExpression = lToMultiply;
        for (let i = 1; i < lMultiplier; i++) {
            lExpression = lExpression + Symbols.LogicalAnd + lToMultiply;
        }
        return aOriginal.replace(aString1 + Symbols.LogicalMultiply + aString2, lExpression);
    }

    protected renameOperatorsFromSymbolToWord(expression: string): string {
        expression = expression.split(Symbols.BracketIn).join(' ( ');
        expression = expression.split(Symbols.BracketOut).join(' ) ');
        expression = expression.split(Symbols.LogicalAnd).join(' && ');
        expression = expression.split(Symbols.LogicalOr).join(' || ');
        expression = expression.split(Symbols.LogicalNot).join(' ! ');

        expression = expression.split(Symbols.LogicAnd).join(' && ');
        expression = expression.split(Symbols.LogicOr).join(' || ');
        return expression;
    }

    protected convertVarsToBools(varsList: string[], expression: string, generics: StringEval[]): string {

        let lResult = expression;
        if (!varsList) {
            return lResult;
        }
        varsList.forEach((element) => {

            if (element === 'TRUE' || element === 'FALSE') {
                lResult = lResult.split(element).join(element.toLowerCase());
            } else {
                const lFound = generics.find(lObj => {
                    return lObj.name === element && lObj.wasUsed === false;
                });
                if (lFound) {
                    lResult = lResult.split(element).join((lFound.isEnabled === true).toString());
                    lFound.wasUsed = true;
                } else {
                    lResult = lResult.split(element).join('false');
                }
            }

        });
        return lResult;
    }
}
