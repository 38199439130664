import { Injectable } from '@angular/core';
import { DotButton, DotPage, DotPromo } from 'dotsdk';

import { DotButtonService, AvailabilityService } from '@core/acrelec-content';
import { ContentService } from '@core/acrelec-external';
import { BasketService } from '@core/basket';
import { ModalService, ModalType } from '@core/modal';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUp, PopUpService } from '@ui/pop-up';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  constructor(
    private _availabilityService: AvailabilityService,
    private _basketService: BasketService,
    private _contentService: ContentService,
    private _db: DotButtonService,
    private _modalService: ModalService,
    private _popupService: PopUpService,
    private _t: TranslationService,
  ) { }

  /**
   * Displays a modal with the promotion product if the code is valid
   * Displays a popup with optional callbacks if there is an error
   * @param barCode the promotional code to display
   */
  displayPromotion(barCode: string, closeModal?: () => void, retry?: () => void): void {
    if (this.isMaximumPromotionAmount) {
      this.openCustomPopUp(this._t.translate(68, 'Invalid code'), closeModal, retry);
      return;
    }
    const promoError = this.checkPromoError(barCode)
    if (promoError) {
      this.openCustomPopUp(promoError, closeModal, retry);
      return;
    }
    const promoProduct = this.getPromotionalProductFromCode(barCode);
    if (!promoProduct) {
      this.openCustomPopUp(this._t.translate(68, 'Invalid code'), closeModal, retry);
      return;
    }
    this._modalService.setModal({ type: ModalType.PRODUCT, data: promoProduct });
  }

  /**
   * Checks the maximum number of promotions globally allowed and compare it with the number of promotions in the basket
   */
  private get isMaximumPromotionAmount(): boolean {
    return this._basketService.isMaximumGlobalPromotionAmount;
  }

  /**
 * Check promotion validity
 */
  private checkPromoError(barCode: string): string | undefined {
    const promo = this.getPromotionsFromCode(barCode)?.first();
    return this.promotionHasError(promo);
  }

  /**
   * Checks if the minimum amount to get promotion is reached
   * @param promotions promotions to filter
   */
  private filterMinimumAmountPromotions(promotions: DotPromo[]): DotPromo[] {
    return promotions.filter((promotion) => {
      if (
        promotion.Description &&
        promotion.Description.MinUAmount &&
        this._basketService.totalPrice < promotion.Description.MinUAmount
      ) {
        return false;
      }
      return true;
    });
  }

  /**
   * return all promotions with valid barcode
   */
  private getAllCodePromotions(
    promotions: DotPromo[],
    barCode: string
  ): DotPromo[] | null {
    return promotions.filter(
      (promotion) =>
        !promotion.Description ||
        !promotion.Description.Barcode ||
        promotion.Description.Barcode.split('\\b').join('') === barCode
    );
  }

  /**
   * Returns product(s) related to the promotion
   */
  private getPromotionButtons(
    promotions: DotPromo[],
    promoPage: DotPage
  ): DotButton[] {
    if (!promotions) return null;
    const promoButtons = [];
    promotions.forEach((promotion: DotPromo) => {
      if (!promotion.Description || !promotion.Description.PosDiscountID)
        return null;
      const posDiscountId = promotion.Description.PosDiscountID;
      let promoGroup = promoPage.Buttons.filter((button: DotButton) =>
        this.isValidPromoButton(button, promotion)
      )[0];
      if (!promoGroup || !promoGroup.Page) return null;
      promoGroup.Page.Buttons.forEach((promoButton: DotButton) => {
        if (!promoButton.Page) return null;
        const validPromoButton = promoButton.Page.Buttons.find(
          (product: DotButton) =>
            Math.abs(parseInt(product.Link, 10)) === posDiscountId
        );
        if (!validPromoButton) return null;
        this._db.setAsPromotionalButton(
          validPromoButton,
          promotion.ID,
          posDiscountId
        );
        promoButtons.push(validPromoButton);
      });
    });
    return promoButtons;
  }

  /**
   * Returns the first promotional product related to the barcode
   */
  private getPromotionalProductFromCode(code: string): DotButton | null {
    if (!code) return null;
    code = code.toUpperCase();
    const promotions = this.getPromotionsFromCode(code);
    if (!promotions) return null;
    const promotionsPage = this._contentService.promotionPage;
    if (!promotionsPage) return null;
    const promoButtons = this.getPromotionButtons(promotions, promotionsPage);
    return promoButtons.first();
  }

  /**
   * Returns all promotions related to the barcode
   */
  private getPromotionsFromCode(barCode: string): DotPromo[] | null {
    const promoList = this._contentService.promotionList;
    if (!promoList) return null;
    const allPromotions = this.getAllCodePromotions(promoList, barCode);
    if (!allPromotions) return null;
    const filteredPromotions = this.filterMinimumAmountPromotions(
      allPromotions
    );
    if (!filteredPromotions.length) return null;
    return filteredPromotions;
  }
  /**
   * Checks if the promotion is valid
   */
  private isValidPromoButton(button: DotButton, promotion: DotPromo): boolean {
    const btnLink = button.Link;
    if (btnLink) {
      if (promotion.PageID === btnLink) {
        return true;
      }
    }
    return false
  }

  /**
   * Display a popup with an error message, a way to close the popup or the voucher modal
   * @param popUpMessage the message to display in the popup
   * @param closeModal the optional callback to close the manual/scanner voucher modal
   */
  private openCustomPopUp(popUpMessage: string, closeModal?: () => void, retry?: () => void,): void {
    const popUp = {
      component: MessagePopUpComponent,
      componentData: popUpMessage,
      leftButtonContent: this._t.translate(23, 'cancel'),
      leftButtonCallback: () => {
        this._popupService.close();
        if (closeModal) {
          closeModal();
        }
      },
      rightButtonContent: this._t.translate(70, 'retry'),
      rightButtonCallback: () => {
        this._popupService.close();
        if (retry) {
          retry();
        }
      }
    }
    this._popupService.open(popUp);
  };

  /**
   * Checks if promotion has error and return it if so
   */
  private promotionHasError(promotion: DotPromo): string | undefined {
    if (!promotion) {
      return;
    }
    const isAvailable =
      (promotion.Description &&
        promotion.Description.Avlb &&
        this._availabilityService.isAvailable(promotion.Description.Avlb, new Date())
      ) ||
      !promotion.Description ||
      !promotion.Description.Avlb;
    if (!isAvailable) {
      const message =
        promotion.Description &&
          promotion.Description.Avlb &&
          promotion.Description.LblNoItem
          ? promotion.Description.LblNoItem
          : 'This promo is invalid';
      return message;
    }
  }
}
