import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { ModalModule } from '@core/modal';
import { TranslationModule } from '@core/translation';
import { PopUpModule } from '@ui/pop-up';
import { SharedModule } from '@ui/shared';

import { SingleKeyComponent } from './components/single-key/single-key.component';
import { VoucherManualComponent } from './components/voucher-manual.component';

@NgModule({
  declarations: [VoucherManualComponent, SingleKeyComponent],
  imports: [
    AccessibilityModule,
    CommonModule,
    InlineSVGModule,
    ModalModule,
    PopUpModule,
    SharedModule,
    TranslationModule,
  ],
  exports: [VoucherManualComponent],
})
export class VoucherManualModule {}
