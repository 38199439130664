import { Component, OnInit, Input } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AccessibilityService } from '@core/accessibility';
import { AvailabilityService, DotButtonService } from '@core/acrelec-content';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-size-dropdown-item',
  templateUrl: './size-dropdown-item.component.pug',
  styleUrls: ['./size-dropdown-item.component.scss'],
})
export class SizeDropdownItemComponent implements OnInit {
  @Input() price: number;
  @Input() picture: string;
  @Input() caption: string;
  @Input() isFirst: boolean;
  @Input() buttonDisabled: boolean;

  constructor(
    private _a: AccessibilityService,
    private _db: DotButtonService,
    private _t: TranslationService,
    private _availabilityService: AvailabilityService
  ) {}

  ngOnInit() {
    this.price = this.price / 100;
  }
}
