import { Injectable } from '@angular/core';
import {
  DotAllergen,
  DotButton,
  DotButtonType,
  DotPage,
  omitPropertiesInObject,
} from 'dotsdk';
import * as _ from 'lodash';

import { AppSettingsService } from '@core/acrelec-external';
import { TranslationService } from '@core/translation';

@Injectable({
  providedIn: 'root',
})
export class DotButtonService {
  constructor(
    private _appSettingsService: AppSettingsService,
    private _translationService: TranslationService
  ) {}

  /**
   * Get allergens from a product
   * @param button The button that contains allergens
   */
  getAllergens(button: DotButton): DotAllergen[] | null {
    if (
      !button ||
      button.AllergensAndNutritionalValues === null ||
      button.AllergensAndNutritionalValues.Allergens.length <= 0
    ) {
      return null;
    }
    return button.AllergensAndNutritionalValues.Allergens;
  }

  /**
   * Get calories of a product
   * @param button The button to get the calories for.
   */
  getCalories(button: DotButton): string | null {
    return this.getNutritionalValue(button, 'CAL');
  }

  /**
   * Check if a product has to have a background image and return the picture or null
   * @button The button to get the background image for.
   */
  getBackground(button: DotButton): string | null {
    if (!this.isDropdown(button) && this.isPage(button)) {
      return 'assets/images/backgrounds/page-bg.png';
    }
    return null;
  }

  /**
   * Return translated button caption
   * @button The button to get the caption for.
   */
  getCaption(button: DotButton): string {
    const language = this._translationService.language;
    const caption = button.getCaption(language);
    if (caption && caption.length > 0) {
      return caption;
    }
    const captionDictionary = button.CaptionDictionary;
    if (!captionDictionary) {
      return button.Caption;
    }
    return captionDictionary[language];
  }

  /**
   * Get a specific nutritional value
   * @button The button to get the nutritional value for.
   * @nutritionalType The wanted nutritional type
   */
  getNutritionalValue(
    button: DotButton,
    nutritionalType: string
  ): string | null {
    if (
      !button ||
      button.AllergensAndNutritionalValues === null ||
      button.AllergensAndNutritionalValues.NutritionalValues.length <= 0
    ) {
      return null;
    }
    const allNutritionalValues =
      button.AllergensAndNutritionalValues.NutritionalValues;
    const nutritionalValue = allNutritionalValues.find(
      (value) => value.Name === nutritionalType
    );

    if (!nutritionalValue) {
      return null;
    }

    return nutritionalValue.Value;
  }

  /**
   * Get a product picture and return it's path or a placeholder
   * @button The button to get the picture path for.
   */
  getPicturePath(button: DotButton, isCombo: boolean = false): string {
    if (button.Picture) {
      return `${this._appSettingsService.sharedFolderPath}/assets/items/${button.Picture}`;
    }
    if (isCombo) return 'assets/images/placeholder-bottom.png';
    return 'assets/images/placeholder-center.png';
  }

  /**
   * Return a product's tags
   * @button The button to get the tags for.
   */
  getTags(button: DotButton): string[] {
    const buttonTagsArray = button.Tags;
    if (buttonTagsArray) {
      const tagsArray = buttonTagsArray.split(',');
      return tagsArray;
    } else {
      return [];
    }
  }

  /**
   * Check if a product has modifiers
   * @button The button to check
   */
  hasModifiers(button: DotButton): boolean {
    if (
      button.ModifiersPage &&
      button.ModifiersPage.Modifiers &&
      button.ModifiersPage.Modifiers.length > 0
    ) {
      return true;
    }
    return false;
  }

  /**
   * Check if a product is a combo
   * @button The button to check
   */
  isCombo(button: DotButton): boolean {
    if (button.ButtonType === DotButtonType.MENU_BUTTON) return true;
    if (
      button &&
      button.ComboPage &&
      button.ComboPage.Combos &&
      button.ComboPage.Combos.length > 0
    ) {
      return true;
    }
    return false;
  }

  /**
   * Check if a product has a dropdown
   * @button The button to check
   */
  isDropdown(button: DotButton): boolean {
    return (
      button.ButtonType === DotButtonType.PAGE_BUTTON &&
      button.Tags.includes('dropdown')
    );
  }

  /**
   * Check if a product has a dropdown
   * @button The button to check
   */
  isDropdownPage(button: DotPage): boolean {
    return button.Tags === 'dropdown' || button.Tags.includes('dropdown');
  }

  /**
   * Check if a product is a page
   * @button The button to check
   */
  isPage(button: DotButton): boolean {
    if (button.ButtonType === DotButtonType.PAGE_BUTTON) return true;
    return false;
  }

  /**
   * Check if a product is a promo
   * @button The button to check
   */
  isPromo(button: DotButton): boolean {
    return button.isPromo;
  }

  /**
   * @returns true if the two buttons represent the same product, false if not
   */
  isSameButton(button1: DotButton, button2: DotButton): boolean {
    // Make sure they have the same Link:
    if (button1.Link !== button2.Link) {
      return false;
    }

    // FIXME: wait for an sdk update to include that property and check promotions
    // Compare Promos:
    // const samePromo = button1['Promo'] === button2['Promo'];

    // Compare Modifiers
    const omitProperties = [
      'analyticsOptions',
      '$uuid',
      '$type',
      '$$prefixName',
      'ref_uuid',
    ];
    const sameModifiers =
      (!button1.hasModifiers && !button2.hasModifiers) ||
      _.isEqual(
        omitPropertiesInObject(button1.ModifiersPage, omitProperties),
        omitPropertiesInObject(button2.ModifiersPage, omitProperties)
      );

    // Compare Combos:
    const sameCombos =
      (!button1.ComboPage && !button2.ComboPage) ||
      JSON.stringify(button1.ComboPage) === JSON.stringify(button2.ComboPage);

    return sameModifiers && sameCombos;
  }

  /**
   * Initializes a button to the default quantities for modifiers, etc.
   * @param product the product to select the default for
   */
  selectDefaults(product: DotButton, force?: boolean): void {
    if (force) {
      product.quantity = 1;
      product.Selected = true;
    } else {
      // Set quantities
      const productQuantity = Math.max(
        product.MinQuantity,
        product.DefaultQuantity
      );
      product.quantity = productQuantity;
      product.Selected = product.quantity ? true : false;
    }
  }

  /**
   * Set the button as a promotional button
   */
  setAsPromotionalButton(
    button: DotButton,
    promotionId: number,
    posDiscountId?: number
  ): void {
    button.isPromo = true;
    button.promoId = promotionId;
    button.posDiscountId = posDiscountId ? posDiscountId : -1;
  }
}
