import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessibilityModule } from '@core/accessibility';

import { CountdownPopUpComponent } from './components/countdown-pop-up/countdown-pop-up.component';
import { MessagePopUpComponent } from './components/message-pop-up/message-pop-up.component';
import { PopUpContentDirective } from './components/pop-up-content/pop-up-content.directive';
import { PopUpComponent } from './components/pop-up.component';

@NgModule({
  declarations: [
    CountdownPopUpComponent,
    MessagePopUpComponent,
    PopUpComponent,
    PopUpContentDirective,
  ],
  imports: [AccessibilityModule, CommonModule],
  exports: [
    CountdownPopUpComponent,
    MessagePopUpComponent,
    PopUpComponent,
    PopUpContentDirective,
  ],
})
export class PopUpModule {}
