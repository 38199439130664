import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton } from 'dotsdk';
import { Observable } from 'rxjs';

import { SuggestiveSalesService } from '@core/suggestive-selling';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-suggestive-selling',
  templateUrl: './suggestive-selling.component.pug',
  styleUrls: ['./suggestive-selling.component.scss'],
})
export class SuggestiveSellingComponent implements OnInit {
  @Input() product: DotButton;
  @Output() closeModal = new EventEmitter<void>();

  constructor(
    private _suggestiveSalesService: SuggestiveSalesService,
    private _t: TranslationService
  ) { }

  ngOnInit(): void {
    const status = this._suggestiveSalesService.initializeSuggestiveSelling(
      this.product
    );
    if (!status) {
      this.closeModal.emit();
    }
  }

  /**
   * True if basket is empty
   */
  private get isAddDisabled(): boolean {
    return this._suggestiveSalesService.isBasketEmpty;
  }

  /**
   * Return product suggestions
   */
  private get suggestions(): Observable<DotButton[]> {
    return this._suggestiveSalesService.productBasket$;
  }

  /**
   * Add a product
   * @param product
   */
  private addProduct(product: DotButton) {
    this._suggestiveSalesService.addProduct(product);
  }

  /**
   * Remove a product
   * @param product
   */
  private removeProduct(product: DotButton) {
    this._suggestiveSalesService.removeProduct(product);
  }

  /**
   * Close the modal
   */
  private close() {
    this._suggestiveSalesService.clearBasket();
    this.closeModal.emit();
  }

  /**
   * Add the product(s) to the order
   */
  private confirm() {
    this._suggestiveSalesService.addProductsToBasket();
    this.closeModal.emit();
  }
}
