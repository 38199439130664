import { trigger, useAnimation, transition } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Animations } from '@core/animations';

@Component({
  selector: 'app-quantity-buttons',
  templateUrl: './quantity-buttons.component.pug',
  styleUrls: ['./quantity-buttons.component.scss'],
  animations: [
    trigger('inflateNumber', [
      transition(':increment, :decrement', [
        useAnimation(Animations.inflateAnimation, {
          params: {
            scale: 1.2,
            time: '100ms',
          },
        }),
      ]),
    ]),
  ],
})
export class QuantityButtonsComponent implements OnInit {
  @Input() isAccessibilityActivated = false;
  @Input() isAddDisabled: boolean;
  @Input() quantity: number;
  @Input() maxQuantity: number;
  @Input() minQuantity: number;
  @Output('onAdd') onAdd = new EventEmitter();
  @Output('onRemove') onRemove = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  /**
   * Get the color depending on the quantity
   */
  private get countColor(): string {
    if (this.quantity === this.maxQuantity) return 'var(--bk-red)';
    return 'var(--bk-brown)';
  }

  /**
   * Hide the minus icon
   */
  private get isMinusDisabled() {
    if (!this.minQuantity) return this.quantity <= 0;
    return this.quantity <= this.minQuantity;
  }

  /**
   * Hide the plus icon
   */
  private get isPlusDisabled() {
    if (!this.maxQuantity) return false;
    return this.isAddDisabled || this.quantity >= this.maxQuantity;
  }

  /**
   * Remove quantity
   */
  private onMinusClick() {
    this.onRemove.emit();
  }

  /**
   * Add quantity
   */
  private onPlusClick() {
    this.onAdd.emit();
  }
}
