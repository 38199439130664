import { Injectable } from '@angular/core';
import { AtpScannerService } from 'dotsdk';

import { AppSettingsService } from '@core/acrelec-external';


@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  private _timeOut: number;
  private _scannerService: AtpScannerService;

  constructor(
    private _appSettingsService: AppSettingsService,
  ) {
    this._scannerService = AtpScannerService.getInstance();
    this._timeOut = this._appSettingsService.scannerTimeout;
  }

  /**
   * @returns true if scanner is available
   */
  async isScannerAvailable(): Promise<boolean> {
    try {
      return await this._scannerService.isScannerAvailable();
    } catch (err) {
      return false;
    }
  }

  /**
   * Start the scanner and reads the barCode.
   * @returns the bar code if valid, else undefined
   */
  async startScan(): Promise<string | undefined> {
    try {
      console.log(`scan started for ${this._timeOut} seconds`)
      return await this._scannerService.scan(this._timeOut);
    }
    catch (err) {
      console.error(err);
      return undefined;
    }
  }
}
