import { Injectable } from '@angular/core';
import { DotButton, DotAvailability, DotPage, DotCombo } from 'dotsdk';

import { ContentService, ServiceTypeService } from '@core/acrelec-external';

import { DateAvailabilityService } from './date-availability.service';
import { DayOfWeekService } from './day-of-week.service';
import { FilterTagsService } from './filter-tags.service';
import { TimeAvailabilityService } from './time-availability.service';
import { WorkingHoursService } from './working-hours.service';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  constructor(
    private _dayOfWeekService: DayOfWeekService,
    private _serviceTypeService: ServiceTypeService,
    private _dateAvailabilityService: DateAvailabilityService,
    private _timeAvailabilityService: TimeAvailabilityService,
    private _workingHoursService: WorkingHoursService,
    private _filterTagsService: FilterTagsService,
    private _contentService: ContentService
  ) {}

  /**
   * Check navbar buttons availability
   * @param button the button whose availability is checked
   */
  public isNavbarButtonAvailable(button: DotButton): boolean {
    const buttonAvailable = this.isAvailable(button.Avlb, new Date());
    const currentPage = this._contentService.getPageByButtonLink(button.Link);
    return currentPage && buttonAvailable && this.isPageAvailable(currentPage);
  }

  /**
   * Check button availability
   * @param button the button whose availability is checked
   */
  public isButtonDisabled(button: DotButton): boolean {
    return button.ButtonStatus === '2';
  }

  /**
   * Check button availability
   * @param button the button whose availability is checked
   */
  public isButtonAvailable(button: DotButton): boolean {
    const buttonAvailability = this.isAvailable(button.Avlb, new Date());
    if (button.Page) {
      return buttonAvailability && this.isPageAvailable(button.Page);
    }
    if (button.ComboPage) {
      return (
        buttonAvailability && this.isComboPageAvailable(button.ComboPage.Combos)
      );
    }
    return buttonAvailability;
  }

  /**
   * Common availabilty check
   * @param availabilityObject availability object from button
   * @param dateOfComparing date time when the availability is checked
   */
  public isAvailable(
    productAvailability: DotAvailability,
    dateOfComparing: Date
  ): boolean {
    if (!productAvailability) {
      return true;
    }

    // check Day Of Week availability
    if (productAvailability.DOW) {
      const dowAvailability = this._dayOfWeekService.isDowAvailable(
        parseInt(productAvailability.DOW, 10),
        dateOfComparing
      );
      if (!dowAvailability) {
        return false;
      }
    }

    // check service type availability
    if (productAvailability.Service) {
      const serviceTypeAvailability = this._serviceTypeService.isServiceLocationAvailable(
        productAvailability.Service
      );
      if (!serviceTypeAvailability) {
        return false;
      }
    }

    // check date availability
    if (productAvailability.Days) {
      const dateAvailability = this._dateAvailabilityService.checkDateAvailability(
        productAvailability.Days.Start,
        productAvailability.Days.Stop,
        dateOfComparing
      );
      if (!dateAvailability) {
        return false;
      }
    }

    // check time availability
    if (productAvailability.THS) {
      const timeAvailability = this._timeAvailabilityService.isTimeAvailable(
        productAvailability.THS.Start,
        productAvailability.THS.Stop,
        dateOfComparing
      );
      if (!timeAvailability) {
        return false;
      }
    }

    // check working hours availability
    if (productAvailability.WorkingHours) {
      const workingHoursAvailability = this._workingHoursService.isWorkingHourActive(
        productAvailability.WorkingHours
      );
      if (!workingHoursAvailability) {
        return false;
      }
    }

    // check working hours availability
    if (productAvailability.FilterTags) {
      const tagsAvailability = this._filterTagsService.isTagsAvailable(
        productAvailability.FilterTags
      );
      if (!tagsAvailability) {
        return false;
      }
    }

    // if all availability checks pass, return true
    return true;
  }

  public isPageAvailable(page: DotPage): boolean {
    if (page?.Avlb && !this.isAvailable(page.Avlb, new Date())) {
      return false;
    }
    if (page.Buttons) {
      const buttonAvailable = page.Buttons.find(
        (button) => this.isButtonAvailable(button) === true
      );
      return buttonAvailable ? true : false;
    }
    if (page.Buttons) {
      const buttonDisabled = page.Buttons.find(
        (button) => this.isButtonDisabled(button) === true
      );
      return buttonDisabled ? true : false;
    }
    return false;
  }

  /**
   * Check combo availability
   * @param combo the combo whose availability is checked
   */
  protected isComboPageAvailable(combos: DotCombo[]): boolean {
    if (!combos) {
      return false;
    }
    const comboUnavailable = combos.find(
      (combo) => this.isComboAvailable(combo) === false
    );
    return comboUnavailable ? false : true;
  }

  /**
   * Check combo component availability
   * @param combo the combo component whose availability is checked
   */
  protected isComboAvailable(combo: DotCombo): boolean {
    if (combo.Buttons) {
      const buttonAvailable = combo.Buttons.find(
        (button) => this.isButtonAvailable(button) === true
      );
      return buttonAvailable ? true : false;
    }
    return false;
  }
}
