import { DotButton, DotCombo } from 'dotsdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ComboDirection } from '../../types/ComboDirection';
import { ComboFooterIcons } from '../../types/ComboFooterIcons';
import { ComboHeaderPictures } from '../../types/ComboHeaderPictures';
import { ComboStep } from '../../types/ComboStep';
import { DotButtonService } from '../dot-button.service';
import { ComboFooterService } from './combo-footer.service';
import { ComboService } from './combo.service';

export class NavigationComboService {
  private _footerService: ComboFooterService;
  private _buttonsSubject: BehaviorSubject<null>;
  private combo: DotButton;
  private _comboSize: string;
  private _comboSteps: DotCombo[];
  private _firstStep = 0;
  private _firstStepLevel: number;
  private _footerIconSubject: BehaviorSubject<ComboFooterIcons>;
  private _footerIcons: ComboFooterIcons = {
    maxSteps: 0,
    first: null,
    second: null,
    third: null,
  };
  private _headerPicturesSubject: BehaviorSubject<ComboHeaderPictures>;
  private _headerPictures: ComboHeaderPictures = {
    drink: null,
    sandwich: null,
    side: null,
  };
  private _lastStep: number;
  private _lastStepLevel: number;
  private _step = 0;

  constructor(
    private _initialButton: DotButton,
    private _comboService: ComboService,
    private _dotButtonService: DotButtonService
  ) {
    this._buttonsSubject = new BehaviorSubject(null);
    this.combo = this._initialButton;
    this._comboSize = this._comboService.getStartSize(this._initialButton);
    this._comboSteps = this._comboService.getVisibleComboSteps(
      this._initialButton
    );
    if (!this.combo.uuid) this._comboService.initializeCombo(this.combo);
    this._footerIconSubject = new BehaviorSubject(this._footerIcons);
    this._headerPicturesSubject = new BehaviorSubject(this._headerPictures);
    this._firstStepLevel = this._comboService.getMinLevel(this.combo);
    this._lastStep = this._comboSteps.length - 1;
    this._lastStepLevel = this._comboService.getMaxLevel(this.combo);

    this._footerService = new ComboFooterService();
    this.setFooterIcons(ComboDirection.FIRST_STEP);

    this._footerIconSubject.subscribe((data) => {
      const { first, second, third } = data;
      this._footerIcons.first = first;
      this._footerIcons.second = second;
      this._footerIcons.third = third;
    });

    this._headerPicturesSubject.subscribe((data) => {
      const { drink, sandwich, side } = data;
      this._headerPictures.drink = drink;
      this._headerPictures.sandwich = sandwich;
      this._headerPictures.side = side;
    });
  }

  /**
   * Return an [[Observable]] that provides the buttons list for the subscriber
   * @return [[Observable]] to subscribe to
   */
  get buttonsObservable(): Observable<DotButton[]> {
    return this._buttonsSubject.pipe(
      map((_) => {
        const comboStepButtons = this._comboService.getVisibleComboStepButtons(
          this.combo,
          this.currentStepLevel
        );
        return comboStepButtons;
      })
    );
  }

  /**
   * @returns The current combo step being edited
   */
  get currentComboStep(): DotCombo {
    return this._comboSteps[this._step];
    // return this._comboService.getComboStep(this._comboButton, this._step);
  }

  /**
   * @returns the current combo step Level value
   */
  get currentStepLevel(): number {
    return this.currentComboStep.Level;
  }

  /**
   * Return an [[Observable]] that provides the footer icons for the subscriber
   * @return [[Observable]] to subscribe to
   */
  get footerIcons(): Observable<ComboFooterIcons> {
    return this._footerIconSubject;
  }

  get footerStep(): number {
    return this._step;
  }

  get firstComboStep(): DotCombo {
    return this._comboSteps[this._firstStep];
  }

  /**
   * Return an [[Observable]] that provides the header pictures for the subscriber
   * @return [[Observable]] to subscribe to
   */
  get headerPictures(): Observable<ComboHeaderPictures> {
    return this._headerPicturesSubject;
  }

  get headerStep(): number {
    return this.currentStepLevel - this._firstStepLevel + 1;
  }

  /**
   * @returns True if any combo item is selected for the current combo step
   */
  get isComboItemSelected(): boolean {
    return this._comboService.isItemSelected(this.combo, this.currentStepLevel);
  }

  /**
   * @returns True if current step is the first combo step
   */
  get isFirstStep(): boolean {
    return this.currentStepLevel === this._firstStepLevel;
  }

  /**
   * @returns True if current step is the last combo step
   */
  get isLastStep(): boolean {
    return this.currentStepLevel === this._lastStepLevel;
  }

  /**
   * @returns True if current step is the 'sides' step
   */
  get isSideStep(): boolean {
    return this.currentComboStep.ComboStepName?.toLowerCase() === ComboStep.SIDE;
  }

  /**
   * @returns True if current step is display only one item button
   */
  get isSingleProductStep(): boolean {
    return this._comboService.isSingleItem(this.combo, this.currentStepLevel);
  }

  /**
   * @returns True if combo is only one step long
   */
  get isSingleStepCombo(): boolean {
    return this._comboSteps.length === 1;
    // return this._firstStepLevel === this._lastStepLevel;
  }

  /**
   * @returns The current combo step buttons or null if none are found
   */
  get singleProduct(): DotButton | null {
    const comboStepButtons = this._comboService.getVisibleComboStepButtons(
      this.combo,
      this.currentStepLevel
    );
    if (!comboStepButtons || !comboStepButtons.length) return null;
    return comboStepButtons[0];
  }

  /**
   * @returns the combo current size
   */
  get size(): string {
    return this._comboSize;
  }

  /**
   * Deselects all currently selected combo step items
   */
  deselectCurrentStepItems(): void {
    this._comboService.deselectComboStepItems(this.currentComboStep);
  }

  /**
   * Navigates forward in the combo navigation.
   * @returns 0 if navigation cannot continue forwards, else the step value
   */
  nextStep(): number | null {
    if (this.isLastStep) return null;
    this._step++;
    this.setFooterIcons(ComboDirection.FORWARD);
    return this._step;
  }

  /**
   * Navigates backward in the combo navigation.
   * @returns 0 if navigation cannot continue forwards, else the step value
   */
  previousStep(): number | null {
    if (this.isFirstStep) return null;
    this._step--;
    this.setFooterIcons(ComboDirection.BACKWARD);
    return this._step;
  }

  /**
   * Sets the footer icons by sending an event to the [[BehaviorSubject]]
   * @param direction If going forward or backward in the combo steps
   */
  setFooterIcons(direction: ComboDirection): void {
    const footerIcons = this._footerService.getFooterIcons(
      direction,
      this._comboSteps,
      this._step,
      this._firstStep,
      this._lastStep
    );
    if (footerIcons) this._footerIconSubject.next(footerIcons);
  }

  /**
   * Sets the header picture by sending an event to the [[BehaviorSubject]]
   */
  setHeaderPictures(): void {
    const newPictures = { ...this._headerPictures };
    const firstStep = this._comboService.getSelectedProduct(
      this.firstComboStep
    );
    const drink = this._comboService.getSelectedDrink(this._comboSteps);
    const side = this._comboService.getSelectedSide(this._comboSteps);
    if (firstStep) {
      newPictures.sandwich = this._dotButtonService.getPicturePath(
        firstStep,
        true
      );
    }
    if (drink) {
      newPictures.drink = this._dotButtonService.getPicturePath(drink, true);
    }
    if (side) {
      newPictures.side = this._dotButtonService.getPicturePath(side, true);
    }
    this._headerPicturesSubject.next(newPictures);
  }
}
