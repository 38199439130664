import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, Observable } from 'rxjs';

import { ModalService } from '@app/core/modal';

import { CountdownPopUpComponent } from '../components/countdown-pop-up/countdown-pop-up.component';
import { PopUp } from '../types/PopUp';

@Injectable({
  providedIn: 'root',
})
export class PopUpService {
  public isVisible = false;
  public stack: PopUp[] = [];
  private _content: BehaviorSubject<PopUp | null> = new BehaviorSubject(null);

  constructor(private _modalService: ModalService) {}

  get content(): Observable<PopUp | null> {
    return this._content.asObservable();
  }

  get isCountdown(): boolean {
    const lastContent = this.stack.length && this.stack[this.stack.length - 1];
    if (!lastContent || !lastContent.component) return false;
    const isCountdown =
      lastContent.component.name === CountdownPopUpComponent.name;
    return isCountdown;
  }

  /**
   * Close the pop-up
   */
  public close(): void {
    this.stack.pop();
    const lastContent = this.stack.length && this.stack[this.stack.length - 1];
    if (lastContent) return this.display(lastContent);
    this.isVisible = false;
    this._content.next(null);
    this._modalService.closePopUp();
    Sentry.setContext('popup', null);
  }

  /**
   * Open the pop-up
   */
  public open(content: PopUp): void {
    if (!content) return;
    this.stack.push(content);
    this.display(content);
  }

  /**
   * Display the pop-up content
   */
  private display(content: PopUp): void {
    if (!content) this._modalService.closePopUp();
    this._content.next(content);
    this.isVisible = true;
    this._modalService.setPopUp();
    Sentry.setContext('popup', null);
    Sentry.setContext('popup', {
      component: content?.component?.name,
    });
  }
}
