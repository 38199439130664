import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sat',
  templateUrl: './sat.component.pug',
  styleUrls: ['./sat.component.scss']
})
export class SatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
