import { Component, EventEmitter, Output } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.pug',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Output() onBack = new EventEmitter();

  constructor(private _t: TranslationService) {}

  /**
   * Return to previous navigation
   */
  onClick(): void {
    this.onBack.emit();
  }
}
