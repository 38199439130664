import { Component, OnInit } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { NavigationService, ScreenType } from '@core/navigation';
import { TranslationService } from '@core/translation';


@Component({
  selector: 'app-sat-activate',
  templateUrl: './sat-activate.component.pug',
  styleUrls: ['./sat-activate.component.scss'],
})
export class SatActivateComponent implements OnInit {


  constructor(
    private _navigationService: NavigationService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService
  ) {}

  ngOnInit(): void {}

  /**
   * Check if PMR mode is enabled
   */
  private get accessibilityStatus(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Activate Service At Table
   */
  private acceptSat(): void {
      this._navigationService.addStack({
        type: ScreenType.SERVICE_AT_TABLE_FLAG_NUMBER,
        content: null,
      });
  }

  /**
   * Activate Service At Table
   */
  private refuseSat(): void {
      this._navigationService.addStack({
        type: ScreenType.PAYMENT,
        content: null,
      });
  }
}
