import { Injectable } from '@angular/core';
import { DotButton } from 'dotsdk';

import { ComboService, DotButtonService } from '@core/acrelec-content';
import { ContentService } from '@core/acrelec-external';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';

import { CustomAllergen } from '../types/CustomAllergen';

@Injectable({
  providedIn: 'root',
})
export class AllergensService {
  private _allergens: CustomAllergen[];

  constructor(
    private _contentService: ContentService,
    private _db: DotButtonService,
    private _comboService: ComboService,
    private _sessionService: SessionService,
    private _t: TranslationService
  ) {
    this._allergens = this._contentService.getAllergens() as CustomAllergen[];
    this._allergens.map((allergen: CustomAllergen) => ({
      ...allergen,
      isSelected: false,
    }));
    // Subscribes to the session observable and reset filters on session reset
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) this.resetFilter();
    });
  }

  /**
   * Return the list of all allergens
   */
  get allergens(): CustomAllergen[] {
    return this._allergens;
  }

  /**
   * Return allergens selected by the customer
   */
  get selectedAllergens(): CustomAllergen[] {
    return this._allergens.filter(
      (allergen: CustomAllergen) => allergen.isSelected
    );
  }

  /**
   * Compare product allergens with customer allergens
   * @param button Button we need to get allergens for
   */
  checkButtonAllergen(button: DotButton) {
    const mainProduct = this._db.isCombo(button)
      ? this._comboService.getMainProduct(button)
      : button;

    if (
      !mainProduct ||
      !mainProduct.AllergensAndNutritionalValues ||
      mainProduct.AllergensAndNutritionalValues.Allergens.length <= 0
    ) {
      return false;
    } else {
      const selected = this.selectedAllergens;

      const buttonAllergens = this._db.getAllergens(mainProduct);
      return selected.some((selectedAllergen: CustomAllergen) => {
        return buttonAllergens.find((allergen: CustomAllergen) =>
          allergen.Name.includes(selectedAllergen.Name)
        );
      });
    }
  }

  /**
   * Change allergen's selection status
   * @param name Allergen short code
   */
  selectAllergen(name: string): void {
    this._allergens.find((allergen: CustomAllergen) => {
      if (allergen.Name === name) {
        allergen.isSelected = !allergen.isSelected;
      }
    });
  }

  /**
   * Set all allergens as unselected
   */
  resetFilter(): void {
    this._allergens.forEach(
      (allergen: CustomAllergen) => (allergen.isSelected = false)
    );
  }

  /**
   * Returns allergen's name
   * @param allergen Allergen we need the name for
   */
  getAllergenName(allergen: CustomAllergen): string {
    const labels = (allergen.Label as unknown) as string;
    return labels;
  }

  /**
   * Returns allergen's picture
   * @param allergen Allergen we need the picture from
   */
  getAllergenCaption(allergen: CustomAllergen): string {
    const picture = allergen.Name.toUpperCase();
    return `assets/images/allergens/${picture}.svg`;
  }
}
