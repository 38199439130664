import {
  animate,
  style,
  animation,
  query,
  keyframes,
} from '@angular/animations';

export class Animations {
  static readonly deleteBasketProduct = animation(
    [
      animate(
        '{{ time }}',
        keyframes([
          style({
            opacity: 1,
            offset: 0,
          }),
          style({
            opacity: 1,
            offset: 0.4,
          }),
          style({
            opacity: 0,
            transform: 'translateX(-970rem)',
            offset: 1,
          }),
        ])
      ),
    ],
    { params: { time: '400ms' } }
  );

  static readonly displayBasketProducts = animation(
    [
      animate(
        '{{ time }}',
        keyframes([
          style({
            marginTop: '100%',
            offset: 0,
          }),
          style({
            marginTop: '50%',
            offset: 0.5,
          }),
          style({
            marginTop: '0%',
            offset: 0.6,
          }),
          style({
            marginTop: '2%',
            offset: 0.7,
          }),
          style({
            marginTop: '0%',
            offset: 0.8,
          }),
          style({
            marginTop: '3%',
            offset: 0.9,
          }),
          style({
            marginTop: '0%',
            offset: 1,
          }),
        ])
      ),
    ],
    { params: { time: '400ms' } }
  );

  static readonly fadeOut = animation(
    [
      style({ opacity: '{{ start }}' }),
      animate('{{ time }}', style({ opacity: '{{ end }}' })),
    ],
    { params: { time: '300ms', start: 1, end: 0 } }
  );

  static readonly hideBasketContent = animation(
    [
      style({ height: '100%' }),
      animate('{{ time }} linear', style({ height: '0' })),
    ],
    { params: { time: '3000ms' } }
  );

  static readonly inflateAnimation = animation(
    [
      style({
        transform: 'scale({{ scale }})',
      }),
      animate('{{ time }}'),
    ],
    { params: { time: '1200ms', scale: '1.2' } }
  );

  static readonly inflateModal = animation(
    [
      style({
        'border-radius': '50%',
        height: '150rem',
        opacity: 0,
        overflow: 'hidden',
        width: '150rem',
      }),
      animate(
        '{{ time }} ease-in',
        style({
          'border-radius': '15rem',
          height: '*',
          opacity: 1,
          overflow: 'auto',
          width: '*',
        })
      ),
    ],
    { params: { time: '200ms' } }
  );

  static readonly showModalChild = animation(
    [
      style({
        opacity: 0,
      }),
      animate(
        '{{ time }}',
        style({
          opacity: 1,
        })
      ),
    ],
    { params: { time: '300ms' } }
  );

  static readonly pageTransition = animation([
    style({ position: 'relative' }),
    query(
      '#PageTransition',
      [
        style({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#F59400',
          borderRadius: '100%',
          height: '100rem',
          width: '100rem',
          zIndex: '8',
        }),
        animate(
          '300ms ease-in',
          keyframes([
            style({ transform: 'scale(1)', offset: 0 }),
            style({ transform: 'scale(0.2)', offset: 0.1 }),
            style({ transform: 'scale(25)', offset: 1 }),
          ])
        ),
      ],
      {
        optional: true,
      }
    ),
    query(':enter', [animate('0ms ease-out'), style({ display: 'none' })], {
      optional: true,
    }),
    query(':leave', [animate('0ms ease-out')], {
      optional: true,
    }),
  ]);
}
