import { Component, OnDestroy, OnInit } from '@angular/core';
import { sessionEndEvent } from 'dotsdk';

import { PaymentType } from '@app/pages/payment/components/types/PaymentType';
import { AccessibilityService } from '@core/accessibility';
import { NavigationService, ScreenType } from '@core/navigation';
import { PaymentService } from '@core/payment';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.pug',
  styleUrls: ['./payment-failure.component.scss'],
})
export class PaymentFailureComponent implements OnInit, OnDestroy {
  private _countdownStartNumber = 30;
  private _countdownTask: number;
  constructor(
    private _t: TranslationService,
    private _navigationService: NavigationService,
    private _paymentService: PaymentService,
    private _sessionService: AccessibilityService
  ) {}

  ngOnInit(): void {
    // Initialize a cooldown before cancel order
    this.getCountdown();
  }

  ngOnDestroy(): void {
    clearInterval(this._countdownTask);
  }

  /**
   * Check if PMR mode is enabled
   */
  private get accessibilityStatus(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Launch the cooldown before cancelling the order
   */
  private getCountdown(): void {
    this._countdownTask = setInterval(() => {
      this._countdownStartNumber -= 1;
      if (this._countdownStartNumber < 1) {
        clearInterval(this._countdownTask);
        this.cancelOrder();
      }
    }, 1000) as unknown as number;
  }

  /**
   * Cancel the order and returns to the default page
   */
  private cancelOrder(): void {
    this._paymentService.voidOrderOnPos();
    this._paymentService.resetOrder();
    this._navigationService.resetStack();
  }

  /**
   * Retry the failed payment
   */
  private retryPayment(): void {
    this._paymentService.processPayment(PaymentType.REMOTE, true).catch(() => undefined);
  }
}
