import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { DotButton } from 'dotsdk';

import { ComboFooterIcons } from '@core/acrelec-content';
import { TranslationService } from '@core/translation';

interface Classes {
  id: Id;
  classes: { 'Step--valid': boolean; 'Step--selected': boolean };
}

enum Id {
  first = 'First',
  second = 'Second',
  third = 'Third',
}

@Component({
  selector: 'app-combo-footer',
  templateUrl: './combo-footer.component.pug',
  styleUrls: ['./combo-footer.component.scss'],
})
export class ComboFooterComponent implements OnInit, OnChanges {
  @Input() step: number;
  @Input() disabled: boolean;
  @Input() displayStepper: boolean;
  @Input() displayLastStepBtn: boolean;
  @Input() icons: ComboFooterIcons;
  @Output() onContinue = new EventEmitter<DotButton>();
  @Output() lockUserInteraction = new EventEmitter<boolean>();
  @Output() onCancel = new EventEmitter();
  private _state: Classes[];
  private _selectedProduct: DotButton;

  constructor(private _t: TranslationService) {}

  ngOnInit() {
    this._state = this.setStatus();
  }

  ngOnChanges() {
    this._state = this.setStatus();
  }

  /**
   * @param id The step's id
   * @returns The classes set by the setStatus() method on init and changes
   */
  getStepClass(id: Id) {
    if (!this._state) return;
    const state = this._state.find((step) => step.id === id);
    return state.classes;
  }

  /**
   * Get the path SVG to the SVG file based on the provided ID
   * @param id
   */
  getSVG(id: Id): string {
    if (
      id === Id.first &&
      this.icons.first &&
      this.step === this.icons.first.step
    ) {
      return this.icons.first.path;
    }
    if (
      id === Id.second &&
      this.icons.second &&
      this.step === this.icons.second.step
    ) {
      return this.icons.second.path;
    }
    if (
      id === Id.third &&
      this.icons.third &&
      this.step === this.icons.third.step
    ) {
      return this.icons.third.path;
    }
    return 'assets/images/icons/combo-check.svg';
  }
  /**
   * Get the path SVG to the SVG file based on the provided ID
   * @param id
   */
  getSvgAttributes(id: Id): {} {
    if (
      (id === Id.first && this.step === this.icons.first.step) ||
      (id === Id.second && this.step === this.icons.second.step) ||
      (id === Id.third && this.step === this.icons.third.step)
    ) {
      return { fill: 'var(--bk-bbq-brown)' };
    }
    return { fill: 'var(--bk-white)' };
  }

  /**
   * Get the title based on the provided ID
   * @param id
   */
  getTitle(id: Id): string | null {
    switch (id) {
      case Id.first:
        return this.icons.first ? this.icons.first.title : null;
      case Id.second:
        return this.icons.second ? this.icons.second.title : null;
      case Id.third:
        return this.icons.third ? this.icons.third.title : null;
      default:
        return null;
    }
  }

  private get isSecondStepActive(): boolean {
    if (!this.icons || !this.icons.maxSteps || !this.icons.second) return false;
    return this.icons.second.step <= this.icons.maxSteps;
  }

  private get isThirdStepActive(): boolean {
    if (!this.icons || !this.icons.maxSteps || !this.icons.third) return false;
    return this.icons.third.step <= this.icons.maxSteps;
  }

  private lockInteraction(isLocked: boolean): void {
    this.lockUserInteraction.emit(isLocked);
  }

  private onCancelClick() {
    this.onCancel.emit();
  }

  private onContinueClick() {
    if (this.disabled) {
      return;
    }
    this.onContinue.emit(this._selectedProduct);
  }

  /**
   * Defines the status of the footer icons depending on the current step.
   * Selected: current step
   * Unselected: next step
   * Valid: previously validated step
   */
  private setStatus(): Classes[] {
    const unselected = { 'Step--valid': false, 'Step--selected': false };
    const selected = { 'Step--valid': false, 'Step--selected': true };
    const valid = { 'Step--valid': true, 'Step--selected': false };
    if (!this.icons || !this.icons.first) {
      return;
    }

    switch (this.step) {
      case this.icons.first.step:
        return [
          { id: Id.first, classes: selected },
          { id: Id.second, classes: unselected },
          { id: Id.third, classes: unselected },
        ];
      case this.icons.second.step:
        return [
          { id: Id.first, classes: valid },
          { id: Id.second, classes: selected },
          { id: Id.third, classes: unselected },
        ];
      case this.icons.third.step:
        return [
          { id: Id.first, classes: valid },
          { id: Id.second, classes: valid },
          { id: Id.third, classes: selected },
        ];
      default:
        return [
          { id: Id.first, classes: unselected },
          { id: Id.second, classes: unselected },
          { id: Id.third, classes: unselected },
        ];
    }
  }
}
