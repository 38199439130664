import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { ModalModule } from '@core/modal';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { PopUpModule } from '@ui/pop-up';

import { SatKeyboardComponent } from './components/sat-keyboard.component';
import { SatSingleKeyComponent } from './components/sat-single-key/sat-single-key.component';

@NgModule({
  declarations: [SatKeyboardComponent, SatSingleKeyComponent],
  imports: [
    AccessibilityModule,
    CommonModule,
    InlineSVGModule,
    ModalModule,
    PopUpModule,
    SessionModule,
    TranslationModule,
  ],
  exports: [SatKeyboardComponent],
})
export class SatKeyboardModule { }
