import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecExternalModule } from '@core/acrelec-external';
import { SessionModule } from '@core/session';

@NgModule({
  declarations: [],
  imports: [AcrelecExternalModule, CommonModule, SessionModule],
})
export class TranslationModule {}
