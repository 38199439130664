import { Injectable } from '@angular/core';
import {
  AbordMethodEnum,
  ContextRequest,
  EnvironmentEnum,
  OrderStateEnum,
  Product as RecommendationProduct,
  RecommendationSdk,
  RecommendationSdkDelay,
  ServiceTypeEnum,
  UpdateOrderRequest,
} from 'acrelec-recommendation-sdk';
import {
  AtpEnvironmentService,
  DotButton,
  DotButtonType,
  PosServingLocation,
} from 'dotsdk';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import * as uuid from 'uuid';

import { PaymentSteps } from '@app/core/payment/types/PaymentSteps';
import {
  AvailabilityService,
  ComboService,
  ContentValidationService,
  DotButtonService,
} from '@core/acrelec-content';
import { AppSettingsService, ContentService } from '@core/acrelec-external';
import { SessionService } from '@core/session';

@Injectable({
  providedIn: 'root',
})
export class RecommendationService {
  public authenticated = false;
  private readonly POPULAR_COUNT = 10;
  private buttonTypes = {
    0: 'PAGE_BUTTON',
    1: 'MENU_BUTTON',
    2: 'ITEM_BUTTON',
    3: 'GROUP_BUTTON',
    5: 'JUMP_BUTTON',
    10: 'PROMO_BUTTON',
    11: 'ITEM_PACK_BUTTON',
    15: 'PROMOTIONS_BUTTON',
    16: 'DISCOUNT_BUTTON',
  };
  private orderToken: string | undefined;
  private orderStartTime: number | undefined;

  private recommendationSDK: RecommendationSdk;
  private recommendationSDKDelay: RecommendationSdkDelay;

  private popularProductsSubject = new BehaviorSubject<DotButton[] | undefined>(
    undefined
  );

  constructor(
    private _appSettingsService: AppSettingsService,
    private _comboService: ComboService,
    private _contentService: ContentService,
    private _db: DotButtonService,
    private _sessionService: SessionService,
    private _availabilityService: AvailabilityService,
    private _contentValidationService: ContentValidationService
  ) {
    this.recommendationSDK = new RecommendationSdk({
      endpoint: this._appSettingsService.recommendationEndpoint,
      defaultStore: this._appSettingsService.storeId,
    });
    this.recommendationSDKDelay = new RecommendationSdkDelay(
      this.recommendationSDK,
      { autoStart: true, defaultTimer: 10000 }
    );

    this.recommendationSDK.auth
      .store({
        storeId: this._appSettingsService.storeId,
        publicKey: this._appSettingsService.publicToken,
      })
      .then(() => {
        this.authenticated = true;
      });

    if (_appSettingsService.isEnrichmentEnabled) {
      // Subscribes to the session observable and reset tent number on session reset
      this._sessionService.resetSession$.subscribe((reset: boolean) => {
        if (reset) {
          if (this.orderToken) {
            this.updateOrder({
              abortDate: new Date(),
              abortMethod: AbordMethodEnum.MANUAL,
              state: OrderStateEnum.CANCELLED,
            }).catch(() => undefined);
          }
          this.orderToken = undefined;
        }
      });

      this._sessionService.paymentStatus$.subscribe(
        (paymentStep: PaymentSteps) => {
          if (this.orderToken && paymentStep === PaymentSteps.Success) {
            this.updateOrder({
              paymentDate: new Date(),
              state: OrderStateEnum.PAID,
              paidAmount: this._sessionService._paymentPrice,
            }).catch(() => undefined);
            const currentTime = Date.now();
            this.updateMetric('end_time', currentTime).catch(() => undefined);
            this.updateMetric(
              'elapsed_time',
              currentTime - this.orderStartTime
            ).catch(() => undefined);
            this.updateMetric(
              'total_price',
              this._sessionService._paymentPrice
            ).catch(() => undefined);
            this.orderToken = undefined;
          }
        }
      );
    }
  }

  /**
   * @returns true if reco engine is enabled in bundle settings
   */
  private get isRecommendationActice(): boolean {
    return this._appSettingsService.isRecommendationEnabled;
  }

  /**
   * @returns true if reco engine is enabled in bundle settings
   */
  private get isEnrichmentActice(): boolean {
    return this._appSettingsService.isEnrichmentEnabled;
  }

  public get popularProducts$(): Observable<DotButton[]> {
    return this.popularProductsSubject.asObservable();
  }

  async createOrder(
    environment: EnvironmentEnum,
    serviceType: PosServingLocation
  ) {
    if (!this.isEnrichmentActice) return;
    this.orderToken = uuid.v4();
    this.orderStartTime = Date.now();

    const atpEnvironment = await AtpEnvironmentService.getInstance().getEnvironmentDetails();
    const atpBundleSettings = await AtpEnvironmentService.getInstance().getBundleSettings();

    await this.logErrors(
      this.recommendationSDKDelay.orders.create({
        serviceType:
          serviceType === PosServingLocation.IN
            ? ServiceTypeEnum.EAT_IN
            : ServiceTypeEnum.TAKE_OUT,
        token: this.orderToken,
        environment,
        context: {
          storeName: atpEnvironment.StoreDetails.Name,
          storeCode: atpEnvironment.StoreDetails.Code,
          nestName: atpEnvironment.NestDetails.Name,
          nestId: atpEnvironment.NestIdentifier,
          bundleSettings: atpBundleSettings,
          environment: atpEnvironment,
        },
      })
    );
    await this.updateMetric('start_time', this.orderStartTime);
  }

  async getPopularProducts(
    itemsCount: number,
    ContextRequest?: ContextRequest
  ): Promise<DotButton[] | undefined> {
    if (!this.isRecommendationActice) return undefined;
    const response: RecommendationProduct[] = await this.getPopularsItems(
      itemsCount,
      ContextRequest
    );
    return this.findProductItems(response);
  }

  async getRecommendationProducts(
    itemsCount: number,
    ContextRequest?: ContextRequest
  ): Promise<DotButton[] | undefined> {
    if (!this.isRecommendationActice) return undefined;
    const response: RecommendationProduct[] = await this.getRecommendationItems(
      itemsCount,
      ContextRequest
    );
    const products = this.findProductItems(response);
    return products;
  }

  async updateMetric(name: string, value: number): Promise<void> {
    if (!this.isEnrichmentActice) return;
    // return this.logErrors(RecommendationSDK.updateMetric(this.orderToken, name, value));
    return;
  }

  async updateOrder(data: UpdateOrderRequest): Promise<void> {
    if (!this.isEnrichmentActice) return;
    return this.logErrors(
      this.recommendationSDKDelay.orders.updateByToken(this.orderToken, data)
    );
  }

  async updateOrderItem(button: DotButton): Promise<void> {
    if (!this.isEnrichmentActice) return;

    if (!(button as any).recommendationKey) {
      (button as any).recommendationKey = uuid.v4();
    }

    const isRecommendation = !!(button as any).IsRecommendation;
    return this.logErrors(
      this.recommendationSDKDelay.orders.createOrUpdateItemInOrderByToken(
        this.orderToken,
        {
          key: (button as any).recommendationKey,
          productType: this.getTypeFromButtonType(button.ButtonType),
          productId: button.Link,
          quantity: button.quantity,
          additionalData: {
            isRecommendation,
            combo:
              button.ComboPage &&
              button.ComboPage.Combos.map((combo) => ({
                name: combo.ComboStepName,
                items: combo.Buttons.filter(
                  (button) => button.Selected || button.quantity > 0
                ).map((button) => ({
                  name: button.Caption,
                  id: button.Link,
                  quantity: button.quantity,
                  selected: button.Selected,
                  modifiers: button?.ModifiersPage?.activeModifiers,
                })),
              })),
          },
        }
      )
    );
  }

  /**
   * If the product is a dropdown child, we have no way of knowing it.
   * We should return the orginal product values from the catalog as they are normally correct
   * @param product pages.json product's button
   * @returns the product with the modified caption and picture
   */
  public getCatalogValues(product: DotButton): DotButton {
    if (this._db.isCombo(product)) {
      return this.getComboCatalogValues(product);
    }
    const catalogProduct = this._contentService.getCatalogProductByLink(
      product.Link
    );
    if (!catalogProduct) {
      return product;
    }
    const productClone = _.cloneDeep(product);
    productClone.Caption = catalogProduct.Caption;
    productClone.Picture = catalogProduct.Picture;
    (productClone as any).IsRecommendation = true;
    return productClone;
  }

  /**
   * Fetch new recommendations popular items.
   */
  public async updatePopularProducts(context?: ContextRequest): Promise<void> {
    const popularProducts = await this.getPopularProducts(
      this.POPULAR_COUNT,
      context
    );
    this.popularProductsSubject.next(popularProducts.slice(0, 3));
  }

  private async logErrors(request: Promise<any>): Promise<any | undefined> {
    try {
      return await request;
    } catch (e) {
      console.error(`Could not reach recommendation engine: ${e.message}`);
      return undefined;
    }
  }

  private async getPopularsItems(
    itemsCount: number,
    ContextRequest?: ContextRequest
  ): Promise<RecommendationProduct[]> {
    if (!this.isRecommendationActice) return undefined;
    return this.recommendationSDK.recommendation.getPopulars(
      ContextRequest,
      itemsCount
    );
  }

  private async getRecommendationItems(
    itemsCount: number,
    ContextRequest?: ContextRequest
  ): Promise<RecommendationProduct[]> {
    if (!this.isRecommendationActice) return undefined;
    return this.recommendationSDK.recommendation.getRecommendationByOrderToken(
      this.orderToken,
      ContextRequest,
      itemsCount
    );
  }

  /**
   * Returns an array of [DotButton] clones, modified using the catalog.json value for dropdowns and combos.
   * @param productIds IDs of product to get from JSON
   * @returns an array of [DotButton] clones
   */
  private findProductItems(products: RecommendationProduct[]): DotButton[] {
    return products
      .map((recommendationProduct) => {
        const product = this._contentService.getProductByLink(
          recommendationProduct.productId
        );
        return product && this.getCatalogValues(product);
      })
      .filter((product) => !!product)
      .filter((product) => !this._db.isDropdown(product))
      .filter(
        (product) =>
          this._availabilityService.isButtonAvailable(product) &&
          !this._availabilityService.isButtonDisabled(product) &&
          this._contentValidationService.isButtonValid(product)
      );
  }

  /**
   * If the product is a combo, we need to retrieve the main product's picture and caption.
   * @param product pages.json product's button
   * @returns the product with the modified caption and picture
   */
  private getComboCatalogValues(product: DotButton): DotButton {
    const mainComboProduct = this._comboService.getMainProduct(product);
    const catalogProduct = this._contentService.getCatalogProductByLink(
      mainComboProduct.Link
    );
    const productClone = _.cloneDeep(product);
    (productClone as any).IsRecommendation = true;
    if (!catalogProduct) {
      return product;
    }
    productClone.Caption = `${catalogProduct.Caption} ${product.Caption}`;
    productClone.Picture = catalogProduct.Picture;
    return productClone;
  }

  private getTypeFromButtonType(buttonType: DotButtonType): string {
    return this.buttonTypes[buttonType] || 'unknown';
  }
}
