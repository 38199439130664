import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { NavigationModule } from '@core/navigation';
import { PaymentModule as PaymentServiceModule } from '@core/payment';
import { TranslationModule } from '@core/translation';
import { AccessibilityModule as AccessibilityUiModule } from '@ui/accessibility';

import {
  PaymentComponent,
  PaymentCounterComponent,
  PaymentFailureComponent,
  PaymentProcessingComponent,
  PaymentRemoteComponent,
  PaymentSuccessComponent,
  PaymentTypeComponent
} from './components';
import { PaymentWaitingComponent } from './components/payment-waiting/payment-waiting.component';
import { PaymentRoutingModule } from './payment-routing.module';



@NgModule({
  declarations: [
    PaymentComponent,
    PaymentCounterComponent,
    PaymentFailureComponent,
    PaymentProcessingComponent,
    PaymentRemoteComponent,
    PaymentSuccessComponent,
    PaymentTypeComponent,
    PaymentWaitingComponent,
  ],
  imports: [
    AcrelecExternalModule,
    AccessibilityModule,
    AccessibilityUiModule,
    CommonModule,
    InlineSVGModule,
    NavigationModule,
    PaymentRoutingModule,
    PaymentServiceModule,
    TranslationModule,
  ],
  exports: [PaymentComponent],
})
export class PaymentModule {}
