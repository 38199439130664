import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateAvailabilityService {
  constructor() { }

  /**
   * @param dayOfWeek day of the week number
   * @param currentTime moment when the availability is check
   * Check day of week availability
   */
  public checkDateAvailability(startDate: string, stopDate: string, dateOfComparing: Date): boolean {
    const comparingDate = dateOfComparing.valueOf();
    const beginDate = this.stringToDate(startDate, dateOfComparing);
    const endDate = this.stringToDate(stopDate, dateOfComparing, false);

    if (comparingDate < beginDate) {
      return false;
    }
    if (endDate < comparingDate) {
      return false;
    }
    return true;
  }

  protected stringToDate(value: string, dateOfComparing: Date, startDate: boolean = true): number {
    if (value) {
      return Date.parse('20' + value[0] + value[1] + '-' + value[2] + value[3] + '-' + value[4] + value[5]);
    } else {
      return (startDate)
        ? dateOfComparing.valueOf() - 100 * 24 * 60 * 60 * 1000
        : dateOfComparing.valueOf() + 100 * 24 * 60 * 60 * 1000;
    }
  }
}
