import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { Animations } from '@core/animations';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-basket-button',
  templateUrl: './basket-button.component.pug',
  styleUrls: ['./basket-button.component.scss'],
  animations: [
    trigger('inflateProduct', [
      transition(':increment, :decrement', [
        useAnimation(Animations.inflateAnimation, {
          params: {
            scale: 1.2,
            time: '100ms',
          },
        }),
      ]),
    ]),
  ],
})
export class BasketButtonComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() isOpen = false;
  @Input() isPmr = false;
  @Input() productCount: string;
  @Output() displayBasketContent = new EventEmitter<void>();

  constructor(private _t: TranslationService) {}

  ngOnInit(): void {}

  /**
   * Toggle basket visibility
   */
  private basketClick(): void {
    this.displayBasketContent.emit();
  }
}
