import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ElementRef,
} from '@angular/core';
import { DotButton, DotModifier } from 'dotsdk';

import { AccessibilityService } from '@core/accessibility';
import {
  DotButtonService,
  ContentValidationService,
  PriceCalculationService,
  AvailabilityService,
} from '@core/acrelec-content';
import { AllergensService } from '@core/allergens';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

@Component({
  selector: 'app-modifiers-list',
  templateUrl: './modifiers-list.component.pug',
  styleUrls: ['./modifiers-list.component.scss'],
})
export class ModifiersListComponent implements OnInit {
  @Input() modifier: DotModifier[];
  @Input() buttons: DotButton[];
  @Input() center = false;
  @Input() isMaxModifiers: boolean;
  @Input() isSingleProduct?: boolean = false;
  @Input() isRecommendationDisplay?: boolean = false;
  @Input() isOpen = false;
  @Output() onAddModifier = new EventEmitter<DotButton>();
  @Output() onRemoveModifier = new EventEmitter<DotButton>();

  private _language: string;

  constructor(
    private _allergensService: AllergensService,
    private _contentService: ContentValidationService,
    private _db: DotButtonService,
    private _popUpService: PopUpService,
    private _priceService: PriceCalculationService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService,
    private _modifiersList: ElementRef,
    private _availabilityService: AvailabilityService
  ) {}

  ngOnInit() {
    this._language = this._t.language;
  }

  /**
   * Checks if button has free modifiers
   */
  private get hasFreeButtons(): boolean {
    if (!this.buttons) {
      return false;
    }
    return this.buttons.filter((button) => {
      return (
        parseInt(button.Price, 10) <= 0 &&
        this._contentService.isButtonValid(button)
      );
    }).length > 0
      ? true
      : false;
  }

  /**
   * Returns product's free modifiers
   */
  private get freeButtons(): DotButton[] {
    return this.buttons.filter((button) => parseInt(button.Price, 10) <= 0);
  }

  /**
   * Returns style data to center elements if needed
   */
  private get centeredStyle() {
    if (!this.center) return null;

    return {
      'justify-content': 'center',
    };
  }

  /**
   * Checks if products has premium modifiers
   */
  private get hasPremiumButtons(): boolean {
    if (!this.buttons) {
      return false;
    }
    return (
      this.buttons.filter((button) => {
        return (
          parseInt(button.Price, 10) > 0 &&
          this._contentService.isButtonValid(button)
        );
      }).length > 0
    );
  }

  /**
   * If a product has premium modifiers, returns them, otherwise returns free modifiers
   */

  private get premiumButtons(): DotButton[] {
    if (!this.buttons) {
      return [];
    }
    const buttons = this.buttons.filter(
      (button) => parseInt(button.Price, 10) > 0
    );

    // if no premium buttons, return free buttons
    return this.hasPremiumButtons ? buttons : this.freeButtons;
  }

  /**
   * Displays pop up to inform custorm that the current modifier has allergens he selected
   */
  private displayAllergensAlert(button: DotButton): void {
    this._popUpService.open({
      component: MessagePopUpComponent,
      componentData: this._t.translate(
        39,
        'Please note this product contains one or several allergens that you selected'
      ),
      rightButtonContent: this._t.translate(28, 'continue'),
      rightButtonCallback: () => {
        this._popUpService.close();
        this.onAddModifier.emit(button);
      },
      leftButtonContent: this._t.translate(23, 'cancel'),
      leftButtonCallback: () => {
        this._popUpService.close();
      },
    });
  }

  /**
   * Checks the modifier's button position and depending on it, scrolls in the modal to display modifiers in the center of it
   */
  private displayModifiersAnimation(): void {
    const modifiersPosition = document
      .getElementsByClassName('FreeModifiers')[0]
      .getBoundingClientRect();
    const maxElementPosition = this._sessionService.isAccessibilityActivated
      ? 1590
      : 1200;
    const scrollElement =
      this._sessionService.isAccessibilityActivated && !this.isSingleProduct
        ? document.getElementsByClassName('Modal')[0]
        : this._modifiersList.nativeElement;
    const necessaryScroll = modifiersPosition.y - maxElementPosition;
    const parent = document.getElementById('Scroll');
    const currentScroll = parent.scrollTop;
    if (modifiersPosition && modifiersPosition.y > maxElementPosition) {
      setTimeout(() => {
        const scrollTo =
          currentScroll === 0
            ? necessaryScroll
            : currentScroll + necessaryScroll;
        scrollElement.scrollTo({ top: scrollTo, behavior: 'smooth' });
      });
    }
  }

  /**
   * Display or hide free modifiers
   */
  private freeModifiersClick(): boolean {
    if (this.isOpen) {
      this.isOpen = false;
      this._modifiersList.nativeElement.scrollTop = 0;
      return;
    }
    this.isOpen = true;
    this.displayModifiersAnimation();
  }

  /**
   * Add modifier to the ordered product
   */
  private onAdd(button: DotButton) {
    if (this._allergensService.checkButtonAllergen(button)) {
      this.displayAllergensAlert(button);
    } else {
      if (isNaN(button.quantity)) {
        button.quantity = 0;
      }
      this.onAddModifier.emit(button);
    }
  }

  /**
   * Remove modifier from the ordered product
   */
  private onRemove(button: DotButton) {
    this.onRemoveModifier.emit(button);
  }

  /**
   * Disable products from the modifiers
   */
  private isProductDisabled(button: DotButton): boolean {
    return this._availabilityService.isButtonDisabled(button);
  }
}
