import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-remote',
  templateUrl: './payment-remote.component.pug',
  styleUrls: ['./payment-remote.component.scss'],
})
export class PaymentRemoteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
