import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContextRequest } from 'acrelec-recommendation-sdk';
import { DotButton } from 'dotsdk';
import { BehaviorSubject, Observable } from 'rxjs';

import { RecommendationService } from '@app/core/recommendation';
import { AccessibilityService } from '@core/accessibility';
import {
  ComboService,
  DotButtonService,
  PriceCalculationService,
} from '@core/acrelec-content';
import { TranslationService } from '@core/translation';
import { ModalService, ModalType } from '@app/core/modal';
import { ContentService } from '@app/core/acrelec-external';

@Component({
  selector: 'app-recommendation-list',
  templateUrl: './recommendation-list.component.pug',
  styleUrls: ['./recommendation-list.component.scss'],
})
export class RecommendationListComponent implements OnChanges, OnInit {
  @Input() isBackButtonVisible = false;
  @Output('onProductButtonClick') onProductClick = new EventEmitter<{
    button: DotButton;
    event: Event;
  }>();
  private readonly isRecommendation = true;
  private readonly isHomePage = true;

  constructor(
    private _db: DotButtonService,
    private _comboService: ComboService,
    private _priceCalculationService: PriceCalculationService,
    private _recommendationService: RecommendationService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService,
    private _modalService: ModalService,
    private _contentService: ContentService,
    private _dotButtonService: DotButtonService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.updatePopularProducts();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.updatePopularProducts();
  }

  /**
   * Check if PMR mode is enabled
   */
  private get isAccessibilityActivated(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Get the calories from the product
   * @param product The desired product
   */
  private getProductCalories(product: DotButton): string | null {
    if (
      product.Page &&
      product.Page.Buttons.length > 0 &&
      product.Tags.includes('dropdown')
    ) {
      // Retrieve calories from the single product if present
      const singleProduct = product.Page.Buttons.find(
        (button) => !this._db.isCombo(button)
      );
      if (singleProduct) {
        return this._db.getCalories(singleProduct);
      }
      // Retrieve calories from the first combo main product if no single item is present
      const comboProduct = product.Page.Buttons.find((button) =>
        this._db.isCombo(button)
      );
      if (comboProduct) {
        return this._comboService.getCalories(comboProduct);
      }
    }
    return this._db.getCalories(product);
  }

  /**
   * Used to open a dropdown or to navigate
   * depending on the button type
   * @param event
   * @param button
   */
  private onProductButtonClick(event: any, button?: DotButton): void {
    const parentPage = this._contentService.getPageByButtonLink(
      (button as any).parent
    );

    if (parentPage && this._dotButtonService.isDropdownPage(parentPage)) {
      setTimeout(() => {
        this._modalService.setModal({
          type: ModalType.RECOMMENDATION_SELECT,
          data: {
            initialProduct: button,
            products: parentPage.Buttons.map((button) => this._recommendationService.getCatalogValues(button)),
          },
        });
      });
    }

    if (button) {
      return this.onProductClick.emit({
        button,
        event,
      });
    }
    return this.onProductClick.emit({
      button: event.button,
      event: event.event,
    });
  }

  private async updatePopularProducts(context?: ContextRequest): Promise<void> {
    // Removed for the moment. Moved to screen saver.
    // await this._recommendationService.updatePopularProducts(context);
  }
}
