import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@pipes';
import { ModifiersModule } from '@ui/modifiers';
import { SharedModule } from '@ui/shared';

import { RecommendationSelectComponent } from './components/recommendation-select.component';

@NgModule({
  declarations: [RecommendationSelectComponent],
  imports: [
    CommonModule,
    ModifiersModule,
    PipesModule,
    SharedModule,
  ],
  exports: [RecommendationSelectComponent],
})
export class RecommendationSelectModule { }
