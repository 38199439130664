import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';

import { ContentValidationService } from '@core/acrelec-content';

@Pipe({
  name: 'buttonContent'
})
export class ButtonContentPipe implements PipeTransform {

  constructor(private _contentValidationService: ContentValidationService) {}

  /**
   * Returns a list of buttons filtered by their link values and visibility values
   */
  transform(buttons: DotButton[]): DotButton[] {
    return buttons;
    // return buttons.filter(button => this._contentValidationService.isButtonValid(button));
  }

}
