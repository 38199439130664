export enum ScreenType {
  SERVICE = 'Service',
  ORDER_AREA = 'OrderArea',
  ORDER_COMBO = 'OrderCombo',
  ORDER_REVIEW = 'OrderReview',
  PAYMENT = 'Payment',
  PAYMENT_REMOTE = 'PaymentRemote',
  PAYMENT_COUNTER = 'PaymentCounter',
  SCREENSAVER = 'ScreenSaver',
  SERVICE_AT_TABLE = 'ServiceAtTable',
  SERVICE_AT_TABLE_FLAG_NUMBER = 'ServiceAtTableFlagNumber',
  SERVICE_AT_TABLE_COUNTER= 'ServiceAtTableCounter',
}
