import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { InlineSVGModule } from 'ng-inline-svg';

import { RecommendationSelectModule } from '@app/ui/recommendation-select';
import { AccessibilityModule } from '@core/accessibility';
import { AcrelecContentModule } from '@core/acrelec-content';
import {
  AcrelecExternalModule,
  AppSettingsService,
  ContentService,
} from '@core/acrelec-external';
import { AllergensModule } from '@core/allergens';
import { AnimationsModule } from '@core/animations';
import { BasketModule } from '@core/basket';
import { ModalModule } from '@core/modal';
import { PaymentModule } from '@core/payment';
import { PromotionsModule } from '@core/promotions';
import { ScannerModule } from '@core/scanner';
import { SessionModule } from '@core/session';
import { OrderModule } from '@pages/order';
import { PaymentModule as PaymentPageModule } from '@pages/payment';
import { SatModule } from '@pages/sat';
import { ScreenSaverModule } from '@pages/screen-saver';
import { ServiceModule } from '@pages/service';
import { AccessibilityModule as AccessibilityUiModule } from '@ui/accessibility';
import { AllergensModalModule } from '@ui/allergens-modal';
import { BasketModule as BasketUiModule } from '@ui/basket';
import { CaloriesModule } from '@ui/calories';
import { ModifiersModule } from '@ui/modifiers';
import { PopUpModule } from '@ui/pop-up';
import { ProductModalModule } from '@ui/product-modal';
import { SatKeyboardModule } from '@ui/sat-keyboard';
import { SharedModule } from '@ui/shared';
import { SliderModule } from '@ui/slider';
import { VoucherChoiceModule } from '@ui/voucher-choice-modal';
import { VoucherManualModule } from '@ui/voucher-manual-modal';
import { VoucherScannerModule } from '@ui/voucher-scanner-modal';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AccessibilityModule,
    AccessibilityUiModule,
    AcrelecContentModule,
    AcrelecExternalModule,
    AllergensModule,
    AllergensModalModule,
    AnimationsModule,
    AppRoutingModule,
    BasketModule,
    BasketUiModule,
    BrowserModule,
    BrowserAnimationsModule,
    CaloriesModule,
    InlineSVGModule.forRoot(),
    ModalModule,
    ModifiersModule,
    OrderModule,
    PaymentModule,
    PaymentPageModule,
    PopUpModule,
    ProductModalModule,
    RecommendationSelectModule,
    PromotionsModule,
    ScannerModule,
    SatModule,
    SatKeyboardModule,
    ScreenSaverModule,
    SharedModule,
    ServiceModule,
    SessionModule,
    SliderModule,
    VoucherChoiceModule,
    VoucherManualModule,
    VoucherScannerModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        appSettingsService: AppSettingsService,
        contentService: ContentService
      ) => (): Promise<any> =>
        appSettingsService.initialize().then(
          () => contentService.initialize(),
          () => {}
        ),
      deps: [AppSettingsService, ContentService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
