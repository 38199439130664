import {
  animateChild,
  query,
  trigger,
  transition,
  useAnimation,
} from '@angular/animations';
import { Component} from '@angular/core';

import { ModalService, ModalType } from '@app/core/modal';
import { AccessibilityService } from '@core/accessibility';
import { Animations } from '@core/animations';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-voucher-choice',
  templateUrl: './voucher-choice.component.pug',
  styleUrls: ['./voucher-choice.component.scss'],
  animations: [
    trigger('displayModal', [
      transition(':enter', [
        useAnimation(Animations.inflateModal, { params: { time: '300ms' } }),
        query('@displayChild', animateChild({ delay: '0' })),
      ]),
    ]),
    trigger('displayChild', [
      transition(':enter', [
        useAnimation(Animations.showModalChild, {
          params: { time: '300ms' },
        }),
      ]),
    ]),
  ],
})
export class VoucherChoiceComponent {
  constructor(
    private _accessibility: AccessibilityService,
    private _modalService: ModalService,
    private _t: TranslationService
  ) {}

  /**
   * Close voucher choice modal
   */
  private cancel(): void {
    this._modalService.closeModal();
  }

  private displayVoucherManual(): void {
    this._modalService.setModal({ type: ModalType.VOUCHER_MANUAL });
  }

  private displayVoucherScanner(): void {
    this._modalService.setModal({ type: ModalType.VOUCHER_SCANNER });
  }
}
