import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AllergensModule } from '@core/allergens';
import { AnimationsModule } from '@core/animations';
import { ModalModule } from '@core/modal';
import { TranslationModule } from '@core/translation';
import { PipesModule } from '@pipes';
import { SharedModule } from '@ui/shared';

import { AllergensItemComponent } from './components/allergens-item/allergens-item.component';
import { AllergensModalComponent } from './components/allergens-modal.component';

@NgModule({
  declarations: [AllergensItemComponent, AllergensModalComponent],
  imports: [
    AllergensModule,
    AnimationsModule,
    CommonModule,
    InlineSVGModule,
    ModalModule,
    AccessibilityModule,
    SharedModule,
    TranslationModule,
    PipesModule,
  ],
  exports: [AllergensModalComponent],
})
export class AllergensModalModule {}
