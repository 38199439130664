import { Injectable } from '@angular/core';

import { ModalService } from '@core/modal';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  public _isKeyboardVisible = false;
  public _barCode = '';
  public _keys: any[] = [
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '_'],
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M'],
  ];
  constructor(private _modalService: ModalService) {}

  /**
   * Get current barcode
   */
  get barCode() {
    return this._barCode;
  }

  /** Get keyboard keys */
  get keyboard() {
    return this._keys;
  }

  /**
   * Get keyboard visibility
  */
  get keyboardVisibility(): boolean {
    return this._isKeyboardVisible;
  }

  /**
   * Add pressed key to barcode
   * @param keyPress key that has been pressed
   */
  addKeyPress(keyPress: string): void {
    if (this._barCode.length + keyPress.length <= 20) {
      this._barCode = this._barCode + keyPress;
    }
  }

  /**
   * Clode keyboard
   */
  close(): void {
    this.resetBarCode();
    this._modalService.closeModal();
  }

  /**
   * Remove last character from barcode
   */
  deleteLastKeyPress(): void {
    if (this._barCode.length > 0) {
      this._barCode = this._barCode.slice(0, -1);
    }
  }

  /**
   * Remove barcode
   */
  resetBarCode(): void {
    this._barCode = '';
  }

  /**
   * Toggle keyboard visibility
   */
  toggleKeyboard(): void {
    this.resetBarCode();
    this._isKeyboardVisible = !this._isKeyboardVisible;
  }
}
