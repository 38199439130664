import { Component, OnInit } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.pug',
  styleUrls: ['./payment-processing.component.scss'],
})
export class PaymentProcessingComponent implements OnInit {
  constructor(public _t: TranslationService) {}

  ngOnInit(): void {}
}
