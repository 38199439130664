import { Component, OnInit } from '@angular/core';

import { PaymentService } from '@app/core/payment';
import { AccessibilityService } from '@core/accessibility';
import { AppSettingsService } from '@core/acrelec-external';
import { TranslationService } from '@core/translation';

import { PaymentType } from '../types/PaymentType';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.pug',
  styleUrls: ['./payment-type.component.scss'],
})
export class PaymentTypeComponent implements OnInit {


  constructor(
    private _appSettingsService: AppSettingsService,
    private _paymentService: PaymentService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService
  ) {}

  ngOnInit(): void {

      // go directly to remote payment if counter is disabled
      if (!this._appSettingsService.enableCounterPayment) {
        this.choosePaymentType(PaymentType.REMOTE);
      }
  }

  /**
   * Check if PMR mode is enabled
   */
  private get accessibilityStatus(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Choose the type of payment for the current order
   */
  private choosePaymentType(type: PaymentType): void {
    this._paymentService.processPayment(type).catch(() => undefined);
  }
}
