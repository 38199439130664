import { Injectable } from '@angular/core';
import { DotButton, DotPage, DotSale, DotSaleConditionLoader } from 'dotsdk';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { ComboService, DotButtonService } from '@core/acrelec-content';
import { ContentService } from '@core/acrelec-external';
import { LocalBasketService } from '@core/basket';

/**
 * The suggestive selling service has private basket to hold promotion products before adding them
 * to the order basket if the customer wishes so.
 */
@Injectable({
  providedIn: 'root',
})
export class SuggestiveSalesService {

  constructor(
    private _comboService: ComboService,
    private _contentService: ContentService,
    private _db: DotButtonService,
    private _localBasket: LocalBasketService,
  ) { }

  /**
   * @returns True if suggestive basket is empty
   */
  get isBasketEmpty(): boolean {
    return this._localBasket.isBasketEmpty;
  }

  /**
   * @returns An Observable to watch the products added or removed from the suggestive selling basket
   */
  get productBasket$(): Observable<DotButton[]> {
    return this._localBasket.productBasket$;
  }

  /**
   * Add a DotButton product to the local suggestive sales basket
   * @param product Product to add to the basketProduct
   */
  addProduct(product: DotButton): void {
    this._localBasket.addProduct(product);
  }

  /**
   * Add the products currently in the suggestive selling basket to the global order basket
   * by cloning them to avoid interference with the global product DotButton
   */
  addProductsToBasket(): void {
    this._localBasket.addToGlobalBasket();
  }

  /**
   * Empties suggestive selling basket
   */
  clearBasket(): void {
    this._localBasket.clearBasket();
  }

  /**
   * True if the product has suggestions to display
   * @param product Product to check for suggestions
   */
  hasSuggestions(product: DotButton): boolean {
    if (this.getSuggestivePages(product)) return true;
    return false;
  }

  /**
   * Get all suggestions, clone them to avoid side-effects * and add them to
   * the local suggestion basket with a quantity of 0
   * @param product Product for which to initialize the suggestions
   */
  initializeSuggestiveSelling(product: DotButton): boolean {
    const suggestivePages = _.cloneDeep(this.getSuggestivePages(product));
    if (!suggestivePages || !suggestivePages.length) return false;
    this._localBasket.products = [...suggestivePages[0].Buttons.map(button => {
      (button as any).IsRecommendation = true;
      return button;
    })];
    return true;
  }

  /**
   * Remove a DotButton product from the local suggestive sales basket
   * @param product Product to remove from the basketProduct
   */
  removeProduct(product: DotButton): void {
    this._localBasket.removeProduct(product);
  }

  /**
   * @returns Suggestive pages from the pages.json file
   */
  private get suggestionPages(): DotPage[] {
    return this._contentService.hiddenPages.filter(
      (x) => x.PageType === 'Suggestive'
    );
  }

  /**
   * @param buttonLink DotButton.Link that you want suggestions for.
   * @returns the suggestion pages that correspond to the specified DotButton link or null if no suggestions were found.
   */
  private getButtonSuggestionByLink(buttonLink: string): DotPage[] {
    const suggestionConditions = DotSaleConditionLoader.getInstance()
      .loadedModel;
    const condition = suggestionConditions.Items.find(
      (x) => x.ID === buttonLink
    );
    if (!condition || !condition.SaleList) {
      return null;
    }
    // find suggestion page that corresponds to the first sale group ID found.
    return this.getSuggestionsBySaleList(condition.SaleList);
  }

  /**
   * @param comboLink Combo link that you want to get suggestions for.
   * @returns the suggestion pages that correspond to the specified DotCombo link or null if no suggestions were found.
   */
  private getComboSuggestionsByLink(comboLink: string): null | DotPage[] {
    const suggestionConditions = DotSaleConditionLoader.getInstance()
      .loadedModel;
    const condition = suggestionConditions.Menus.find(
      (menuSuggestion) => menuSuggestion.ID === comboLink
    );
    if (!condition || !condition.SaleList) {
      return null;
    }
    // find suggestion page that corresponds to the first sale group ID found.
    return this.getSuggestionsBySaleList(condition.SaleList);
  }

  /**
   * Filters the sale list array based on availability and returns the first suggestion page found for the filtered sale list.
   * @param saleList Sale list array
   * @returns the first suggestion page found for the filtered sale list or null if no suggestion page was found.
   */
  private getSuggestionsBySaleList(
    saleList: DotSale[],
  ): null | DotPage[] {
    if (!saleList || saleList.length === 0) {
      return null;
    }

    // for each saleList available return the first suggestion page found or return null
    const suggestions: DotPage[] = [];
    for (const sale of saleList) {
      const find = this.suggestionPages.find((suggestionPage) => {
        return suggestionPage.ID === sale.SaleGrpID;
      });
      // if (find && this.availabilityService.checkPageAvailability(find)) {
      if (find) {
        suggestions.push(find);
      }
    }
    // no suggestion found in the saleList array, therefore return null
    return suggestions && suggestions.length > 0 ? suggestions : null;
  }

  /**
   * Return all suggestive pages for a product
   * @param product Product to get suggestive pages from
   * @returns An array of suggestive [[DotPages]]
   */
  private getSuggestivePages(product: DotButton): DotPage[] | null {
    const link = product.Link;
    let suggestivePages: DotPage[] | null = null;
    if (this._db.isCombo(product)) {
      suggestivePages = this.getComboSuggestionsByLink(link);
    }
    if (!suggestivePages) {
      suggestivePages = this.getButtonSuggestionByLink(link);
    }
    return suggestivePages;
  }
}
