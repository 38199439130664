import { Injectable } from '@angular/core';
import { DotCombo } from 'dotsdk';

import { ComboDirection } from '../../types/ComboDirection';
import { ComboFooterIcon } from '../../types/ComboFooterIcon';
import { ComboFooterIcons } from '../../types/ComboFooterIcons';
import { ComboStep } from '../../types/ComboStep';

interface FooterSteps {
  maxSteps: number | null;
  firstStep: number | null;
  secondStep: number | null;
  thirdStep: number | null;
}

// TODO: Move to the combo-footer component module
@Injectable({
  providedIn: 'root',
})
export class ComboFooterService {
  constructor() { }

  getFooterIcons(
    direction: ComboDirection,
    comboSteps: DotCombo[],
    currentStep: number,
    firstStep: number,
    lastStep?: number
  ): ComboFooterIcons | null {
    let footerSteps: FooterSteps | null = null;

    switch (direction) {
      case ComboDirection.FIRST_STEP:
        footerSteps = this.getFooterStepsFirstStep(
          currentStep,
          firstStep,
          lastStep
        );
        break;
      case ComboDirection.FORWARD:
        footerSteps = this.getFooterStepsForward(
          currentStep,
          firstStep,
          lastStep
        );
        break;
      case ComboDirection.BACKWARD:
        footerSteps = this.getFooterStepsBackward(currentStep, firstStep);
        break;
    }
    if (!footerSteps) return null;
    return this.getComboFooterIcons(footerSteps, comboSteps);
  }

  /**
   * Sets the footer icons by sending an event to the [[BehaviorSubject]]
   * @param button The button to get the icon for.
   */
  getFooterStepsFirstStep(
    comboStep: number,
    comboFirstStep: number,
    comboLastStep: number
  ): FooterSteps {
    // if we have X steps we need to set the last icon to either step + 2 or X depending on the smallest
    const lastFooterStep = Math.min(comboStep + 2, comboLastStep);
    const maxSteps: number = lastFooterStep - comboFirstStep + 1;
    // ......................... //
    let firstStep: number | null = null;
    let secondStep: number | null = null;
    let thirdStep: number | null = null;

    // Get step number
    if (maxSteps <= 1) {
      firstStep = lastFooterStep;
    } else if (maxSteps <= 2) {
      firstStep = lastFooterStep - 1;
      secondStep = lastFooterStep;
    } else {
      firstStep = lastFooterStep - 2;
      secondStep = lastFooterStep - 1;
      thirdStep = lastFooterStep;
    }

    return {
      maxSteps,
      firstStep,
      secondStep,
      thirdStep,
    };
  }

  /**
   * Sets the footer icons by sending an event to the [[BehaviorSubject]]
   * @param button The button to get the icon for.
   */
  getFooterStepsForward(
    step: number,
    comboFirstStep: number,
    comboLastStep: number
  ): FooterSteps | null {
    const footerStep: number = step - comboFirstStep + 1;
    const isNewIconSet: boolean = footerStep % 3 === 0;
    if (!isNewIconSet) return null;
    // if we have X steps we need to set the last icon to either step + 2 or X depending on the smallest
    const lastFooterStep = Math.min(step + 2, comboLastStep);
    const maxSteps: number = lastFooterStep - comboFirstStep + 1;

    let firstStep: number | null = null;
    let secondStep: number | null = null;
    let thirdStep: number | null = null;

    if (maxSteps <= 1) {
      firstStep = lastFooterStep;
    } else if (maxSteps <= 2) {
      firstStep = lastFooterStep - 1;
      secondStep = lastFooterStep;
    } else {
      firstStep = lastFooterStep - 2;
      secondStep = lastFooterStep - 1;
      thirdStep = lastFooterStep;
    }

    return {
      maxSteps,
      firstStep,
      secondStep,
      thirdStep,
    };
  }

  /**
   * Sets the footer icons by sending an event to the [[BehaviorSubject]]
   * @param button The button to get the icon for.
   */
  private getFooterStepsBackward(
    comboStep: number,
    comboFirstStep: number
  ): FooterSteps | null {
    // We check if we need to navigate backward in the footer
    const footerStep: number = comboStep - comboFirstStep + 1;
    const isNewIconSet: boolean = footerStep % 2 === 0;
    if (!isNewIconSet) return null;

    const firstFooterStep = Math.max(comboStep - 1, comboFirstStep);
    const maxSteps = 3;

    const firstStep: number = firstFooterStep;
    const secondStep: number = firstFooterStep + 1;
    const thirdStep: number = firstFooterStep + 2;

    return {
      maxSteps,
      firstStep,
      secondStep,
      thirdStep,
    };
  }

  private getComboFooterIcons(
    footerSteps: FooterSteps,
    comboSteps: DotCombo[]
  ): ComboFooterIcons {
    return {
      maxSteps: footerSteps.maxSteps,
      first: this.getComboFooterIcon(footerSteps.firstStep, comboSteps),
      second: this.getComboFooterIcon(footerSteps.secondStep, comboSteps),
      third: this.getComboFooterIcon(footerSteps.thirdStep, comboSteps),
    };
  }

  /**
   * return icons for specific steps
   * @param step current step in combo
   * @param comboSteps different combo steps
   */
  private getComboFooterIcon(
    step: number,
    comboSteps: DotCombo[]
  ): ComboFooterIcon | null {
    // const comboStep = comboSteps.find((comboPage) => comboPage.Level === step);
    const comboStep = comboSteps[step];
    if (!comboStep) return null;
    const icon: ComboFooterIcon = {
      step,
      title: null,
      path: null,
    };
    const stepTitle = comboStep.ComboStepName.toLowerCase();
    icon.title = stepTitle ? stepTitle : `step ${step}`;
    switch (icon.title) {
      case ComboStep.SANDWICH:
        icon.path = 'assets/images/icons/combo-sandwich.svg';
        break;
      case ComboStep.SIDE:
        icon.path = 'assets/images/icons/combo-sides.svg';
        break;
      case ComboStep.DRINK:
        icon.path = 'assets/images/icons/combo-drinks.svg';
        break;
      case ComboStep.FRIES:
        icon.path = 'assets/images/icons/combo-fries.svg';
        break;
    }
    return icon;
  }
}
