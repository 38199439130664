import {
  trigger,
  animate,
  style,
  state,
  transition,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AppSettingsService } from '@app/core/acrelec-external';
import { ModalService, ModalType } from '@app/core/modal';
import { PaymentType } from '@app/pages/payment/components/types/PaymentType';
import { AccessibilityService } from '@core/accessibility';
import { ComboService, DotButtonService } from '@core/acrelec-content';
import { BasketService } from '@core/basket';
import { NavigationService, ScreenType } from '@core/navigation';
import { PaymentService } from '@core/payment';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.pug',
  styleUrls: ['./order-review.component.scss'],
  animations: [
    trigger('deleteProduct', [
      state('start', style({ opacity: 1 })),
      transition(':leave', [
        animate(400, style({ opacity: 0.5, transform: 'translateX(-970rem)' })),
      ]),
    ]),
  ],
})
export class OrderReviewComponent implements OnInit {
  constructor(
    private _appSettingsService: AppSettingsService,
    private _basketService: BasketService,
    private _navigationService: NavigationService,
    private _paymentService: PaymentService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService,
  ) { }

  ngOnInit() { }

  /**
   * Get the contents of the basket
   */
  private get basketContent(): DotButton[] {
    return this._basketService.products;
  }

  /**
   * Get the total price of the basket
   */
  private get totalPrice(): number {
    return this._basketService.totalDisplayPrice;
  }

  /**
   * Go back if basket isEmpty
   * @param isEmpty Basket is empty or not
   */
  private isBasketEmpty(isEmpty: boolean | null): void {
    if (isEmpty) this.goBack();
  }

  /**
   * Pops the navigation stack to go back one page
   */
  private goBack() {
    this._navigationService.popStack();
  }

  /**
   * Begins the payment process
   */
  private goToPayment() {
    const page = {
      type: ScreenType.PAYMENT,
      content: null,
    };
    if (this._appSettingsService.serviceAtTable) {
      page.type = ScreenType.SERVICE_AT_TABLE;
    }
    this._navigationService.addStack(page);
  }
}
