import { Pipe, PipeTransform } from '@angular/core';
import { DotBanner } from 'dotsdk';

import { ContentValidationService } from '@core/acrelec-content';


@Pipe({
  name: 'bannersContent'
})
export class BannersContentPipe implements PipeTransform {

  constructor(private _contentValidationService: ContentValidationService){}

  /**
   * Returns a list of buttons filtered by the validity of their file exention and picture
   */
  transform(banners: DotBanner[]): DotBanner[] {
    return banners.filter(banner => this._contentValidationService.isBannerValid(banner));
  }

}
