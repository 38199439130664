import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton, DotModifier } from 'dotsdk';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { AccessibilityService } from '@core/accessibility';
import {
  ComboFooterIcons,
  ComboHeaderPictures,
  ComboService,
  DotButtonService,
  ModifiersService,
  NavigationComboService,
  NavigationModifierService,
  PriceCalculationService,
} from '@core/acrelec-content';
import { NavigationService } from '@core/navigation';
import { SuggestiveSalesService } from '@core/suggestive-selling';
import { TranslationService } from '@core/translation';

import { ComboHeaderTitle } from '../../types/ComboHeaderTitle';

/**
 * The combo component clones the DotButton passed as Input, and initializes it if needed.
 * It then creates a custom navigation that's used to navigate between the visible combo steps and
 * tells the behaviour of the "Continue" and "Back" buttons.
 * If clicking on a combo product that has modifiers, the component creates a local modifier navigation
 * that overrides the behaviour of the "Continue" and "Back" buttons until there are no more modifiers pages.
 */
@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.pug',
  styleUrls: ['./combo.component.scss'],
})
export class ComboComponent implements OnInit {
  @Input() combo: DotButton;
  @Output() closeModal = new EventEmitter<void>();
  @Output() addCombo = new EventEmitter<DotButton>();

  private _comboBasePrice: number;
  private _comboMealPrice: number;
  private _language: string;
  private _lockUserInteraction = false;
  private _navCombo: NavigationComboService;
  private _navModifiers: NavigationModifierService | null = null;

  constructor(
    private _accessibility: AccessibilityService,
    private _comboService: ComboService,
    private _db: DotButtonService,
    private _modifiersService: ModifiersService,
    private _navigationService: NavigationService,
    private _priceService: PriceCalculationService,
    private _sessionService: AccessibilityService,
    private _suggestionService: SuggestiveSalesService,
    private _t: TranslationService
  ) { }

  /**
   * On init the combo component clones the DotButton passed as Input, and initializes it if needed.
   * It then creates a custom navigation that's used to navigate between the visible combo steps and
   * tells the behaviour of the "Continue" and "Back" buttons.
   * If the combo DotButton's first step is a single product without choices, we simulate a click on it
   * to reduce user's input.
   */
  ngOnInit(): void {
    this._language = this._t.language;
    this._navCombo = new NavigationComboService(
      this.combo,
      this._comboService,
      this._db
    );
    this._comboMealPrice = this.combo.MinPrice;
    this._comboBasePrice = this._comboService.getAriadnaPrice(this.combo);
    if (this._navCombo.isSingleProductStep) {
      const singleProduct = this._navCombo.singleProduct;
      this.onProductSelection(singleProduct);
    }
  }

  /**
   * Check if accessibility mode is enabled
   */
  private get accessibilityStatus(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * @returns An observable that emits the current step's products
   */
  private get comboList(): Observable<DotButton[]> {
    return this._navCombo.buttonsObservable;
  }

  /**
   * Creates the combo header title from the first product's name
   * If the combo is a multistep one, add a subtitle base on the combo button's name itself.
   * I.e: title = first product "Cheese Burger" | subtitle = combo button "Large Meal"
   */
  private get comboHeaderTitle(): ComboHeaderTitle {
    const headerTitle: ComboHeaderTitle = { title: null, subtitle: null };
    const firstProduct = this._comboService.getSelectedProduct(
      this._navCombo.firstComboStep
    );
    if (!firstProduct) return headerTitle;
    headerTitle.title = this._db.getCaption(firstProduct);
    if (this._navCombo.isSingleStepCombo) return headerTitle;
    headerTitle.subtitle = this._db.getCaption(this.combo);
    return headerTitle;
  }

  /**
   * @returns The combo step title, such as "Choose your sandwich", etc.
   */
  private get comboTitle(): string {
    const currentComboStep = this._navCombo.currentComboStep;
    let comboTitle;
    const comboTitleDictionary = currentComboStep.TitleDictionary;
    if (comboTitleDictionary) {
      comboTitle = comboTitleDictionary[this._t.language];
    }
    if (!(comboTitle && comboTitle.length > 0)) {
      comboTitle = currentComboStep && currentComboStep.Title;
    }
    if (!(comboTitle && comboTitle.length > 0)) {
      comboTitle = this._t.translate(48, 'customize');
    }
    return comboTitle;
  }

  /**
   * @returns True if we are displaying the final combo step on screen ("Add to bag" button)
   */
  private get displayLastStepBtn(): boolean {
    const isFinalModifierStep =
      !this._navModifiers || this._navModifiers.isLastStep;
    const isFinalComboStep = this._navCombo.isLastStep && isFinalModifierStep;
    const isSingleProductCombo =
      this._navCombo.isSingleProductStep &&
      this._navCombo.isLastStep &&
      this._navModifiers &&
      this._navModifiers.isLastStep;
    if (isFinalComboStep || isSingleProductCombo) {
      return true;
    }
    return false;
  }

  /**
   * @returns True if we are on a multistep combo and need to display the stepper
   */
  private get displayStepper(): boolean {
    return !this._navCombo.isSingleStepCombo;
  }

  /**
   * @returns An observable that emits the footer's stepper icons
   */
  private get footerIcons(): Observable<ComboFooterIcons> {
    return this._navCombo.footerIcons;
  }

  /**
   * @returns The index of the current stepper's step
   */
  private get footerStep(): number {
    return this._navCombo.footerStep;
  }

  /**
   * @returns The index of the current header's step to display the correct image
   */
  private get headerStep(): number {
    return this._navCombo.headerStep;
  }

  /**
   * @returns An observable that emits the header's images
   */
  private get headerPictures(): Observable<ComboHeaderPictures> {
    return this._navCombo.headerPictures;
  }

  /**
   * @returns An observable that emits the current modifier step's products
   */
  private get modifiersList(): Observable<DotButton[]> {
    if (!this._navModifiers) return;
    return this._navModifiers.buttonsObservable;
  }

    /**
   * @returns An observable that emits the current modifier step's products
   */
  private get modifier(): Observable<DotModifier | null> {
    if (!this._navModifiers) return;
    return this._navModifiers.currentModifierObservable;
  }

  /**
   * @returns True if we need to display the back button
   */
  private get isBackButtonVisible(): boolean {
    // return !this._navCombo.isFirstStep && !this._navModifiers;
    return !this._navCombo.isFirstStep;
  }

  /**
   * @returns True if current step is Combo step
   */
  private get isCombo(): boolean {
    return !this._navModifiers;
  }

  private get isContinueDisabled(): boolean {
    if (this._navModifiers) {
      return this._navModifiers.isLessThanMinModifiersInGroup;
    }
    // TODO: check is mandatory modifiers are selected to allow continue?
    return !this._navCombo.isComboItemSelected;
  }

  /**
   * Check if this is the maximum modifiers
   */
  private get isMaxModifiers(): boolean {
    if (this._navModifiers) return this._navModifiers.isMaxModifiersInGroup;
    return false;
  }

  /**
   * @returns True if current step is the 'sides' step
   */
  get isSideStep(): boolean {
    return this._navCombo.isSideStep;
  }

  /**
   * Starts a navigation through the button's modifiers steps.
   * @param button The selected button for which to display the modifier's navigation
   */
  private createModifierNavigation(button: DotButton) {
    this._navModifiers = new NavigationModifierService(
      button,
      this._modifiersService
    );
  }

  /**
   * Get the total price from the combo and save it inside the clade and emits the combo product to the parent component
   */
  private finishCombo() {
    const price = this._priceService.getPrice(this.combo);
    this.combo.ComputedPrice = price;
    this.addCombo.emit(this.combo);
  }

  private onAddModifier(button: DotButton) {
    this._navModifiers.increaseModifier(button);
  }

  private lockInteraction(isLocked: boolean): void {
    this._lockUserInteraction = isLocked;
  }

  /**
   * Calls the 'onBack' depending on wether we are inside a modifier navigation or not
   */
  private onBack() {
    if (this._navModifiers) {
      this.onBackModifiers();
      return;
    }
    this.onBackCombo();
  }

  /**
   * Goes to the previous step, unless we are at the first step,
   * then we pop the navigation stack to return to the previous page.
   */
  private onBackCombo() {
    if (!this._navCombo.previousStep()) {
      this._navigationService.popStack();
    }
  }

  /**
   * Goes to the previous modifier step, unless we are at the first step,
   * then we go back to the current combo step and deselect the combo product
   */
  private onBackModifiers() {
    if (!this._navModifiers.previousStep()) {
      this._navModifiers.resetModifiers();
      if (!this._navCombo.isFirstStep) {
        this._navCombo.deselectCurrentStepItems();
      }
      this._navModifiers = null;
    }
    return;
  }

  /**
   * Close the modal
   */
  private onCancel() {
    this.closeModal.emit();
  }

  /**
   * Calls the 'onContinue' depending on wether we are inside a modifier navigation or not
   */
  private onContinue() {
    if (this._navModifiers) {
      this.onContinueModifiers();
      return;
    }
    this.onContinueCombo();
  }

  private onContinueCombo() {
    // If false, then combo nav is finished
    if (!this._navCombo.nextStep()) {
      this.finishCombo();
    }
  }
  /**
   * Goes to the next modifier step, unless we are at the last step,
   * then we go to the next combo step  or finish the combo
   */
  private onContinueModifiers() {
    // If false, then modifier nav is finished
    if (!this._navModifiers.nextStep()) {
      this._navModifiers = null;
      this.onContinueCombo();
    }
  }

  /**
   * On a product's click, set the header picture, and create a navigation for the modifiers if any exist
   * @param button The product that has been clicked
   */
  private onProductSelection(button: DotButton) {
    if (this._lockUserInteraction) return;
    this._comboService.selectStepProduct(
      button,
      this._navCombo.currentComboStep,
      this._navCombo.size
    );
    this._navCombo.setHeaderPictures();
    if (this._db.hasModifiers(button)) {
      this.createModifierNavigation(button);
    }
  }

  /**
   * Decrease a modifier's quantity
   * @param button The modifier to decrease
   */
  private onRemoveModifier(button: DotButton) {
    this._navModifiers.decreaseModifier(button);
  }
}
