import { animate, style, trigger, transition } from '@angular/animations';

/**
 * Slider animation of screen saver and accessibility banner
 */
export const sliderAnimation = trigger('slider', [
  transition(':enter', [
    style({ opacity: 0, position: 'absolute' }),
    animate('100ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('100ms', style({ opacity: 0 })),
  ]),
]);
