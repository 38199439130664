import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-combo-button',
  templateUrl: './combo-button.component.pug',
  styleUrls: ['./combo-button.component.scss'],
})
export class ComboButtonComponent implements OnInit {
  @Input() hasModifiers: boolean;
  @Input() name: string;
  @Input() picture: string;
  @Input() price: string | null;
  @Input() quantity: number;
  @Input() selected: boolean;
  @Output() onSelection = new EventEmitter();

  constructor(
    private _sessionService: AccessibilityService,
    private _t: TranslationService
  ) {}

  ngOnInit() {}

  /**
   * Adds classes depending on the type of button
   */
  private get buttonClasses(): {
    'Button--plus': boolean;
    'Button--dimmed': boolean;
    'Button--edit': boolean;
  } {
    if (!this.selected) {
      return {
        'Button--plus': true,
        'Button--dimmed': false,
        'Button--edit': false,
      };
    }
    if (this.hasModifiers) {
      return {
        'Button--plus': false,
        'Button--dimmed': false,
        'Button--edit': true,
      };
    }
    return {
      'Button--plus': true,
      'Button--dimmed': true,
      'Button--edit': false,
    };
  }

  /**
   * Get the SVG of the button
   * depending on different factors
   */
  private get buttonSvg() {
    if (this.selected && this.hasModifiers) {
      return '/assets/images/icons/button-edit.svg';
    }
    return '/assets/images/icons/button-plus.svg';
  }

  /**
   * Check if PMR mode is enabled
   */
  private get isAccessibilityActivated(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Check if the button is disabled
   */
  private get isDisabled(): boolean {
    return this.selected && !this.hasModifiers;
  }

  private onSelectionClick() {
    this.onSelection.emit();
  }
}
