import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrderAreaComponent } from './components/order-area/order-area.component';
import { OrderComponent } from './components/order.component';
import { OrderReviewComponent } from './components/review/order-review.component';
import { OrderGuard } from './guard/order.guard';

const orderRoutes: Routes = [
  {
    path: 'order',
    component: OrderComponent,
    canActivate: [OrderGuard],
    children: [
      {
        path: '',
        component: OrderAreaComponent,
        data: { animation: 'OrderArea' },
      },
      {
        path: 'review',
        component: OrderReviewComponent,
        data: { animation: 'OrderReview' },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(orderRoutes)],
  exports: [RouterModule],
})
export class OrderRoutingModule {}
