import { Injectable } from '@angular/core';
import { DotButton } from 'dotsdk';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { PriceCalculationService } from '@core/acrelec-content';

import { BasketService } from './basket.service';

@Injectable({
    providedIn: 'root',
})
export class LocalBasketService {
    private _productBasket: DotButton[] = [];
    private _productBasketSubject: BehaviorSubject<
        DotButton[]
    > = new BehaviorSubject(this._productBasket);

    constructor(
        private _basketService: BasketService,
        private _priceService: PriceCalculationService,
    ) { }

    /**
     * @returns True if suggestive basket is empty
     */
    get isBasketEmpty(): boolean {
        return !this._productBasket.some((product) => product.quantity > 0);
    }

    /**
     * @returns An Observable to watch the products added or removed from the local basket
     */
    get productBasket$(): Observable<DotButton[]> {
        return this._productBasketSubject.asObservable();
    }

    set products(products: DotButton[]) {
        this._productBasket = products;
        this.updateBasketObservables();
    }

    /**
     * Add a DotButton product to the local suggestive sales basket
     * @param product Product to add to the basketProduct
     */
    addProduct(product: DotButton): void {
        const found = this._productBasket.find(
            (basketProduct) => basketProduct.Link === product.Link
        );
        if (found) {
            found.ComputedPrice = this._priceService.getPrice(found)
            found.quantity++;
        }
        this.updateBasketObservables();
    }

    /**
     * Add the products currently in the local basket to the global order basket
     * by cloning them to avoid interference with the global product DotButton
     */
    addToGlobalBasket(): void {
        const products = this._productBasket.filter(
            (product) => product.quantity > 0
        );
        if (!products) return;
        products.forEach((product) => {
            const productClone = _.cloneDeep(product); // cloning the product here to not affect global product quantity
            this._basketService.addProductToBasket(productClone);
            product.quantity = 0;
        });
        this.clearBasket();
    }

    /**
     * Empties local basket and updates observers
     */
    clearBasket() {
        this._productBasket = [];
        this.updateBasketObservables();
    }

    /**
     * Remove a DotButton product from the local suggestive sales basket
     * @param product Product to remove from the basketProduct
     */
    removeProduct(product: DotButton): void {
        const found = this._productBasket.find(
            (basketProduct) => basketProduct.Link === product.Link
        );
        if (found && found.quantity > 0) {
            found.quantity--;
        }
        this.updateBasketObservables();
    }

    /**
     * Updates subscribed observers
     */
    private updateBasketObservables() {
        this._productBasketSubject.next(this._productBasket);
    }
}