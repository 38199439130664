import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton, DotModifier } from 'dotsdk';

import { AccessibilityService } from '@core/accessibility';
import {
  AvailabilityService,
  DotButtonService,
  ModifiersService,
  PriceCalculationService,
} from '@core/acrelec-content';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.pug',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input() product: DotButton;
  @Output() addProduct = new EventEmitter<DotButton>();
  @Output() closeModal = new EventEmitter<void>();

  constructor(
    private _accessibility: AccessibilityService,
    private _db: DotButtonService,
    private _modifierService: ModifiersService,
    private _priceService: PriceCalculationService,
    private _t: TranslationService,
    private _availabilityService: AvailabilityService
  ) {}

  ngOnInit(): void {
    this.product.quantity = 1;
  }

  /**
   * Check if this is the maximum modifiers of the product
   */
  private get isMaxModifiers(): boolean {
    if (!this._db.hasModifiers(this.product)) return false;
    return this._modifierService.isAnyGroupMaxQuantity(
      this.product.ModifiersPage.Modifiers
    );
  }

  /**
   * Check if this is the minimum modifiers of the product selected
   */
  private get isLessThanGroupMinQuantity(): boolean {
    if (!this._db.hasModifiers(this.product)) return false;
    return this.product.ModifiersPage.Modifiers.some((modifier) =>
      this._modifierService.isLessThanGroupMinQuantity(modifier)
    );
  }

  /**
   * Get all modifiers of the product
   */
  private get modifiers(): DotModifier[] | null {
    return this.product.ModifiersPage ? this.product.ModifiersPage.Modifiers.filter(
      (modifier) => modifier.PageInfo.AutoPopFeat !== ('9' || '3')
    ) : [];
  }

  /**
   * Add one more quantity
   */
  private add(): void {
    this.product.quantity++;
  }

  /**
   * Increase quantity modifier
   * @param modifier
   */
  private addModifier(modifier: DotButton): void {
    this._modifierService.increaseModifier(modifier);
  }

  /**
   * Close the modal
   */
  private cancel(): void {
    this.closeModal.emit();
  }

  /**
   * Confirm the product and add it to the basket
   */
  private confirm() {
    this.product.ComputedPrice = this._priceService.getPrice(this.product);
    this.addProduct.emit(this.product);
  }

  /**
   * Remove one quantity
   */
  private remove(): void {
    if (this.product.quantity > 1) this.product.quantity--;
  }

  /**
   * Decrease quantity modifier
   * @param modifier
   */
  private removeModifier(modifier: DotButton): void {
    this._modifierService.decreaseModifier(modifier);
  }

  private isProductDisabled(button: DotButton): boolean {
    return this._availabilityService.isButtonDisabled(button);
  }
}
