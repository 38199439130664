import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { timeoutOrderEvent} from 'dotsdk';

import { PopUpContentComponent } from '../pop-up-content/pop-up-content.component';
@Component({
  selector: 'app-countdown-pop-up',
  templateUrl: './countdown-pop-up.component.pug',
  styleUrls: ['./countdown-pop-up.component.scss'],
})
export class CountdownPopUpComponent
  implements OnInit, OnDestroy, PopUpContentComponent {
  @Input()
  data: {
    message: string;
    countdownMaxValue: number;
    countdownCallback: () => void;
  };
  private _currentCountdown: number;
  private _countdownTimeout: NodeJS.Timeout;

  constructor() {}

  ngOnInit(): void {
    this._currentCountdown = this.data.countdownMaxValue / 1000;
    this._countdownTimeout = this.createTimeout();
  }

  ngOnDestroy(): void {
    clearInterval(this._countdownTimeout);
  }

  /**
   * Runs coundown for pop-up
   */
  private createTimeout(): NodeJS.Timeout {
    const timeout = setInterval(() => {
      this._currentCountdown -= 1;
      if (this._currentCountdown < 1) {
        clearInterval(timeout);
        this.data.countdownCallback();
        // TODO emit timeout event when the popup close warning time is reached
        timeoutOrderEvent.emit(null);
      }
    }, 1000);
    return timeout;
  }
}
