import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DayOfWeekService {

  constructor() { }

  /**
   * Check day of week availability
   * @param dayOfWeek product availability day
   * @param dateOfComparing current date
   */
  public isDowAvailable(dayOfWeek: number, dateOfComparing: Date): boolean {
    const dow = dayOfWeek || 127;
    if (this.isAllowedDay(dateOfComparing, dow)) {
      return true; // day is allowed
    }
    return false;
  }

  /**
   * Checks if product is available on the current day
   * @param aTimestamp current date
   * @param allowedDays product availability day
   */
  protected isAllowedDay(aTimestamp: Date, allowedDays: number): boolean {
    // eslint-disable-next-line no-bitwise
    return (allowedDays & this.dateMask(aTimestamp)) !== 0;
  }

  protected dateMask(aTimestamp: Date): number {
    const currentDay = aTimestamp.getDay() !== 0 ? aTimestamp.getDay() : 7;
    const dayPower2 = Math.pow(2, currentDay - 1);
    return dayPower2;
  }
}
