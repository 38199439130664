import { Component, OnInit } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-payment-counter',
  templateUrl: './payment-counter.component.pug',
  styleUrls: ['./payment-counter.component.scss'],
})
export class PaymentCounterComponent implements OnInit {
  constructor(
    private _t: TranslationService,
  ) {}

  ngOnInit(): void {}
}
