import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { BasketModule } from '@core/basket';
import { ModalModule } from '@core/modal';
import { TranslationModule } from '@core/translation';
import { PopUpModule } from '@ui/pop-up';

@NgModule({
  declarations: [],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    BasketModule,
    CommonModule,
    ModalModule,
    PopUpModule,
    TranslationModule,
  ],
})
export class PromotionsModule {}
