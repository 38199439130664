import { Component, Input } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';

@Component({
  selector: 'app-accessibility-button',
  templateUrl: './accessibility-button.component.pug',
  styleUrls: ['./accessibility-button.component.scss'],
})
export class AccessibilityButtonComponent {
  @Input() iconColor?: string;
  constructor(public _sessionService: AccessibilityService) {}

  /**
   * Toggle PMR mode
   */
  toggleAccessibility(): void {
    this._sessionService.toggleAccessibility();
  }

  /**
   * Return the color of icon, if not defined
   * in the variables it return a default color
   */
  get color(): object {
    const color = this.iconColor ? this.iconColor : 'var(--bk-flamming-orange)';
    return { fill: color };
  }
}
