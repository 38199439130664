import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { BasketModule } from '@core/basket';
import { NavigationModule } from '@core/navigation';
import { RecommendationModule } from '@core/recommendation';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { PopUpModule } from '@ui/pop-up';

@NgModule({
  declarations: [],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    BasketModule,
    CommonModule,
    HttpClientModule,
    NavigationModule,
    PopUpModule,
    RecommendationModule,
    SessionModule,
    TranslationModule,
  ],
})
export class PaymentModule { }
