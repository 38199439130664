import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-order-footer-buttons',
  templateUrl: './order-footer-buttons.component.pug',
  styleUrls: ['./order-footer-buttons.component.scss'],
})
export class OrderFooterButtonsComponent implements OnInit {
  @Input() isConfirmDisabled = false;
  @Output() cancelOrder = new EventEmitter<void>();
  @Output() confirmOrder = new EventEmitter<void>();

  constructor(private _t: TranslationService) {}

  ngOnInit(): void {}

  /**
   * Cancel the order
   */
  cancelClick(): void {
    this.cancelOrder.emit();
  }

  /**
   * Confirm the order
   */
  confirmClick(): void {
    if (!this.isConfirmDisabled) {
      this.confirmOrder.emit();
    }
  }
}
