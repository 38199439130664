import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AppSettingsService } from '@app/core/acrelec-external';
import { Animations } from '@core/animations';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.pug',
  styleUrls: ['./order.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('* => *', [useAnimation(Animations.pageTransition)]),
    ]),
  ],
})
export class OrderComponent implements OnInit {
  constructor(private _appSettings: AppSettingsService) {}

  ngOnInit(): void {}

  prepareRoute(outlet: RouterOutlet) {
    if (!this._appSettings.disabledOrangeTransition) {
      return (
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData.animation
      );
    } else {
      return false;
    }
  }
}
