import { Injectable } from '@angular/core';

import { AppSettingsService } from '@core/acrelec-external';

@Injectable({
  providedIn: 'root'
})
export class FilterTagsService {

  /**
   * check filter tags
   */
  constructor(private _appSettingsService: AppSettingsService) {
  }

  /**
   * check tags availability
   * @param filter string array with filter tags
   */
  public isTagsAvailable(filter: string): boolean {

      let filterTagsArray = filter.split(',');
      filterTagsArray = this.trimAndLowercase(filterTagsArray);
      const storeTags = this._appSettingsService.storeTags;
      const kioskTags = this._appSettingsService.kioskTags;

      let tagsArray =  (storeTags) ? storeTags : [];
      tagsArray = (kioskTags) ? [...kioskTags, ...tagsArray] : tagsArray;

      tagsArray = this.trimAndLowercase(tagsArray);

      return (tagsArray === []) ? true : tagsArray.some(tags => filterTagsArray.indexOf(tags) >= 0);
  }

  /**
   * trim and lower case for array items
   * @param tagsArray array whose items will be trim
   */
  protected trimAndLowercase(tagsArray: string[]): string[] {
      let resultArray = [];
      if (tagsArray.length > 0) {
          const resultLowercase = tagsArray.map(item => item.toLowerCase());
          resultArray = resultLowercase.map(item => item.trim());
      }
      return resultArray;
  }
}
