import { Injectable } from '@angular/core';
import { PosServingLocation } from 'dotsdk';
import { BehaviorSubject, Observable } from 'rxjs';

import { PaymentSteps } from '@app/core/payment/types/PaymentSteps';
import { NavigationService } from '@core/navigation';

import { AccessibilityService } from '../../accessibility/services/accessibility.service';

/**
 * The Session service resets all the different app services
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  suggestNavigationScroll = false;
  public _paymentPrice = 0;
  private _isAtpInitialized = false;
  private _isOrderInitialized = false;
  private _resetSession = new BehaviorSubject<boolean>(false);
  private _paymentStatus = new BehaviorSubject<PaymentSteps>(PaymentSteps.Idle);

  constructor() {}

  get isAtpInitialized(): boolean {
    return this._isAtpInitialized;
  }

  get isOrderInitialized(): boolean {
    return this._isAtpInitialized && this._isOrderInitialized;
  }

  get resetSession$(): Observable<boolean> {
    return this._resetSession.asObservable();
  }


  get paymentStatus$(): Observable<PaymentSteps> {
    return this._paymentStatus.asObservable();
  }

  public set paymentStatus(paymentStatus: PaymentSteps) {
    this._paymentStatus.next(paymentStatus);
  }

  /**
   * Set flag to true if ATP has been initialized
   */
  initializeAtpSession() {
    this._isAtpInitialized = true;
  }

  /**
   * Set flag to true if user's order has been initialized
   */
  initializeOrderSession(service: PosServingLocation) {
    this._isOrderInitialized = true;
  }

  /**
   * Reset all the different app services by sending an envent on the rxjs subject
   */
  resetSession() {
    this._isOrderInitialized = false;
    this._resetSession.next(true);
    this._paymentStatus.next(PaymentSteps.Idle);
    this._paymentPrice = 0;
  }
}
