import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AvailabilityService } from '@app/core/acrelec-content';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-modifier-tile',
  templateUrl: './modifier-tile.component.pug',
  styleUrls: ['./modifier-tile.component.scss'],
})
export class ModifierTileComponent {
  @Input() isMaxModifiers: boolean;
  @Input() maxQuantity: number;
  @Input() name: string;
  @Input() picture: string;
  @Input() price: number;
  @Input() quantity: number;
  @Input() selected: boolean;
  @Input() buttonDisabled: boolean;

  @Output() onAdd = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  constructor(private _t: TranslationService) {}

  /**
   * Add modifier to the ordered product
   */
  private add() {
    this.onAdd.emit();
  }

  /**
   * Remove modifier from the ordered product
   */
  private remove() {
    this.onRemove.emit();
  }
}
