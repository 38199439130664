import { Injectable } from '@angular/core';
import { DotButton, DotCombo, DotModifier } from 'dotsdk';
import * as _ from 'lodash';

import { ComboService } from './combo/combo.service';
import { DotButtonService } from './dot-button.service';
import { ModifiersService } from './modifiers/modifiers.service';

@Injectable({
  providedIn: 'root',
})
export class PriceCalculationService {
  constructor(
    private _db: DotButtonService,
    private _modifiers: ModifiersService,
    private _combo: ComboService
  ) {}

  /**
   * Check if a product is a combo, then return its price divided by 100 for display purposes
   * @param product The button we want the price for
   */
  getDisplayPrice(product: DotButton): string | null {
    if (!product) return null;
    let price = this.getPrice(product);
    if (!price) {
      return null;
    }
    return (price / 100).toFixed(2);
  }

  /**
   * Check if a product is a combo, then return its price divided by 100 for display purposes
   * @param product The button we want the price for
   */
  getModifierDisplayPrice(
    modifier: DotModifier,
    product: DotButton
  ): string | null {
    if (!product) return null;
    if (!modifier) return this.getDisplayPrice(product);

    const { PageInfo: modifierInfo, Buttons: buttons } = modifier;

    if (modifierInfo.ChargeThreshold < 0) {
      return null;
    }

    const modifiersItems = _.chain(buttons)
      .map((button) => {
        const quantity = button.quantity || (button.Selected ? 1 : 0);
        return _.range(quantity).map(() => button);
      })
      .flatten()
      .sortBy((button) => parseInt(button.Price, 10) || 0)
      .value();

    if (modifiersItems.length < modifierInfo.ChargeThreshold) {
      return null;
    }

    return this.getDisplayPrice(product);
  }

  /**
   * Return the price multiplied by the product's quantity
   * @param product The button we want the price for
   */
  getTotalDisplayPrice(product: DotButton): string | null {
    if (!product) return null;
    const price = this.getPrice(product);
    if (!price) return null;
    return ((price * product.quantity) / 100).toFixed(2);
  }

  /**
   * Check if a product is a combo, then return its price
   * @param product The button we want the price for
   */
  getPrice(product: DotButton): number | null {
    if (!product) return null;
    if (this._db.isCombo(product)) {
      return this.getComboPrice(product);
    }
    return (
      this.getProductPrice(product) + this._modifiers.getModifiersPrice(product)
    );
  }

  /**
   * Get the product's price and transform it
   * @button The button to get the price for.
   */
  getProductPrice(button: DotButton): number | null {
    if (!button) return null;
    const hasPrice = (btn: DotButton): boolean => {
      if (!btn.Price) return false;
      return true;
    };
    const hasMinPrice = (btn: DotButton): boolean => {
      if (!btn.MinPrice) return false;
      return true;
    };
    if (this._db.isDropdown(button)) {
      const regularMeal = button.Page.Buttons.find(button => button.Caption.toLowerCase().includes('meal'));
      const minPriceButton = regularMeal || button.Page.Buttons.reduce((previous, current) => {
        if (!previous) {
          if (hasPrice(current) || hasMinPrice(current)) return current;
        }
        if (hasPrice(current) || hasMinPrice(current)) {
          if (current.Price < previous.Price) return current;
          if (current.MinPrice < previous.MinPrice) return current;
        }
        return previous;
      }, null);
      if (hasPrice(minPriceButton)) return parseInt(minPriceButton.Price, 10);
      if (hasMinPrice(minPriceButton)) {
        return minPriceButton.MinPrice;
      }
    }
    if (hasPrice(button)) return parseInt(button.Price, 10);
    if (hasMinPrice(button)) return button.MinPrice;
    return null;
  }

  /**
   * This function be called in multiple cases: When default price is needed or when an Combo Edit is taking place
   * @param comboButton the button containing all the combo's data
   */
  getComboPrice(comboButton: DotButton): number | null {
    if (!comboButton) return null;
    const size = this._combo.getStartSize(comboButton);
    let price = 0;
    for (const combo of comboButton.ComboPage.Combos) {
      let defaultButtons;
      const hasAnySelected = (comboStep: DotCombo): boolean => {
        return comboStep.Buttons.some((button) => button.quantity > 0);
      };
      if (hasAnySelected(combo)) {
        // If at least one button, has quantity
        defaultButtons = combo.Buttons.filter(
          (button) => button.VisibleOn === size && button.quantity > 0
        );
      } else {
        // Default prices is needed:
        // As described in DOT19-894, default selection be made for Buttons with IncludedQuantity === 1 OR
        // the length of Buttons for that specific size has length === 1 (user can't select)
        defaultButtons = combo.Buttons.filter(
          (x) => x.VisibleOn === size
        ).filter(
          (button, index, buttons) =>
            button.IncludedQuantity === 1 || buttons.length === 1
        );
      }

      // Calculate the total price of all included/default buttons:
      const buttonsPrice = defaultButtons.reduce((totalPrice, button) => {
        return (
          totalPrice +
          button.MinPrice +
          this._modifiers.getModifiersPrice(button)
        );
      }, 0);
      // Update Price:
      price += buttonsPrice;
    }
    return price;
  }
}
