import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { ComboHeaderPictures } from '@core/acrelec-content';

import { ComboHeaderTitle } from '../../../../types/ComboHeaderTitle';

@Component({
  selector: 'app-combo-header',
  templateUrl: './combo-header.component.pug',
  styleUrls: ['./combo-header.component.scss'],
})
export class ComboHeaderComponent implements OnInit, OnChanges {
  @Input() title: ComboHeaderTitle;
  @Input() step: number;
  @Input() pictures: ComboHeaderPictures;

  constructor(private _sessionService: AccessibilityService) {}

  /**
   * We check if a previous picture exists for the element.
   * If not, we simply animate the picture.
   * If a previous picture is set, we swap them using an animation.
   * @param changes The change event containing the new header pictures
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.pictures) {
      const current = changes.pictures.currentValue;
      const previous = changes.pictures.previousValue;

      const hasPreviousSide: boolean = previous && previous.side;
      const hasCurrentSide: boolean = current && current.side;
      if (this.isSideActive && !hasPreviousSide && hasCurrentSide) {
        this.animateElements('firstStep', 'Side', current.side, 'ComboSide');
      } else if (
        hasCurrentSide &&
        hasPreviousSide &&
        current.side !== previous.side
      ) {
        this.animateElements('secondStep', 'Side', current.side, 'ComboSide');
      }

      const hasPreviousDrink: boolean = previous && previous.drink;
      const hasCurrentDrink: boolean = current && current.drink;
      if (this.isDrinkActive && !hasPreviousDrink && hasCurrentDrink) {
        this.animateElements('firstStep', 'Drink', current.drink, 'ComboDrink');
      } else if (
        hasCurrentDrink &&
        hasPreviousDrink &&
        current.drink !== previous.drink
      ) {
        this.animateElements(
          'secondStep',
          'Drink',
          current.drink,
          'ComboDrink'
        );
      }
    }
  }

  ngOnInit(): void {
    if (this.title.title) this.setTitleSize(this.title.title);
  }

  /**
   * Check if the drink picture is present
   */
  get isDrinkActive(): boolean {
    return !!this.pictures.drink;
  }

  /**
   * Check if side picture is present
   */
  get isSideActive(): boolean {
    return !!this.pictures.side;
  }

  /**
   * Animate the header picture using a DOM element ID
   * @param step current step id
   * @param parentId Parent DOM element id to which the animation will attach
   * @param currentValue Path of the header picture
   * @param className CSS class name for the img element being animated
   */
  animateElements(
    step: string,
    parentId: string,
    currentValue: string,
    className: string
  ): void {
    const transform =
      className === 'ComboSide'
        ? { from: '500rem', to: '-20rem' }
        : { from: '0rem', to: '572rem' };
    if (step === 'firstStep') {
      const parent = document.getElementById(parentId);
      const currentChild = document.createElement('img');
      currentChild.className = `${className}--isVisible`;
      currentChild.src = currentValue;
      currentChild.animate(
        [
          // keyframes
          { transform: `translateX(${transform.from})` },
          { transform: `translateX(${transform.to})` },
        ],
        {
          // timing options
          duration: 500,
          iterations: 1,
        }
      );
      parent.appendChild(currentChild);
    }

    if (step === 'secondStep') {
      const parent = document.getElementById(parentId);
      const previousChild = parent.lastChild as HTMLElement;
      previousChild.classList.remove(`${className}--isVisible`);
      previousChild.style.position = 'absolute';
      previousChild.classList.add(`${className}--isInvisible`);

      const newChild = document.createElement('img');
      newChild.src = currentValue;

      newChild.animate(
        [
          // keyframes
          { transform: `translateX(${transform.from})` },
          { transform: `translateX(${transform.to})` },
        ],
        {
          // timing options
          duration: 500,
          iterations: 1,
        }
      );

      newChild.className = `${className}--isVisible`;
      parent.appendChild(newChild);

      setTimeout(() => {
        parent.firstChild.remove();
      }, 500);
    }
  }

  /**
   * Defines the size of the title depending on the number of characters
   * @param title The title
   */
  private setTitleSize(title: string): void {
    const titleContainer = document.getElementById('ComboTitle');
    const length = title.length;
    if (length <= 23) {
      titleContainer.style.fontSize = '65rem';
      titleContainer.style.lineHeight = '65rem';
    } else {
      const size = 65 - (length - 23) * 2;
      titleContainer.style.fontSize = `${size}rem`;
      titleContainer.style.lineHeight = `${size}rem`;
    }
  }
}
