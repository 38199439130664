import { Injectable } from '@angular/core';

import { AppSettingsService } from '@app/core/acrelec-external';

@Injectable({
  providedIn: 'root',
})
export class AnimationsService {
  constructor(private _appSettingsService: AppSettingsService) {}

  /**
   * create a fake #transition div to manually display the page transition animation when needed
   */
  public pageSwitchTransition() {
    if (!this._appSettingsService.disabledOrangeTransition) {
      const parent = document.getElementById('app');
      const transition = document.createElement('div');
      transition.id = 'OrderTransition';
      parent.appendChild(transition);
      setTimeout(() => {
        parent.removeChild(transition);
      }, 450);
    } else {
      return false;
    }
  }
}
