import { Component, OnInit } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-sat-flag-number',
  templateUrl: './sat-flag-number.component.pug',
  styleUrls: ['./sat-flag-number.component.scss']
})
export class SatFlagNumberComponent implements OnInit {

  constructor(private _t: TranslationService) { }

  ngOnInit(): void {
  }

}
