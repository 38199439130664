import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

import { TranslationService } from '@core/translation';

enum btnStatus {
  START = 'start',
  DONE = 'done',
}

@Component({
  selector: 'app-add-combo-button',
  templateUrl: './add-combo-button.component.pug',
  styleUrls: ['./add-combo-button.component.scss'],
})
export class AddComboButtonComponent implements OnInit, AfterViewInit {
  @Input() isDisabled: boolean;
  @Output() lockUserInteraction = new EventEmitter<boolean>();
  @Output() addToBagClick = new EventEmitter<void>();
  private _btnStatut: string = null;
  constructor(private _t: TranslationService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const mainButton: HTMLElement = document.getElementsByClassName(
      'AddButton'
    )[0] as HTMLElement;
    mainButton.style.width = mainButton.clientWidth + 'rem';
  }

  /**
   * Start the two animations for the "Add to bag" button with a delay
   */
  private addToBag() {
    if (this.isDisabled) return;
    this.lockUserInteraction.emit(true);
    this._btnStatut = btnStatus.START;
    setTimeout(() => {
      this._btnStatut = btnStatus.DONE;
      setTimeout(() => {
        this.addToBagClick.emit();
      }, 1500); // Timeout for displaying the "added" message
    }, 1000); // Timeout for displaying the "add to bag" animation
  }
}
