import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { ModalModule } from '@core/modal';
import { ScannerModule} from '@core/scanner';
import { TranslationModule } from '@core/translation';
import { PopUpModule } from '@ui/pop-up';
import { SharedModule } from '@ui/shared';

import { VoucherScannerComponent } from './components/voucher-scanner.component';

@NgModule({
  declarations: [VoucherScannerComponent],
  imports: [
    AccessibilityModule,
    CommonModule,
    InlineSVGModule,
    ModalModule,
    PopUpModule,
    ScannerModule,
    SharedModule,
    TranslationModule,
  ],
  exports: [VoucherScannerComponent],
})
export class VoucherScannerModule {}
