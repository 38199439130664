import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecExternalModule } from '@core/acrelec-external';

@NgModule({
  declarations: [],
  imports: [AcrelecExternalModule, CommonModule],
})
export class ScannerModule {}
