import { Injectable } from '@angular/core';
import { DotButton, DotModifier } from 'dotsdk';
import * as _ from 'lodash';

import { DotButtonService } from '../dot-button.service';

@Injectable({
  providedIn: 'root',
})
export class ModifiersService {
  private static readonly ACTIVE_MODIFIER = '1';
  private static readonly INACTIVE_MODIFIER = '2';

  constructor(private _db: DotButtonService) {}

  /**
   * Decrease the modifier's quantity if possible
   * @param modifier the modifier to remove
   * @param modifiersGroup the entire modifier's group
   */
  decreaseModifier(
    modifier: DotButton,
    modifiersGroup?: DotModifier,
    force?: boolean
  ): void {
    // FIXME: Wait to see if I should respect this field
    // const min = modifier.MinQuantity;
    // if (modifier.quantity <= min) return;
    if (modifier.quantity) modifier.quantity--;
    if (!modifier.quantity) modifier.Selected = false;
  }

  /**
   * Returns modifiers from specific button
   */
  getModifiers(button: DotButton): DotButton[] {
    if (!this._db.hasModifiers(button)) return null;
    let flatModifiers: DotButton[] = [];
    button.ModifiersPage.Modifiers.forEach((modifier) =>
      flatModifiers.push(...modifier.Buttons)
    );
    return flatModifiers;
  }

  /**
   * Returns the price of the Button's selected modifiers
   */
  getModifierPrice(modifier: DotModifier): number {
    const { PageInfo: modifierInfo, Buttons: buttons } = modifier;

    if (modifierInfo.ChargeThreshold < 0) {
      return 0;
    }

    const modifiersItems = _.chain(buttons)
      .map((button) => {
        const quantity = button.quantity || (button.Selected ? 1 : 0);
        return _.range(quantity).map(() => button);
      })
      .flatten()
      .sortBy(button => parseInt(button.Price, 10) || 0)
      .value();

    if (modifiersItems.length <= modifierInfo.ChargeThreshold) {
      return 0;
    }

    const paidItems = modifiersItems.slice(modifierInfo.ChargeThreshold);

    return paidItems.reduce((sum, item) => sum + (parseInt(item.Price, 10) || 0), 0);
  }

  /**
   * Returns the price of the Button's selected modifiers
   */
  getModifiersPrice(button: DotButton): number {
    if (this._db.hasModifiers(button)) {
      const activeModifiers: DotModifier[] = button.activeModifiers;
      return activeModifiers.reduce(
        (modifiersPrice, modifier: DotModifier) =>
          modifiersPrice + this.getModifierPrice(modifier),
        0
      );
    }
    return 0;
  }

  /**
   * Returns an aray of modifier's names
   */
  getModifierStringsFromProduct(button: DotButton): string[] | null {
    if (!this._db.hasModifiers(button)) return null;
    const activeButtons = this.getAllSelectedModifiers(
      button.ModifiersPage.Modifiers
    );
    if (!activeButtons) return null;
    const modifiersStrings = [];
    activeButtons.forEach((modifier: DotButton) => {
      const caption = this._db.getCaption(modifier);
      if (!caption) return;
      modifiersStrings.push(caption);
    });
    if (modifiersStrings.length === 0) return null;
    return modifiersStrings;
  }

  /**
   * Filter modifiers from all the modifiers groups and return only the selected ones
   * @param modifier modifier groups to filter
   */
  getAllSelectedModifiers(modifiers: DotModifier[]): DotButton[] | null {
    const activeModifiers: DotButton[] = [];
    modifiers.forEach((page) => {
      const pageActiveModifiers = this.getSelectedModifiers(page);
      activeModifiers.push(...pageActiveModifiers);
    });
    if (activeModifiers.length === 0) return null;
    return activeModifiers;
  }

  /**
   * Filter modifiers and show only selected ones
   * @param modifier modifiers to filter
   */
  getSelectedModifiers(modifierPage: DotModifier): DotButton[] | null {
    const activeModifiers: DotButton[] = modifierPage.Buttons.filter(
      (button) => button.Selected
    );
    return activeModifiers;
  }

  /**
   * Filter modifiers and show only active ones
   * @param modifier modifiers to filter
   */
  getVisibleModifiers(modifier: DotModifier): DotButton[] {
    const filteredButtons = modifier.Buttons.filter(
      (button) => button.ButtonStatus === ModifiersService.ACTIVE_MODIFIER
    );
    const transformedButtons = filteredButtons.map((button) => {
      button.MaxQuantity = button.MaxQuantity ? button.MaxQuantity : 1;
      button.quantity = button.DefaultQuantity ? button.DefaultQuantity : 0;
      return button;
    });
    return transformedButtons;
  }

  /**
   * Increase the modifier's quantity if possible
   * @param modifier the modifier to add
   * @param modifiersGroup the entire modifier's group
   */
  increaseModifier(
    modifier: DotButton,
    modifiersGroup?: DotModifier,
    force?: boolean
  ): void {
    const max = modifier.MaxQuantity;
    if (modifier.quantity >= max) return;
    if (modifiersGroup && this.isGroupMaxQuantity(modifiersGroup)) return;
    modifier.Selected = true;
    modifier.quantity++;
  }

  /**
   * @param modifiers the modifiers pages from the button
   * @returns true if any group has the maximum quantity of selected modifiers
   */
  isAnyGroupMaxQuantity(modifiers: DotModifier[]): boolean {
    let isAnyGroupMaxQuantity = false;
    modifiers.forEach((modifier) => {
      const maxGroup = modifier.PageInfo.MaxQuantity;
      const selectedModifiers = this.getSelectedModifiers(modifier);
      if (maxGroup && selectedModifiers.length >= maxGroup) {
        isAnyGroupMaxQuantity = true;
      }
    });
    return isAnyGroupMaxQuantity;
  }

  /**
   * @param modifiersGroup the modifiers page with the group content
   * @returns true if the group has the maximum quantity of selected modifiers
   */
  isGroupMaxQuantity(modifiersGroup: DotModifier): boolean {
    const maxGroup = modifiersGroup.PageInfo.MaxQuantity;
    const selectedModifiers = this.getSelectedModifiers(modifiersGroup);
    if (maxGroup && selectedModifiers.length >= maxGroup) return true;
    return false;
  }

  /**
   * @param modifiersGroup the modifiers page with the group content
   * @returns true if the group has the minimum quantity of selected modifiers
   */
  isLessThanGroupMinQuantity(modifiersGroup: DotModifier): boolean {
    const minGroup = modifiersGroup.PageInfo.MinQuantity;
    const selectedModifiers = this.getSelectedModifiers(modifiersGroup);
    if (minGroup && selectedModifiers.length < minGroup) return true;
    return false;
  }
  /**
   * Reset all modifiers quantity to 0
   * @param modifiersGroups modifier array to reset
   */
  resetModifierButtons(modifiersGroups: DotModifier[]) {
    if (!modifiersGroups) {
      return;
    }
    modifiersGroups.forEach((modifiersGroup) => {
      modifiersGroup.Buttons.forEach((modifier) => {
        modifier.Selected = false;
        modifier.quantity = modifier.DefaultQuantity
          ? modifier.DefaultQuantity
          : 0;
      });
    });
  }

  /**
   * Returns product's default modifiers
   * @param modifiersGroups Button's modifiers
   */
  selectDefaults(modifiersGroups: DotModifier[]) {
    modifiersGroups.forEach((modifiersGroup) => {
      const modifiers = this.getVisibleModifiers(modifiersGroup);
      modifiers.forEach((modifier: DotButton) => {
        if (this.isGroupMaxQuantity(modifiersGroup)) return;
        this._db.selectDefaults(modifier);
      });
    });
  }

  /**
   * @param modifiersButtons Modifiers to sort by name
   * @returns Array of modifiers sorted by name
   */
  sortByName(modifiersButtons: DotButton[]): DotButton[] {
    return modifiersButtons.sort((a, b) => {
      const captionA = this._db.getCaption(a);
      const captionB = this._db.getCaption(b);
      return captionA.localeCompare(captionB);
    });
  }

  /**
   * @param modifiersButtons Modifiers to sort by price
   * @returns Array of modifiers sorted by price
   */
  sortByPrice(modifiersButtons: DotButton[]): DotButton[] {
    return modifiersButtons.sort((a, b) => {
      const aPrice = a.Price ? a.Price : 0;
      const bPrice = b.Price ? b.Price : 0;
      if (aPrice > bPrice) {
        return -1;
      } else if (aPrice < bPrice) {
        return 1;
      }
      return 0;
    });
  }
}
