import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  PaymentComponent,
  PaymentCounterComponent,
  PaymentFailureComponent,
  PaymentProcessingComponent,
  PaymentRemoteComponent,
  PaymentSuccessComponent,
  PaymentTypeComponent,
  PaymentWaitingComponent,
} from './components';
import { PaymentGuard } from './guard/payment.guard';

const paymentRoutes: Routes = [
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [PaymentGuard],
    data: { animation: 'Payment' },
    children: [
      {
        path: '',
        component: PaymentTypeComponent,
        data: { animation: 'Payment' },
      },
      {
        path: 'counter',
        component: PaymentCounterComponent,
        data: { animation: 'Payment' },
      },
      {
        path: 'remote',
        component: PaymentRemoteComponent,
        data: { animation: 'Payment' },
        children: [
          {
            path: '',
            component: PaymentProcessingComponent,
            data: { animation: 'Payment' },
          },
          {
            path: 'waiting',
            component: PaymentWaitingComponent,
            data: { animation: 'Payment' },
          },
          {
            path: 'failure',
            component: PaymentFailureComponent,
            data: { animation: 'Payment' },
          },
          {
            path: 'success',
            component: PaymentSuccessComponent,
            data: { animation: 'Payment' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(paymentRoutes)],
  exports: [RouterModule],
})
export class PaymentRoutingModule {}
