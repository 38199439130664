import { Component, Input } from '@angular/core';

import { AllergensService, CustomAllergen } from '@core/allergens';

@Component({
  selector: 'app-allergens-item',
  templateUrl: './allergens-item.component.pug',
  styleUrls: ['./allergens-item.component.scss'],
})
export class AllergensItemComponent {
  @Input() product: object;
  @Input() productKey?: number;
  @Input() isAccessibilityActivated: boolean;

  constructor(private _allergensService: AllergensService) { }

  /**
   * Add allergen to the order
   * @param name The name of the allergen
   */
  addAllergen(name: string): void {
    this._allergensService.selectAllergen(name);
  }

  /**
   * Return the name of the allergen
   * @param allergen The product of the allergen
   */
  getAllergenName(allergen: CustomAllergen): string {
    return this._allergensService.getAllergenName(allergen);
  }

  /**
   * Return the caption of the allergen
   * @param allergen The product of the allergen
   */
  getAllergenCaption(allergen: CustomAllergen): string {
    return this._allergensService.getAllergenCaption(allergen);
  }
}
