import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';

@NgModule({
  declarations: [],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    CommonModule,
    SessionModule,
    TranslationModule,
  ],
})
export class AllergensModule {}
