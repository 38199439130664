import { Component, OnInit } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-sat-success',
  templateUrl: './sat-success.component.pug',
  styleUrls: ['./sat-success.component.scss']
})
export class SatSuccessComponent implements OnInit {

  constructor(private _t: TranslationService) { }

  ngOnInit(): void {
  }

}
