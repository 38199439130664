import {
  trigger,
  animate,
  state,
  style,
  transition,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DotButton } from 'dotsdk';
import { findLastIndex } from 'lodash';

import { AccessibilityService } from '@core/accessibility';
import {
  AvailabilityService,
  ComboService,
  DotButtonService,
  PriceCalculationService,
} from '@core/acrelec-content';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.pug',
  styleUrls: ['./product-list.component.scss'],
  animations: [
    trigger('displayMenuSize', [
      state(
        'open',
        style({
          height: '{{height}}',
        }),
        {
          params: {
            height: '335rem',
          },
        }
      ),
      state(
        'closed',
        style({
          height: '0',
        })
      ),
      transition(':enter, :leave', [animate('300ms')]),
    ]),
  ],
})
export class ProductListComponent implements OnChanges, OnInit {
  @Input() activeButton: string;
  @Input() dropDownButtons: DotButton[];
  @Input() orderPageTitle: string;
  @Input() visibleButtons: DotButton[];
  @Output('onProductButtonClick') onProductClick = new EventEmitter<{
    button: DotButton;
    event: Event;
  }>();
  private _language: string;

  constructor(
    private _db: DotButtonService,
    private _comboService: ComboService,
    private _priceService: PriceCalculationService,
    private _translationService: TranslationService,
    private _availabilityService: AvailabilityService,
    private _sessionService: AccessibilityService
  ) {}

  ngOnInit(): void {
    this._language = this._translationService.language;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const orderPageTitle = changes?.orderPageTitle;
    if (orderPageTitle?.previousValue !== orderPageTitle?.currentValue) {
      const dropdown = document.getElementById('ButtonsContainer__Dropdown');
      if (dropdown) {
        dropdown.style.display = 'none';
      }
    }
  }

  private get dropdownIndex(): number {
    const divider = this._sessionService.isAccessibilityActivated ? 5 : 3;
    let activeButtonIndex = this.visibleButtons.findIndex(
      (button) => button.Link === this.activeButton
    );
    let dropdownIndex =
      activeButtonIndex !== -1
        ? Math.floor(activeButtonIndex / divider) * divider + (divider - 1)
        : -1;
    if (this.visibleButtons.length <= dropdownIndex) {
      dropdownIndex = findLastIndex(this.visibleButtons);
    }
    return dropdownIndex;
  }

  /**
   * Get the calories from the product
   * @param product The desired product
   */
  private getProductCalories(product: DotButton): string | null {
    if (
      product.Page &&
      product.Page.Buttons.length > 0 &&
      product.Tags.includes('dropdown')
    ) {
      // Retrieve calories from the single product if present
      const singleProduct = product.Page.Buttons.find(
        (button) => !this._db.isCombo(button)
      );
      if (singleProduct) {
        return this._db.getCalories(singleProduct);
      }
      // Retrieve calories from the first combo main product if no single item is present
      const comboProduct = product.Page.Buttons.find((button) =>
        this._db.isCombo(button)
      );
      if (comboProduct) {
        return this._comboService.getCalories(comboProduct);
      }
    }
    return this._db.getCalories(product);
  }

  private getDropdownScroll(event: any): void {
    event.stopPropagation();
    const dropdownHeight = this._sessionService.isAccessibilityActivated
      ? 215
      : 335;
    const targetInformations = event.currentTarget.getBoundingClientRect();
    const downPosition = targetInformations.y + targetInformations.height;
    const footerHeight = 290;
    const maximimDropDownPosition = 1920 - (footerHeight + dropdownHeight);
    const currentScroll = document.getElementById('Scroll').scrollTop;
    const necessaryScroll = downPosition - maximimDropDownPosition;
    const scrollTo =
      currentScroll === 0 ? necessaryScroll : currentScroll + necessaryScroll;

    if (downPosition > maximimDropDownPosition) {
      setTimeout(() => {
        document
          .getElementById('Scroll')
          .scrollTo({ top: scrollTo, behavior: 'smooth' });
      }, 250);
    }
  }

  /**
   * Used to open a dropdown or to navigate
   * depending on the button type
   * @param event
   * @param button
   */
  private onProductButtonClick(event: any, button?: DotButton): void {
    if (button && this._db.isDropdown(button)) {
      this.getDropdownScroll(event);
    }
    if (button) {
      return this.onProductClick.emit({
        button,
        event,
      });
    }
    return this.onProductClick.emit({
      button: event.button,
      event: event.event,
    });
  }

  /**
   * Disable products from the product list
   */
  private isProductDisabled(button: DotButton): boolean {
    return this._availabilityService.isButtonDisabled(button);
  }
}
