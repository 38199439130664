import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tile-button',
  templateUrl: './tile-button.component.pug',
  styleUrls: ['./tile-button.component.scss'],
})
export class TileButtonComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() svgPath: string;
  @Output() tileClick = new EventEmitter<null>();

  constructor() {}

  ngOnInit(): void {}

  private onTileClick(event): void {
    event.stopPropagation();
    event.preventDefault();
    if (!this.isDisabled) {
      this.tileClick.emit();
    }
  }
}
