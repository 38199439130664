import { Component, OnInit, Input } from '@angular/core';
import { DotBanner } from 'dotsdk';

import { AppSettingsService } from '@app/core/acrelec-external';

import { sliderAnimation } from '../animations/slider.animation';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.pug',
  styleUrls: ['./slider.component.scss'],
  animations: [sliderAnimation],
})
export class SliderComponent implements OnInit {
  @Input() navigationItems: DotBanner[];
  private _visibleVideoIndex: number;

  constructor(
    private _settingsService: AppSettingsService
  ) {}

  ngOnInit(): void {
    if (this.navigationItems.length > 0) {
      this.bannerSlider(this.navigationItems);
    }
  }

  /**
   * Get specific banner image and return its path
   * @param banner Banner we get the image from
  */
  private getImage(banner: DotBanner): string {
    return this._settingsService.sharedFolderPath + '/assets/Banners/' + banner.Image;
  }

  /**
   * Check image extension to return type attribute for html tag
   * @param banner  Banner we get the image's file from
   */
  private getFileType(banner: DotBanner): string {
    return banner.Image.endsWith('mp4') ? 'video/mp4' : 'video/ogg';
  }

  /**
   * Set duration that banner should be displayed
   * @param interval Duration that banner should be displayed
   */
  private setSliderDelay(interval: number) {
    return new Promise((resolve) => setTimeout(resolve, interval));
  }

  /**
   * Set new slide and it's duration
   * @param item banner to display
   */
  private async sliderDelayedAction(item: DotBanner) {
    this._visibleVideoIndex = item.ID;
    const interval = item.Interval || item.Interval !== 0 ? item.Interval : 6 * 1000;
    await this.setSliderDelay(item.Interval);
  }

  /**
   * Set slider from banners
   * @param array Array of banners to display
   */
  private async bannerSlider(array: DotBanner[]) {
    for (const item of array) {
      await this.sliderDelayedAction(item);
    }
    this.bannerSlider(array);
  }

  /**
   * Check if banner's media is a video
   * @param item Banner to check image type from
   */
  private isVideo(item: DotBanner): boolean {
    return item.Image.endsWith('mp4') || item.Image.endsWith('ogg');
  }
}
