import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ScreenSaverComponent } from '@pages/screen-saver';
import { ServiceComponent } from '@pages/service';

import { ServiceGuard } from './guard/service.guard';

const routes: Routes = [
  {
    path: '',
    component: ScreenSaverComponent,
    data: { animation: 'ScreenSaver' },
  },
  {
    path: 'service',
    canActivate: [ServiceGuard],
    component: ServiceComponent,
    data: { animation: 'Service' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
