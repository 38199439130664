import {
  animate,
  trigger,
  style,
  transition,
  state,
} from '@angular/animations';
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { TranslationService } from '@core/translation';
import { PopUpService } from '@ui/pop-up/services/pop-up.service';
import { PopUp } from '@ui/pop-up/types/PopUp';

import { PopUpContentComponent } from './pop-up-content/pop-up-content.component';
import { PopUpContentDirective } from './pop-up-content/pop-up-content.directive';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.pug',
  styleUrls: ['./pop-up.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '452rem',
          width: '967rem',
          borderRadius: '20rem',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '100rem',
          width: '100rem',
          borderRadius: '100rem',
          opacity: 0.5,
          backgroundColor: 'green',
        })
      ),
      transition(':enter', [animate('300ms ease-out')]),
      transition('open => closed', [
        animate('200ms ease-out'),
        style({ opacity: 0 }),
      ]),
    ]),
    trigger('openCloseText', [
      state(
        'open',
        style({
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
        })
      ),
      transition(':enter', [animate('300ms ease-out')]),
      transition(':leave', [animate('250ms ease-out')]),
    ]),
  ],
})
export class PopUpComponent implements OnInit {
  @ViewChild(PopUpContentDirective, { static: true })
  private popUpContent: PopUpContentDirective;

  private _content: PopUp;
  private _popUpStatus: boolean;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _popUpService: PopUpService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService
  ) {}

  ngOnInit(): void {
    this._popUpService.content.subscribe((content) => {
      if (!content) return;
      this._content = content;
      this.loadComponent();
      this._popUpStatus = true;
    });
  }

  /**
   * Executes the function defined for the pop-up right button
   */
  rightButtonCallback(): void {
    if (this._content.rightButtonCallback) {
      this._popUpStatus = false;
      this._content.rightButtonCallback();
    }
  }

  /**
   * Executes the function defined for the pop-up left button
   */
  leftButtonCallback(): void {
    if (this._content.leftButtonCallback) {
      this._popUpStatus = false;
      setTimeout(() => {
        this._content.leftButtonCallback();
      }, 200);
    }
  }

  /**
   * Loads the pop-up component and sets its content
   */
  loadComponent(): void {
    const popUpContent = this._content.component;
    const popUpContentData = this._content.componentData;

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
      popUpContent
    );

    const viewContainerRef = this.popUpContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const popUpContentComponent = componentRef.instance as PopUpContentComponent;
    popUpContentComponent.data = popUpContentData;
  }
}
