import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SatActivateComponent } from './components/sat-activate/sat-activate.component';
import { SatCounterComponent } from './components/sat-counter/sat-counter.component';
import { SatFlagNumberComponent } from './components/sat-flag-number/sat-flag-number.component';
import { SatSuccessComponent } from './components/sat-success/sat-success.component';
import { SatUnavailableComponent } from './components/sat-unavailable/sat-unavailable.component';
import { SatGuard } from './guard/sat.guard';
import { SatComponent } from './sat.component';

const satRoutes: Routes = [
  {
    path: 'sat',
    canActivate: [SatGuard],
    component: SatComponent,
    data: { animation: 'Payment' },
    children: [
      {
        path: '',
        component: SatActivateComponent,
        data: { animation: 'Payment' },
      },
      {
        path: 'counter',
        component: SatCounterComponent,
        data: { animation: 'Payment' },
      },
      {
        path: 'success',
        component: SatSuccessComponent,
        data: { animation: 'Payment' },
      },
      {
        path: 'flag-number',
        component: SatFlagNumberComponent,
        data: { animation: 'Payment' },
      },
      {
        path: 'unavailable',
        component: SatUnavailableComponent,
        data: { animation: 'Payment' },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(satRoutes)],
  exports: [RouterModule],
})
export class SatRoutingModule { }
