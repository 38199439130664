import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';

import { AppCurrencyPipe } from './pipes/app-currency.pipe';
import { ButtonAvailabilityPipe } from './pipes/button-availability.pipe';
import { DropdownContentPipe } from './pipes/dropdown-content.pipe';
import { NavigationAvailabilityPipe } from './pipes/navigation-availability.pipe';
import { AllergensContentPipe } from './validations/allergens-content.pipe';
import { BannersContentPipe } from './validations/banners-content.pipe';
import { ButtonContentPipe } from './validations/button-content.pipe';

@NgModule({
  declarations: [
    AllergensContentPipe,
    AppCurrencyPipe,
    BannersContentPipe,
    ButtonAvailabilityPipe,
    ButtonContentPipe,
    DropdownContentPipe,
    NavigationAvailabilityPipe,
  ],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    CommonModule
  ],
  exports: [
    AllergensContentPipe,
    AppCurrencyPipe,
    BannersContentPipe,
    ButtonAvailabilityPipe,
    ButtonContentPipe,
    DropdownContentPipe,
    NavigationAvailabilityPipe,
  ],
})
export class PipesModule { }
