import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@pipes';

import { SliderComponent } from './components/slider.component';

@NgModule({
  declarations: [SliderComponent],
  imports: [CommonModule, PipesModule],
  exports: [SliderComponent],
})
export class SliderModule {}
