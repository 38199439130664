import { Component, OnInit } from '@angular/core';
import { PosServingLocation } from 'dotsdk';

import { RecommendationService } from '@app/core/recommendation';
import { AccessibilityService } from '@core/accessibility';
import { AppSettingsService, ContentService } from '@core/acrelec-external';
import { NavigationService, ScreenType } from '@core/navigation';
import { PaymentService } from '@core/payment';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.pug',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {
  constructor(
    private _accessibilityService: AccessibilityService,
    private _appSettings: AppSettingsService,
    private _contentService: ContentService,
    private _navigationService: NavigationService,
    private _paymentService: PaymentService,
    private _popupService: PopUpService,
    private _sessionService: SessionService,
    private _t: TranslationService,
    private _recommendationService: RecommendationService
  ) {}

  ngOnInit(): void {}

  /**
   * Return the current language
   */
  get currentLanguage(): string {
    return this._t.language.toLowerCase();
  }

  /**
   * Return the status of PMR mode
   */
  get accessibilityStatus(): boolean {
    return this._accessibilityService.isAccessibilityActivated;
  }

  /**
   * Return the status of PMR mode
   */
  get showPaymentMethods(): boolean {
    return !this._appSettings.hidePaymentMethods;
  }

  /**
   * Used to change the language of the order
   * @param language The language that will be defined
   */
  changeLanguage(language: string) {
    this._t.language = language;
  }

  /**
   * Change the type order type  and redirection to products page
   * @param service The type of service for the order
   */
  changeServiceType(service: PosServingLocation) {
    this._paymentService
      .initializeOrder(new Date())
      .then(() => {
        this._appSettings.serviceType = service;
        this._sessionService.initializeOrderSession(service);
        this._navigationService.addStack({
          type: ScreenType.ORDER_AREA,
          content: this._contentService.mainPage,
        });
      })
      .catch((e) => {
        console.error(e);
        this._popupService.open({
          component: MessagePopUpComponent,
          componentData: this._t.translate(54, 'Error loading order'),
        });
      });
    this._recommendationService.createOrder(this._appSettings.isEnvironment, service);
  }
}
