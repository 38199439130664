import { CommonModule } from '@angular/common';
import { forwardRef, NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { SessionModule } from '@core/session';

@NgModule({
  declarations: [],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    CommonModule,
    SessionModule
  ],
})
export class RecommendationModule { }
