import { Injectable } from '@angular/core';
import { DotButton } from 'dotsdk';

import { DotButtonService } from '@core/acrelec-content';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  private static readonly INACTIVE = 'inactive';
  private _activeButton = DropdownService.INACTIVE;
  private _lastActiveButton = DropdownService.INACTIVE;
  private _dropdownParent: DotButton | null = null;

  constructor(private _db: DotButtonService) {}

  /**
   * @returns the active dropdown button's link ID or 'inactive'
   */
  get activeDropdownButton(): string {
    return this._activeButton;
  }

  /**
   * Used to clear the dropdown parent
   */
  clearDropdownParent(): void {
    this._dropdownParent = null;
  }

  /**
   * Hide the dropdown by clearing all dropdown values
   */
  hideDropdown(): void {
    this._activeButton = DropdownService.INACTIVE;
    this._lastActiveButton = DropdownService.INACTIVE;
    this.clearDropdownParent();
  }

  /**
   * @param clickedButton Product to check
   * @returns true if the product clicked is included in the dropdown parent;
   */
  isDropdownChild(clickedButton: DotButton): boolean {
    if (!this._dropdownParent || !this._dropdownParent.Page.Buttons) {
      return false;
    }
    return this._dropdownParent.Page.Buttons.some((button) =>
      this._db.isSameButton(clickedButton, button)
    );
  }

  /**
   * Set the dropdown child values by getting the parent's values
   * @param dropdownChild dropdown child button that should be altered
   */
  setDropdownChildValues(dropdownChild: DotButton) {
    const dropdownParent = this.dropdownParentValues;
    if (!dropdownParent) return;
    dropdownChild.Picture = dropdownParent.picture;
    dropdownChild.Caption = `${dropdownParent.caption} ${dropdownChild.Caption}`;
  }

  setDropdown(
    clickedButton: DotButton,
    pageButtons: DotButton[]
  ): DotButton[] | null {
    const activeButton = pageButtons.find(
      (visibleButton) => clickedButton.Link === visibleButton.Link
    );
    if (!activeButton) return null;
    // Hide the dropdown
    if (activeButton.Link === this._lastActiveButton) {
      this.clearDropdownParent();
      this.hideDropdown();
      return null;
    }
    // Open the dropdown
    this.activeButtons = activeButton.Link;
    this.setDropdownParent(pageButtons);
    return clickedButton.Page.Buttons;
  }

  /**
   * Use the visible buttons array to retrieve the button that has been clicked and save it.
   * @param pageButtons Array of page buttons to get the parent button from
   */
  private setDropdownParent(pageButtons: DotButton[]) {
    this._dropdownParent = pageButtons.find(
      (button) => button.Link === this._activeButton
    );
  }

  /**
   * @returns The parent caption and picture of the clicked dropdown item
   */
  private get dropdownParentValues(): {
    caption: string;
    picture: string;
  } | null {
    if (!this._dropdownParent) return null;
    const caption = this._db.getCaption(this._dropdownParent);
    const picture = this._dropdownParent.Picture;
    return { caption, picture };
  }

  /**
   * Set the active button to the given link ID
   * @param link ID of the active button
   */
  private set activeButtons(link: string) {
    this._activeButton = link;
    this._lastActiveButton = link;
  }
}
