import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AtpEnvironmentService,
  IPeripheralsStatus,
  AtpPaymentService,
} from 'dotsdk';

import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class PeripheralsService {
  private _atpService: AtpEnvironmentService;
  private _atpPaymentService: AtpPaymentService;

  constructor(
    private _http: HttpClient,
    private _settingsService: AppSettingsService
  ) {
    this._atpService = AtpEnvironmentService.getInstance();
    this._atpPaymentService = AtpPaymentService.getInstance();
  }

  /**
   * Returns the list of peripherals set on current kiosk according to current kiosk profile and it's availability
   */
  async getPeripheralsStatus() {
    const peripheralStatus =
      this._atpService.mocksOptions.useMocks === false
        ? await this._atpService.getPeripheralsStatus()
        : await this._http
            .get('assets/mocks/atp-environment/getPeripheralsStatus.json')
            .toPromise()
            .then((response) => {
              return response;
            });

    return peripheralStatus;
  }

  /**
   * Returns printer availability
   */
  async getPrinterStatus(): Promise<boolean> {
    const peripheralStatus = (await this.getPeripheralsStatus()) as IPeripheralsStatus;
    return peripheralStatus.IsPrinterAvailable;
  }

  /**
   * Check if payment is available
   */
  async isPaymentAvailable() {
    return this._settingsService.useMocksForPos
      ? this._settingsService.useMocksForPos
      : await this._atpPaymentService.isPaymentAvailable().catch(() => false);
  }
}
