import {
  animateChild,
  query,
  trigger,
  transition,
  useAnimation,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { ModalService, ModalType } from '@app/core/modal';
import { AccessibilityService } from '@core/accessibility';
import { Animations } from '@core/animations';
import { PromotionsService } from '@core/promotions';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUp, PopUpService } from '@ui/pop-up';

import { KeyboardService } from '../services/keyboard.service';

@Component({
  selector: 'app-voucher-manual',
  templateUrl: './voucher-manual.component.pug',
  styleUrls: ['./voucher-manual.component.scss'],
  animations: [
    trigger('displayModal', [
      transition(':enter', [
        useAnimation(Animations.inflateModal, { params: { time: '300ms' } }),
        query('@displayChild', animateChild({ delay: '0' })),
      ]),
    ]),
    trigger('displayChild', [
      transition(':enter', [
        useAnimation(Animations.showModalChild, {
          params: { time: '300ms' },
        }),
      ]),
    ]),
  ],
})
export class VoucherManualComponent implements OnInit {
  private keys: any[] = [];

  constructor(
    private _accessibility: AccessibilityService,
    private _kb: KeyboardService,
    private _modalService: ModalService,
    private _popupService: PopUpService,
    private _promotionsService: PromotionsService,
    private _t: TranslationService
  ) {
    this.keys = _kb.keyboard;
  }

  ngOnInit(): void {
    this._kb.resetBarCode();
  }

  /**
   * Close voucher keyboard
   */
  close(): void {
    this._kb.resetBarCode();
    this._kb.close();
  }

  /**
   * Displays the product if the barcode is valid
   */
  validateBarCode(): void {
    const barCode = this._kb.barCode;
    this._promotionsService.displayPromotion(barCode, this._modalService.closeModal)
  }
}
