import { Component } from '@angular/core';

import { AppSettingsService } from '@app/core/acrelec-external';
import { ModalService, ModalType } from '@core/modal';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-order-infos',
  templateUrl: './order-infos.component.pug',
  styleUrls: ['./order-infos.component.scss'],
})
export class OrderInfosComponent {
  private _language: string;

  constructor(
    private _modalService: ModalService,
    private _appSettingsService: AppSettingsService,
    private _t: TranslationService
  ) {
    this._language = _t.language;
  }

  /**
   * Display a modal with allergens
   */
  private displayAllergens(): void {
    this._modalService.setModal({ type: ModalType.ALLERGENS });
  }

  /**
   * Display a modal with calories
   */
  private displayCalories(): void {
    this._modalService.setModal({ type: ModalType.CALORIES });
  }

  /**
   * display a modal with keyboard
   */
  private displayVoucher(): void {
    const hasManualVouchers = this._appSettingsService.hasManualVouchers;
    const hasScannerVoucher = this._appSettingsService.hasScannerVoucher;
    if (hasManualVouchers && hasScannerVoucher) {
      this._modalService.setModal({ type: ModalType.VOUCHER_CHOICE });
    } else if (hasManualVouchers) {
      this._modalService.setModal({ type: ModalType.VOUCHER_MANUAL });
    } else if (hasScannerVoucher) {
      this._modalService.setModal({ type: ModalType.VOUCHER_SCANNER });
    }
  }
}
