import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-sat-single-key',
  templateUrl: './sat-single-key.component.pug',
  styleUrls: ['./sat-single-key.component.scss']
})
export class SatSingleKeyComponent implements OnInit {
  @Input() keyValue?: string;
  @Input() isDelete?: boolean;
  @Input() isClear?: boolean;
  @Output() emitKeyValue: EventEmitter<string> = new EventEmitter();
  constructor(private _t: TranslationService) {}

  ngOnInit() {}
}
