import { registerLocaleData } from '@angular/common';
import localeArabic from '@angular/common/locales/ar';
import localeGerman from '@angular/common/locales/de';
import localeBritishEnglish from '@angular/common/locales/en-GB';
import localeSpanish from '@angular/common/locales/es';
import localeFrench from '@angular/common/locales/fr';
import localeItalian from '@angular/common/locales/it';
import localePolish from '@angular/common/locales/pl';
import localeRussian from '@angular/common/locales/ru';
import localeSweden from '@angular/common/locales/sv';
import localeChinese from '@angular/common/locales/zh';
import { Injectable } from '@angular/core';

import { AppSettingsService, ContentService } from '@core/acrelec-external';
import { SessionService } from '@core/session';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _currency: string;
  private _language: string;
  private _locale: string;

  constructor(
    private _appSettingsService: AppSettingsService,
    private _contentService: ContentService,
    private _sessionService: SessionService
  ) {
    this.resetDefaultLanguage();
    this._currency = this._appSettingsService.currency;
    // Subscribes to the session observable and reset language on session reset
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) this.resetDefaultLanguage();
    });
  }

  /**
   * Get application's currency
   */
  get currency(): string {
    return this._currency;
  }

  /**
   * Get current language
   */
  get language(): string {
    return this._language;
  }

  /**
   * Get current locale for price or date format
   */
  get locale(): string {
    return this._locale;
  }

  /**
   * Set language code
   * @param language Language code
   */
  set language(language: string) {
    this._language = language;
    this.locale = language;
  }

  set locale(language: string) {
    if (!language) {
      return;
    }
    this._locale = language.toLowerCase();

    // Register locale data since only the en-US locale data comes with Angular
    switch (this._locale) {
      case 'ar': {
        registerLocaleData(localeArabic);
        break;
      }
      case 'de': {
        registerLocaleData(localeGerman);
        break;
      }
      case 'es': {
        registerLocaleData(localeSpanish);
        break;
      }
      case 'fr': {
        registerLocaleData(localeFrench);
        break;
      }
      case 'it': {
        registerLocaleData(localeItalian);
        break;
      }
      case 'pl': {
        registerLocaleData(localePolish);
        break;
      }
      case 'ru': {
        registerLocaleData(localeRussian);
        break;
      }
      case 'zh': {
        registerLocaleData(localeChinese);
        break;
      }
      case 'sv': {
        registerLocaleData(localeSweden);
        break;
      }
      default: {
        registerLocaleData(localeBritishEnglish);
        break;
      }
    }
  }

  /**
   * Reset language to default
   */
  resetDefaultLanguage(): void {
    this.language = this._appSettingsService.currentLanguage;
  }

  /**
   * Get translation from id or return refault value
   * @param id translation id
   * @param defaultValue default value if there is no translation
   */
  translate(id: number, defaultValue: string): string {
    const item: any = this._contentService.internationalization.find(
      (item: any) => parseInt(item.ID, 10) === id
    );
    if (!item) {
      return defaultValue;
    }
    // We compare the object's property with upper and lower case, since both cases are used.
    let translation = item[`${this._language}`];
    if (!translation) {
      translation = item[`${this._language.toUpperCase()}`];
    }
    if (!translation) {
      translation = item[`${this._language.toLowerCase()}`];
    }
    if (!translation) {
      return defaultValue;
    }
    return translation;
  }

  isAvailable(language: string): boolean {
    const item: any = this._contentService.internationalization.find(
      (item: any) => item.ID === 'available'
    );
    // We compare the object's property with upper and lower case, since both cases are used.
    if (!item) {
      return false;
    }
    let translation = item[`${language}`];
    if (!translation) {
      translation = item[`${language.toUpperCase()}`];
    }
    if (!translation) {
      translation = item[`${language.toLowerCase()}`];
    }
    return translation === 'true';
  }
}
