import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { OrderStateEnum } from 'acrelec-recommendation-sdk';
import {
  AtpPaymentService,
  AtpPrinterService,
  DotButton,
  DotProductType,
  IPayResponse,
  IPosProduct,
  IPosResponse,
  PosConfig,
  PosElogHandler,
  PosInjectorService,
  PosOperationDevicesTypes,
  PosPaidState,
  PosTenderType,
  sessionEndEvent,
} from 'dotsdk';
import { IPosTestConnectionResponse } from 'dotsdk/src/transactions/models/pos-test-connection-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { retry } from 'ts-retry-promise';

import {
  ComboService,
  DotButtonService,
  ModifiersService,
} from '@core/acrelec-content';
import { AppSettingsService, PeripheralsService } from '@core/acrelec-external';
import { BasketService } from '@core/basket';
import { NavigationService, Page, ScreenType } from '@core/navigation';
import { RecommendationService } from '@core/recommendation';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';
import { PaymentType } from '@pages/payment/components/types/PaymentType';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

import { PaymentSteps } from '../types/PaymentSteps';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private static readonly REF_INT_FILE_PATH = 'shared\\refint.txt';

  private counterPaymentPage: Page = {
    type: ScreenType.PAYMENT_COUNTER,
    content: null,
  };
  private satCounterPage: Page = {
    type: ScreenType.SERVICE_AT_TABLE_COUNTER,
    content: null,
  };
  private remotePaymentPage: Page = {
    type: ScreenType.PAYMENT_REMOTE,
    content: null,
  };

  private _paymentStatus = new BehaviorSubject<PaymentSteps>(PaymentSteps.Idle);
  private readonly _posInjectorService: PosInjectorService;
  private _language: string;
  private _atpPaymentService: AtpPaymentService;
  private _atpPrinterService: AtpPrinterService;
  private _orderNumber: number;
  private _tentNumber: number | undefined;

  constructor(
    private _basketService: BasketService,
    private _comboService: ComboService,
    private _db: DotButtonService,
    private _http: HttpClient,
    private _modifiersService: ModifiersService,
    private _navigationService: NavigationService,
    private _peripheralsService: PeripheralsService,
    private _popupService: PopUpService,
    private _recommendationService: RecommendationService,
    private _sessionService: SessionService,
    private _settingsService: AppSettingsService,
    private _translationService: TranslationService
  ) {
    this._language = _translationService.language;
    this._posInjectorService = PosInjectorService.getInstance();
    this._atpPaymentService = new AtpPaymentService();
    this._atpPrinterService = new AtpPrinterService();

    // Subscribes to the session observable and reset tent number on session reset
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) {
        this._tentNumber = undefined;
      }
    });

    this.paymentStatusObservable.subscribe((paymentStatus: PaymentSteps) => {
      this._sessionService.paymentStatus = paymentStatus;
    });
  }

  get paymentStatusObservable(): Observable<PaymentSteps> {
    return this._paymentStatus.asObservable();
  }

  get tentNumber(): number | undefined {
    return this._tentNumber;
  }

  set tentNumber(tentNumber: number) {
    const posConfig = PosElogHandler.getInstance().posConfig;
    if (!this._settingsService.serviceAtTable || !posConfig) return;
    this._tentNumber = tentNumber;
    /* eslint-disable-next-line camelcase */
    posConfig.posHeader.cvars.TS_No = tentNumber;
  }

  /**
   * Updates POS configuration, then calculates total order transaction
   */

  async calculatePosTotalPrice(): Promise<IPosResponse> {
    // this._currentPosConfiguration.posHeader.updateWith({
    //   functionTypeNumber: PosFunctions.CalculateTransactionTotals,
    //   firstItemAddedTime: this._basketService.firstBasketProductDate,
    // });

    if (
      this._settingsService.selectWorkflow === '33-4-5' ||
      this._settingsService.selectWorkflow === '33-3'
    ) {
      console.log('33');
      const request = this._settingsService.useMocksForPos
        ? this._http
            .get<IPosResponse>(
              '/assets/mocks/pos-injector/calculateTransactionTotals.json'
            )
            .toPromise()
        : PosInjectorService.getInstance().calculateTransactionTotals(
            this._settingsService.posInjectorPath,
            PosElogHandler.getInstance().posConfig
          );

      return request.then((response: IPosResponse) => {
        if (
          !response ||
          (!response.Success &&
            (response.ReturnCode as any) !== '0' &&
            response.ReturnCode !== 0)
        ) {
          console.log(response);
          throw new Error(response.ReturnMessage);
        }
        return response;
      });
    }
  }

  // TODO: use PosElogHandler from dotsdk to set configuration.
  // I will add what we are configuring by default
  /**
   * Sets POS configuration
   * The PosConfig is the object that will be sent in POS transaction
   * Contains PosHeader config and PosOrderConfig
   */
  createPosConfiguration(orderStartTime: Date): PosConfig {
    const posHeaderConfig = PosElogHandler.getInstance().posConfig.posHeader;
    posHeaderConfig.currentLanguageCode = this._language;
    posHeaderConfig.cvars = {};
    posHeaderConfig.receiptText =
      '<@CenterOn><@DoubleOn><@BoldOn>BURGER KING<@BoldOff><@DoubleOff><@CenterOff>';

    return PosElogHandler.getInstance().posConfig;
  }

  /**
   * Retrieve current refInt
   */
  getOrderNumber(): number {
    return this._orderNumber;
  }

  /**
   * Used to initialize the an order session
   */
  async initializeOrder(orderStartTime: Date) {
    // TODO: Enable back Sentry
    this.createPosConfiguration(orderStartTime);
    Sentry.setContext('order', {
      id: PosElogHandler.getInstance().posConfig.posHeader.orderRefInt,
    });
  }

  /**
   * displays a pop-up on payment processing error
   * @param message message to display in the pop-up
   * @param retryFunction callback on retry
   */
  openErrorPopup(message: string, retryFunction?: () => void): void {
    this._navigationService.popStack();
    this._popupService.open(
      retryFunction
        ? {
            component: MessagePopUpComponent,
            componentData: message,
            leftButtonContent: this._translationService.translate(32, 'no'),
            leftButtonCallback: () => {
              this._popupService.close();
              this.voidOrderOnPos();
            },
            rightButtonContent: this._translationService.translate(33, 'yes'),
            rightButtonCallback: () => {
              this._popupService.close();
              retryFunction();
            },
          }
        : {
            component: MessagePopUpComponent,
            componentData: message,
            leftButtonContent: this._translationService.translate(57, 'close'),
            leftButtonCallback: () => {
              this._popupService.close();
              this.voidOrderOnPos();
              this.resetOrder();
              this._navigationService.resetStack();
            },
          }
    );
  }

  /**
   * Request to pay an order
   */
  async payOrder() {
    // we are setting this inside dotsdk
    // this._currentPosConfiguration.posHeader.payStartTime = new Date();

    const isPaymentAvailable = await this._peripheralsService.isPaymentAvailable();

    if (!isPaymentAvailable) {
      throw new Error('Payment is not available');
    }

    const amount = PosElogHandler.getInstance().posConfig.posHeader.amounts
      .amountsTotalPaid;
    const orderPosNumber =
      PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber ||
      PosElogHandler.getInstance().posConfig.posHeader.orderRefInt.toString();

    console.log(orderPosNumber);

    const request = this._settingsService.useMocksForPos
      ? this._http
          .get('assets/mocks/atp-pay/pay.json')
          .pipe(delay(3000))
          .toPromise()
      : this._atpPaymentService.pay(amount, orderPosNumber);

    const paymentResult: IPayResponse = await request.then(
      (response: IPayResponse) => {
        if (response && response.PaidAmount === amount) {
          return response;
        }
        throw new Error(
          `Invalid paid amount. Expected: ${amount}, received: ${
            response && response.PaidAmount
          }`
        );
      }
    );

    return paymentResult;
  }

  /**
   * Print order ticket
   */
  async printOrder(
    receiptText?: string,
    includePayment?: boolean
  ): Promise<void> {
    const printerStatus = await this._peripheralsService.getPrinterStatus();
    if (!printerStatus) {
      throw new Error("Can't connect to the printer");
    }
    if (!this._settingsService.useMocksForPos) {
      await this._atpPrinterService.print(
        includePayment
          ? receiptText.replace(
              '<@PartialCut>',
              '<@PaymentCustomerReceipt>\r\n\r\n<@PartialCut>'
            )
          : receiptText
      );
    }
  }

  /**
   * try to process payment using POS
   * @param isRetry has the payment already been processed once or not
   */
  async processPayment(paymentType: PaymentType, isRetry = false) {
    this.paymentStatus = PaymentSteps.Proceed;

    switch (paymentType) {
      case PaymentType.COUNTER:
        if (this._tentNumber) {
          this._navigationService.addStack(this.satCounterPage);
        } else {
          this._navigationService.addStack(this.counterPaymentPage);
        }
        break;
      case PaymentType.REMOTE:
        this._navigationService.addStack(this.remotePaymentPage);
        break;
    }

    const runPaymentFailed = (errorMessage: string, allowRetry: boolean) => {
      this._navigationService.popStack();
      this.paymentStatus = PaymentSteps.Idle;
      this.openErrorPopup(
        errorMessage,
        allowRetry
          ? () => {
              this.processPayment(paymentType, true).catch(() => undefined);
            }
          : null
      );
    };

    const paymentErrorMessage = this._translationService.translate(
      53,
      'Sorry has error has occurred, please place your order at the counter'
    );

    const paymentFailedMessage = this._translationService.translate(
      52,
      'Your payment failed, do you want to retry?'
    );

    await retry(
      async () => {
        return this.testConnection().then((response) => {
          // this.addOperation(response, 'Test connection');
          return response;
        });
      },
      { retries: 3 }
    ).catch((error) => {
      // this.addOperation(error, 'Test connection');
      runPaymentFailed(paymentErrorMessage, false);
      throw error;
    });

    // If the payment failed on the payment and the OrderPosNumber has been set by OpenOrderFunction
    // the processPayment() function don't use calculatePosTotalPrice() and sendOpenOrderToPos() functions

    if (!PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber) {
      this.paymentStatus = PaymentSteps.Waiting;
      if (
        this._settingsService.selectWorkflow === '33-4-5' ||
        this._settingsService.selectWorkflow === '33-3'
      ) {
        await retry(
          async () => {
            return this.calculatePosTotalPrice();
          },
          { retries: 3 }
        )
          .then((response: IPosResponse) => {
            if (
              !this._settingsService.useMocksForPos &&
              response.SubtotalCents + response.TaxCents !==
                this._basketService.totalPrice
            ) {
              throw new Error(
                `Invalid calculate totals amount. Expected: ${
                  this._basketService.totalPrice
                }, received: ${response.SubtotalCents + response.TaxCents}`
              );
            }
            // this.addOperation(response, 'Calculate POS total success');
            PosElogHandler.getInstance().posConfig.posHeader.amounts = {
              ...PosElogHandler.getInstance().posConfig.posHeader.amounts,
              taxAmount: response.TaxCents,
              subtotalAmount: response.SubtotalCents,
              amountsTotalPaid: response.TaxCents + response.SubtotalCents,
            };
            this._sessionService._paymentPrice =
              response.TaxCents + response.SubtotalCents;
          })
          .catch((error) => {
            // this.addOperation(error, 'Calculate POS total failed');
            runPaymentFailed(paymentErrorMessage, false);
            throw error;
          });
      } else {
        console.log('Skip calculate totals');
        PosElogHandler.getInstance().posConfig.posHeader.updateWith({
          firstItemAddedTime: this._basketService.firstBasketProductDate,
        });
        PosElogHandler.getInstance().posConfig.posHeader.amounts = {
          ...PosElogHandler.getInstance().posConfig.posHeader.amounts,
          taxAmount: 0,
          subtotalAmount: this._basketService.totalPrice,
          amountsTotalPaid: this._basketService.totalPrice,
        };
        this._sessionService._paymentPrice = this._basketService.totalPrice;
      }
      if (
        this._settingsService.selectWorkflow === '33-4-5' ||
        this._settingsService.selectWorkflow === '4-5'
      ) {
        await retry(
          async () => {
            console.log('4');
            return this.sendOpenOrderToPos();
          },
          { retries: 3 }
        )
          .then((response) => {
            // this.addOperation(response, 'Open order success');
            this._orderNumber = response.OrderPOSNumber;
            PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber =
              response.OrderPOSNumber;

            if ((this._settingsService.selectWorkflow = '4-5')) {
              PosElogHandler.getInstance().posConfig.posHeader.amounts = {
                ...PosElogHandler.getInstance().posConfig.posHeader.amounts,
                taxAmount: response.TaxCents,
                subtotalAmount: response.SubtotalCents,
                amountsTotalPaid: response.TaxCents + response.SubtotalCents,
              };
              this._sessionService._paymentPrice =
                response.TaxCents + response.SubtotalCents;
            }
          })
          .catch((error) => {
            // this.addOperation(error, 'Open order failed');
            runPaymentFailed(paymentErrorMessage, false);
            throw error;
          });
        this.paymentStatus = PaymentSteps.Proceed;
      }
    } else {
      this.paymentStatus = PaymentSteps.Proceed;
      console.log(
        'Already opened order with id:',
        PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber
      );
    }

    if (paymentType === PaymentType.REMOTE) {
      let paymentDate: Date;
      const paymentResponse = await retry(
        async () => {
          return this.payOrder();
        },
        { retries: 1 }
      )
        .then(async (response) => {
          console.log(response);
          paymentDate = new Date();
          PosElogHandler.getInstance().posConfig.posHeader.operations.push({
            deviceType: PosOperationDevicesTypes.PAY,
            time: paymentDate,
            id: '1',
            name: 'PAY 1',
            operation: 'Payment success',
            status: 1,
            code: 0,
          });
          PosElogHandler.getInstance().posConfig.posHeader.amounts = {
            ...PosElogHandler.getInstance().posConfig.posHeader.amounts,
            tenders: [
              {
                paymentMediaId: response.TenderMediaId,
                type: PosTenderType.CARD,
                paidAmount: response.PaidAmount,
                paid: PosPaidState.PAID,
              },
            ],
          };
          // this._currentPosConfiguration.posHeader.payEndTime = new Date();
          await this._recommendationService.updateOrder({
            paymentDate,
            state: OrderStateEnum.PAID,
            paidAmount: response.PaidAmount,
            taxesAmount: PosElogHandler.getInstance().posConfig.posHeader.amounts.taxAmount
          });
          return response;
        })
        .catch(async (error) => {
          console.log(error);
          paymentDate = new Date();
          // TODO: Add distinct errors on payment failed.
          PosElogHandler.getInstance().posConfig.posHeader.operations.push({
            deviceType: PosOperationDevicesTypes.PAY,
            time: paymentDate,
            id: '1',
            name: 'PAY 1',
            operation: 'Payment error',
            status: 0,
            code: 1,
          });
          this.paymentStatus = PaymentSteps.Failure;
          await this._recommendationService.updateOrder({
            paymentDate,
            state: OrderStateEnum.PAYMENT_FAILED,
            paidAmount: PosElogHandler.getInstance().posConfig.posHeader.amounts.amountsTotalPaid,
            taxesAmount: PosElogHandler.getInstance().posConfig.posHeader.amounts.taxAmount
          });
          throw error;
        });

      this.paymentStatus = PaymentSteps.Waiting;

      const receipt = await retry(
        async () => {
          return this._settingsService.selectWorkflow === '33-4-5' ||
            this._settingsService.selectWorkflow === '4-5'
            ? this.tenderOrderOnPos()
            : this.sendCompleteOrder();
        },
        { retries: 3 }
      )
        .then((response) => {
          this._orderNumber = response.OrderPOSNumber;
          PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber =
            response.OrderPOSNumber;
          // this.addOperation(response, 'Tender order success');
          // this._currentPosConfiguration.posHeader.posEndTime = new Date();
          return response.Receipt;
        })
        .catch(async (error) => {
          // this.addOperation(error, 'Tender order failed');
          await this.unlockOrderOnPos().catch(() => undefined);
          await this.printOrder(
            '<@CenterOn><@DoubleOn><@BoldOn>BURGER KING<@BoldOff><@DoubleOff><@CenterOff><@PartialCut>'
          );
          // TODO: Add printing error prevention.
          throw error;
        });

      await this.printOrder(
        receipt,
        paymentResponse.HasClientReceipt
      ).catch((error) => console.log(error));
    } else {
      await this.printOrder(
        '<@CenterOn><@DoubleOn><@BoldOn>BURGER KING<@BoldOff><@DoubleOff><@CenterOff><@PartialCut>'
      );
    }

    this.paymentStatus = PaymentSteps.Success;

    setTimeout(() => {
      this.resetOrder();
      this._navigationService.resetStack();
    }, 1000 * 15);
  }

  /**
   * Clear order
   */
  resetOrder(): void {
    this.paymentStatus = null;
    sessionEndEvent.emit(null);
    // TODO: Add sentry back
    if (PosElogHandler.getInstance().posConfig.posHeader.orderRefInt) {
      Sentry.setContext('order', {
        id: PosElogHandler.getInstance().posConfig.posHeader.orderRefInt,
      });
    }
  }

  /**
   * Request to close an opened order on POS
   */
  async sendCompleteOrder(): Promise<IPosResponse> {
    // this._currentPosConfiguration.posHeader.updateWith({
    //   functionTypeNumber: PosFunctions.SendCompleteOrder,
    // });
    console.log('3');
    console.log(PosElogHandler.getInstance().posConfig);
    const request = this._settingsService.useMocksForPos
      ? this._http
          .get('assets/mocks/pos-injector/sendCompleteOrderToPos.json')
          .toPromise()
      : PosInjectorService.getInstance().sendCompleteOrderToPos(
          this._settingsService.posInjectorPath,
          PosElogHandler.getInstance().posConfig
        );

    return request.then((response: IPosResponse) => {
      if (
        !response ||
        (!response.Success &&
          (response.ReturnCode as any) !== '0' &&
          response.ReturnCode !== 0)
      ) {
        console.log(response);
        throw new Error(response.ReturnMessage);
      }
      return response;
    });
  }

  /**
   * Request to open a new order on POS; that operation must be followed by tender order or void order
   */
  async sendOpenOrderToPos() {
    // this._currentPosConfiguration.posHeader.updateWith({
    //   functionTypeNumber: PosFunctions.SendOpenOrder,
    // });

    console.log('4');
    console.log(PosElogHandler.getInstance().posConfig);

    const request = this._settingsService.useMocksForPos
      ? this._http
          .get('assets/mocks/pos-injector/sendOpenOrderToPos.json')
          .pipe(delay(3000))
          .toPromise()
      : PosInjectorService.getInstance().sendOpenOrderToPos(
          this._settingsService.posInjectorPath,
          PosElogHandler.getInstance().posConfig
        );

    return request.then((response: IPosResponse) => {
      if (
        !response ||
        (!response.Success &&
          (response.ReturnCode as any) !== '0' &&
          response.ReturnCode !== 0)
      ) {
        console.log(response);
        throw new Error(response.ReturnMessage);
      }
      return response;
    });
  }

  /**
   * Request to close an opened order on POS
   */
  async tenderOrderOnPos(): Promise<IPosResponse> {
    // this._currentPosConfiguration.posHeader.updateWith({
    //   functionTypeNumber: PosFunctions.SendTenderOrder,
    // });

    if (
      this._settingsService.selectWorkflow === '33-4-5' ||
      this._settingsService.selectWorkflow === '4-5'
    )
      console.log('5');

    const request = this._settingsService.useMocksForPos
      ? this._http
          .get('assets/mocks/pos-injector/tenderOrderOnPos.json')
          .pipe(delay(3000))
          .toPromise()
      : PosInjectorService.getInstance().tenderOrderOnPos(
          this._settingsService.posInjectorPath,
          PosElogHandler.getInstance().posConfig
        );

    return request.then((response: IPosResponse) => {
      if (
        !response ||
        (!response.Success &&
          (response.ReturnCode as any) !== '0' &&
          response.ReturnCode !== 0)
      ) {
        console.log(response);
        throw new Error(response.ReturnMessage);
      }
      return response;
    });
  }

  /**
   * Test connection to pos
   */
  async testConnection(): Promise<IPosTestConnectionResponse> {
    const request = this._settingsService.useMocksForPos
      ? this._http
          .get<{ [key: string]: any; Success: boolean }>(
            'assets/mocks/pos-injector/testConnection.json'
          )
          .toPromise()
      : this._posInjectorService.testConnection(
          this._settingsService.posInjectorPathTest,
          this._settingsService.kioskId
        );

    return request.then((response: IPosTestConnectionResponse) => {
      if (
        !response ||
        (!response.Success &&
          (response.ReturnCode as any) !== '0' &&
          response.ReturnCode !== 0)
      ) {
        console.log(response);
        throw new Error(response.ReturnMessage);
      }
      return {
        ...response,
        Success: true,
      };
    });
  }

  /**
   * Transform basket content to POS injectable products
   */
  transformBasket(buttons: DotButton[]): IPosProduct[] {
    const transformedButtons = buttons.map((button) => {
      const buttonType: DotProductType = button.hasCombos
        ? DotProductType.COMBO
        : DotProductType.ITEM;

      const transformedButton = button.mapToPosProduct(
        buttonType,
        this._language
      );

      if (buttonType === DotProductType.COMBO) {
        // Check if the product contains combo products
        const products = this._comboService.getComboSteps(button);

        transformedButton.Products = products
          .map((product) => {
            // Get selected combo products
            const comboSelectedProduct = this._comboService.getSelectedProduct(
              product
            );
            if (!comboSelectedProduct) return;
            // Change it to IPosProduct
            const posSelected = comboSelectedProduct.mapToPosProduct(
              DotProductType.COMBO_ITEM,
              this._language
            );

            const products: IPosProduct[] = this._db.hasModifiers(
              comboSelectedProduct
            )
              ? this.transformModifiersToPos(comboSelectedProduct)
              : [];

            // Prevent from duplication
            if (transformedButton.Link !== posSelected.Link) {
              products.unshift(posSelected);
            }

            return products;
          })
          .reduce((current, products: IPosProduct[]) => {
            if (products) {
              return current.concat(products);
            }
            return current;
          }, []);
      }
      return transformedButton;
    });

    return transformedButtons;
  }

  /**
   * Transform modifiers to pos products
   */
  transformModifiersToPos(product: DotButton): IPosProduct[] {
    // Get products modifiers
    const modifiers = this._modifiersService.getAllSelectedModifiers(
      product.ModifiersPage.Modifiers
    );

    if (!modifiers) {
      return [];
    }

    return modifiers.map((modifier) => {
      const posModifier = modifier.mapToPosProduct(
        DotProductType.ITEM,
        this._language
      );
      return posModifier;
    });
  }

  /**
   * Request to unlock an order to front counter
   */
  async unlockOrderOnPos() {
    // this._currentPosConfiguration.posHeader.updateWith({
    //   functionTypeNumber: PosFunctions.UnlockOrder,
    // });
    console.log('32');
    const request = this._settingsService.useMocksForPos
      ? this._http
          .get('assets/mocks/pos-injector/unlockOrderOnPos.json')
          .toPromise()
      : PosInjectorService.getInstance().unlockOrderOnPos(
          this._settingsService.posInjectorPath,
          PosElogHandler.getInstance().posConfig
        );

    return request.then((response: IPosResponse) => {
      if (
        !response ||
        (!response.Success &&
          (response.ReturnCode as any) !== '0' &&
          response.ReturnCode !== 0)
      ) {
        console.log(response);
        throw new Error(response.ReturnMessage);
      }
      return response;
    });
  }

  /**
   * Request to close an opened order on POS
   */
  async voidOrderOnPos() {
    if (!PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber) {
      return;
    }
    // this._currentPosConfiguration.posHeader.updateWith({
    //   functionTypeNumber: PosFunctions.SendVoidOrder,
    // });
    console.log('6');
    const request = this._settingsService.useMocksForPos
      ? this._http
          .get('assets/mocks/pos-injector/voidOrderOnPos.json')
          .toPromise()
      : PosInjectorService.getInstance().voidOrderOnPos(
          this._settingsService.posInjectorPath,
          PosElogHandler.getInstance().posConfig
        );

    return request.then((response: IPosResponse) => {
      if (
        !response ||
        (!response.Success &&
          (response.ReturnCode as any) !== '0' &&
          response.ReturnCode !== 0)
      ) {
        console.log(response);
        throw new Error(response.ReturnMessage);
      }
      delete PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber;
      return response;
    });
  }

  /**
   * Updates payment status
   */
  private set paymentStatus(paymentStatus: PaymentSteps) {
    this._paymentStatus.next(paymentStatus);
  }
}
