export enum ModalType {
  ALERT = 'alert',
  ALLERGENS = 'allergens',
  ALLERGENS_INFORMATION = 'allergens-information',
  CALORIES = 'calories',
  POPUP = 'popup',
  PRODUCT = 'product',
  RECOMMENDATION_SELECT = 'recommendation-select',
  VOUCHER_CHOICE = 'voucher-choice',
  VOUCHER_MANUAL = 'voucher-manual',
  VOUCHER_SCANNER = 'voucher-scanner',
}
