import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AccessibilityService } from '@core/accessibility';
import { PriceCalculationService } from '@core/acrelec-content';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-multiple-modifier-tile',
  templateUrl: './multiple-modifier-tile.component.pug',
  styleUrls: ['./multiple-modifier-tile.component.scss'],
})
export class MultipleModifierTileComponent implements OnInit {
  @Input() isAddDisabled: boolean;
  @Input() maxQuantity: number;
  @Input() name: string;
  @Input() picture: string;
  @Input() price: string;
  @Input() quantity: number;
  @Input() selected: boolean;
  @Input() buttonDisabled: boolean;

  @Output() onAdd = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  constructor(
    private _priceService: PriceCalculationService,
    private _sessionService: AccessibilityService,
    private _t: TranslationService
  ) {}

  ngOnInit() {}

  /**
   * Checks if accessibility mode is active
   */
  private get isAccessibilityActivated(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }

  /**
   * Add modifier to the ordered product
   */
  private add(): void {
    if (this.isAddDisabled) return;
    this.onAdd.emit();
  }

  /**
   * Add modifier to the ordered product
   */
  private buttonClick(event: Event): void {
    this.add();
  }

  /**
   * Remove modifier from the ordered product
   */
  private remove(): void {
    this.onRemove.emit();
  }
}
