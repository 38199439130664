import { DotButton, DotModifier } from 'dotsdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModifiersService } from './modifiers.service';

export class NavigationModifierService {
  private static readonly NO_STEP = -1;
  private _currentModifiers: DotModifier;
  private _step: number;
  private _modifiersLength: number;
  private _modifiers: DotModifier[];
  private _buttonsSubject: BehaviorSubject<null>;

  constructor(
    private _button: DotButton,
    private _modifierService: ModifiersService
  ) {
    this._step = 0;
    this._modifiers = this._button.ModifiersPage.Modifiers;
    this._buttonsSubject = new BehaviorSubject(null);
    this._modifiersLength = this._modifiers.length - 1;
    this._currentModifiers = this._modifiers[this._step];
  }

  /**
   * Return an [[Observable]] that provides the buttons list for the subscriber
   * @return [[Observable]] to subscribe to
   */
  get buttonsObservable(): Observable<DotButton[]> {
    return this._buttonsSubject.pipe(
      map((_) => {
        const modifierButtons = this.getModifierButtons(this._step);
        let sortedModifiers = this._modifierService.sortByName(modifierButtons);
        sortedModifiers = this._modifierService.sortByPrice(modifierButtons);
        return sortedModifiers;
      })
    );
  }

  /**
   * Returns modifier
   */
  get currentModifierObservable(): Observable<DotModifier> {
    return this._buttonsSubject.pipe(
      map((_) => {
        const modifier = this._modifiers[this._step];
        return modifier;
      })
    );
  }

  get currentModifierButtons(): DotButton[] {
    return this._currentModifiers.Buttons;
  }

  get isFirstStep(): boolean {
    return this._step <= 0;
  }

  get isLastStep(): boolean {
    return this._step >= this._modifiersLength;
  }

  /**
   * @returns true if maximum number of modifiers has been selected in group
   */
  get isMaxModifiersInGroup(): boolean {
    const modifiersGroup = this._modifiers[this._step];
    return this._modifierService.isGroupMaxQuantity(modifiersGroup);
  }

  /**
   * @returns true if minimum number of modifiers is selected in group
   */
  get isLessThanMinModifiersInGroup(): boolean {
    const modifiersGroup = this._modifiers[this._step];
    return this._modifierService.isLessThanGroupMinQuantity(modifiersGroup);
  }

  /**
   * Decrease the modifier's quantity if possible
   * @param modifier the modifier to remove
   */
  decreaseModifier(modifier: DotButton): void {
    const modifiersGroup = this._modifiers[this._step];
    this._modifierService.decreaseModifier(modifier, modifiersGroup);
  }

  /**
   * Returns modifier's buttons
   */
  getModifierButtons(step: number): DotButton[] {
    return this._modifiers[step].Buttons;
  }

  /**
   * Increase the modifier's quantity if possible
   * @param modifier the modifier to add
   */
  increaseModifier(modifier: DotButton): void {
    const modifiersGroup = this._modifiers[this._step];
    this._modifierService.increaseModifier(modifier, modifiersGroup);
  }

  /**
   * Navigates forward in the modifiers navigation.
   * @returns 0 if navigation cannot continue forwards, else the step value
   */
  nextStep(): number {
    if (this.isLastStep) return 0;
    this._step++;
    this.updateButtons();
    return this._step;
  }

  /**
   * Navigates backward in the modifiers navigation.
   * @returns 0 if navigation cannot continue backwards, else the step value
   */
  previousStep(): number {
    if (this.isFirstStep) return 0;
    this._step--;
    this.updateButtons();
    return this._step;
  }

  /**
   * Reset all the modifiers in the combo product
   */
  resetModifiers() {
    this._modifierService.resetModifierButtons(this._modifiers);
  }

  /**
   * Update displayed modifiers
   */
  updateButtons() {
    this._buttonsSubject.next(null);
  }
}
