import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule as AccessibilityCoreModule } from '@core/accessibility';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { SessionModule } from '@core/session';
import { SliderModule } from '@ui/slider';

import { AccessibilityBannerComponent } from './components/accessibility-banner/accessibility-banner.component';
import { AccessibilityButtonComponent } from './components/accessibility-button/accessibility-button.component';

@NgModule({
  declarations: [AccessibilityBannerComponent, AccessibilityButtonComponent],
  imports: [
    AccessibilityCoreModule,
    AcrelecExternalModule,
    CommonModule,
    SessionModule,
    SliderModule,
    InlineSVGModule,
  ],
  exports: [AccessibilityBannerComponent, AccessibilityButtonComponent],
})
export class AccessibilityModule {}
