import {
  Component,
  EventEmitter,
  Input,
  Output,
  AfterViewChecked,
} from '@angular/core';
import { DotButton } from 'dotsdk';

import { DotButtonService } from '@core/acrelec-content';
import { SessionService } from '@core/session';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.pug',
  styleUrls: ['./order-header.component.scss'],
})
export class OrderHeaderComponent implements AfterViewChecked {
  @Input() currentNavItem: number;
  @Input() menuCaption: string;
  @Input() navigationButtons: DotButton[];
  @Output('onNavigationItemClick') onNavigationItemClick = new EventEmitter<{
    button: DotButton;
    index: number;
  }>();

  private readonly ARROW_WIDTH = 0;
  private readonly MAX_WIDTH = 1080;
  private readonly NAV_BAR_ID = 'NavBar';
  private readonly NAV_ITEM_CLASS = 'Other';
  private readonly NAV_SCROLL_MAX_WIDTH = this.MAX_WIDTH - this.ARROW_WIDTH;
  private readonly NAV_ITEM_WIDTH = 190;
  private readonly REVELATOR_ID = 'Revelator';
  private _language: string;

  constructor(
    private _db: DotButtonService,
    private _sessionService: SessionService,
    private _t: TranslationService
  ) {
    this._language = _t.language;
  }

  ngAfterViewChecked() {
    this.scrollClickedButton(this.currentNavItem);
  }
  /**
   * Used to get the selected item
   * @param index item menu
   */
  private isSelected(index: number) {
    return this.currentNavItem === index;
  }
  /**
   * Used to navigate to the main item
   */
  private onMenuClick() {
    this.onNavigationItemClick.emit({ button: null, index: -1 });
  }
  /**
   * Used to navigate between items in the navbar
   * @param button
   * @param index
   */
  private onNavItemClick(button: DotButton, index: number) {
    this.onNavigationItemClick.emit({ button, index });
  }

  private scrollClickedButton(index?: number) {
    // As menu does not have index, we change other's element index to give it an index 0
    const currentItemIndex = index === undefined ? 0 : index + 1;
    const scrollContainer = document.getElementById(this.NAV_BAR_ID);
    // get current nav item's position, using the fixed size and index
    const elementPosition = this.NAV_ITEM_WIDTH * currentItemIndex;
    // get current nav item's middle
    const elementMiddle = elementPosition + this.NAV_ITEM_WIDTH / 2;
    // scroll until item middle is at the half of the screen (540)
    const scrollOffset = elementMiddle - this.NAV_SCROLL_MAX_WIDTH / 2;
    scrollContainer.scrollTo({
      left: scrollOffset,
      top: 0,
      behavior: 'smooth',
    });
    // Cursor event
    this.smoothMove(currentItemIndex, index);
  }

  /**
   * Produces a smooth motion effect from a position to an item
   * @param currentItemIndex index of the selected element
   */
  private smoothMove(
    currentItemIndex: number,
    previousIndex: number | undefined
  ) {}
}
