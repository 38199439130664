import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { BasketModule } from '@core/basket';
import { RecommendationModule } from '@core/recommendation';
import { SessionModule } from '@core/session';

@NgModule({
  declarations: [],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    BasketModule,
    CommonModule,
    RecommendationModule,
    SessionModule
  ],
})
export class SuggestiveSellingModule { }
