import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { AllergensModule } from '@core/allergens';
import { AnimationsModule } from '@core/animations';
import { NavigationModule } from '@core/navigation';
import { PaymentModule } from '@core/payment';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { AccessibilityModule as AccessibilityUiModule } from '@ui/accessibility';
import { CaloriesModule } from '@ui/calories';
import { PopUpModule } from '@ui/pop-up';

import { ServiceComponent } from './components/service.component';

@NgModule({
  declarations: [ServiceComponent],
  imports: [
    AccessibilityModule,
    AccessibilityUiModule,
    AcrelecExternalModule,
    AllergensModule,
    AnimationsModule,
    CaloriesModule,
    CommonModule,
    InlineSVGModule,
    NavigationModule,
    PaymentModule,
    PopUpModule,
    SessionModule,
    TranslationModule,
  ],
  exports: [ServiceComponent],
})
export class ServiceModule {}
