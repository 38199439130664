import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AppSettingsService } from '@app/core/acrelec-external';
import { AccessibilityService } from '@core/accessibility';
import {
  DotButtonService,
  PriceCalculationService,
} from '@core/acrelec-content';
import { AllergensService } from '@core/allergens';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUpService } from '@ui/pop-up';

@Component({
  selector: 'app-combo-list',
  templateUrl: './combo-list.component.pug',
  styleUrls: ['./combo-list.component.scss'],
})
export class ComboListComponent implements OnInit {
  @Input() buttons: DotButton[];
  @Input() comboBasePrice: number;
  @Input() comboMealPrice: number;
  @Input() isSideStep: boolean;
  @Output() onProductSelection = new EventEmitter<DotButton>();

  private _language: string;

  constructor(
    private _db: DotButtonService,
    private _t: TranslationService,
    private _sessionService: AccessibilityService,
    private _popUpService: PopUpService,
    private _priceService: PriceCalculationService,
    private _allergensService: AllergensService,
    private _appSettingsService: AppSettingsService
  ) {}

  ngOnInit(): void {
    this._language = this._t.language;
  }

  /**
   * Display an allergen alert
   * @param button
   */
  private displayAllergensAlert(button: DotButton): void {
    this._popUpService.open({
      component: MessagePopUpComponent,
      componentData: this._t.translate(
        39,
        'Please note this product contains one or several allergens that you selected'
      ),
      rightButtonContent: this._t.translate(28, 'continue'),
      rightButtonCallback: () => {
        this._popUpService.close();
        this.onProductSelection.emit(button);
      },
      leftButtonContent: this._t.translate(23, 'cancel'),
      leftButtonCallback: () => {
        this._popUpService.close();
      },
    });
  }

  private getPrice(button): string | undefined {
    const price = this._priceService.getPrice(button);
    const isFree = this.comboBasePrice + price <= this.comboMealPrice;
    if (!this._appSettingsService.enableGapPriceCalculation) {
      if (this.isSideStep && isFree) {
        return undefined;
      }
      return this._priceService.getDisplayPrice(button);
    } else {
      const lowerPriceItem = this.buttons
        .map((btn) => this._priceService.getPrice(btn))
        .sort((a, b) => a - b);
      if (price <= lowerPriceItem[0]) {
        return undefined;
      }
      const difference = ((price - lowerPriceItem[0]) / 100).toFixed(2);
      return difference.toString();
    }
  }

  /**
   * Displays an alert if the selected product contains a selected allergens, otherwise emits an event
   * @param button The selected product
   */
  private onSelection(button: DotButton): void {
    if (this._allergensService.checkButtonAllergen(button)) {
      this.displayAllergensAlert(button);
    } else {
      this.onProductSelection.emit(button);
    }
  }
}
