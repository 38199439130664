import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-single-key',
  templateUrl: './single-key.component.pug',
  styleUrls: ['./single-key.component.scss']
})
export class SingleKeyComponent implements OnInit {
  @Input() keyValue?: string;
  @Output() emitKeyValue: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
