import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AccessibilityModule } from '@core/accessibility';
import { AnimationsModule } from '@core/animations';

import { BkButtonComponent } from './components/bk-button/bk-button.component';
import {
  OrderTitleComponent,
  BackButtonComponent,
} from './components/order-title';
import { QuantityButtonsComponent } from './components/quantity-buttons/quantity-buttons.component';
import { TileButtonComponent } from './components/tile-button/tile-button.component';

@NgModule({
  declarations: [
    BackButtonComponent,
    BkButtonComponent,
    QuantityButtonsComponent,
    OrderTitleComponent,
    TileButtonComponent,
  ],
  imports: [
    AccessibilityModule,
    AnimationsModule,
    CommonModule,
    InlineSVGModule,
  ],
  exports: [
    BackButtonComponent,
    BkButtonComponent,
    QuantityButtonsComponent,
    OrderTitleComponent,
    TileButtonComponent,
  ],
})
export class SharedModule { }
