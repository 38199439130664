import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecContentModule } from '@core/acrelec-content';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { RecommendationModule } from '@core/recommendation';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { PopUpModule } from '@ui/pop-up';

@NgModule({
  declarations: [],
  imports: [
    AcrelecContentModule,
    AcrelecExternalModule,
    CommonModule,
    PopUpModule,
    RecommendationModule,
    SessionModule,
    TranslationModule,
  ],
})
export class BasketModule { }
