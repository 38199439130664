import {
  AfterViewInit,
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-bk-button',
  templateUrl: './bk-button.component.pug',
  styleUrls: ['./bk-button.component.scss'],
})
export class BkButtonComponent implements AfterViewInit, OnInit {
  @Input() buttonText: string;
  @Input() buttonType: string;
  @Input() disabled = false;
  @Input() leftIconPath: string | null;
  @Input() rightIconPath: string | null;
  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  /**
   * Return the style of the button
   */
  private get style(): any {
    if (this.buttonType === 'brown') {
      return {
        'background-color': 'var(--bk-brown)',
      };
    } else if (this.buttonType === 'orange') {
      return {
        'background-color': 'var(--bk-flamming-orange)',
      };
    }
    return {
      'background-color': 'transparent',
      border: '4rem solid var(--bk-white)',
    };
  }

  /**
   * Emit click event
   */
  click(): void {
    this.buttonClick.emit();
  }
}
