import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SessionService } from '@app/core/session';
import { AccessibilityService } from '@core/accessibility';
import { AppSettingsService } from '@core/acrelec-external';
import { Animations } from '@core/animations/';
import { Modal, ModalType, ModalService } from '@core/modal';
import { NavigationService } from '@core/navigation';
import { TranslationService } from '@core/translation';
import { CountdownPopUpComponent, PopUpService } from '@ui/pop-up';
import { KeyboardService } from '@ui/voucher-manual-modal';

// TODO: fix fadeOut animation which is not working
@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('* => *', [useAnimation(Animations.pageTransition)]),
    ]),
    trigger('hideOverlay', [
      transition(':leave', [
        useAnimation(Animations.fadeOut, { params: { time: '400ms' } }),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  private _inactivityTimeoutHandle: number;

  constructor(
    private _accessibility: AccessibilityService,
    private _modalService: ModalService,
    private _appSettings: AppSettingsService,
    private _navigationService: NavigationService,
    private _popupService: PopUpService,
    private _router: Router,
    private _settings: AppSettingsService,
    private _sessionService: SessionService,
    private _t: TranslationService
  ) {
    this._settings.initialize();
  }

  // Start a timeout when user is not interacting with the window
  @HostListener('window:click')
  @HostListener('document:mousemove')
  @HostListener('document:mousedown')
  @HostListener('document:keypress')
  @HostListener('document:keydown')
  @HostListener('document:touchend')
  @HostListener('document:touchmove')
  @HostListener('document:touchstart')
  startScreenSaverTimeout(): void {
    this._inactivityTimeoutHandle = this.timeout;
  }

  ngOnInit(): void {
    this._inactivityTimeoutHandle = this.timeout;
    this._router.events.subscribe(() => {
      this._inactivityTimeoutHandle = this.timeout;
    });
    this._sessionService.resetSession$.subscribe((reset) => {
      if (reset) {
        this._settings.setupSentry().catch(console.error);
      }
    });
  }

  prepareRoute(outlet: RouterOutlet): any {
    if (!this._appSettings.disabledOrangeTransition) {
      return (
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData.animation
      );
    } else {
      return false;
    }
  }

  private get allergens$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.ALLERGENS);
  }

  private get calories$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.CALORIES);
  }

  private get voucherChoice$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.VOUCHER_CHOICE);
  }

  private get voucherManual$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.VOUCHER_MANUAL);
  }

  private get voucherScanner$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.VOUCHER_SCANNER);
  }

  private get overlay$(): Observable<boolean> {
    const combinedObservables$: Observable<boolean> = combineLatest(
      this._modalService.modal$.pipe(
        map((modal: Modal) => (modal ? true : false))
      ),
      this._modalService.popup$,
      (modal: boolean, popup: boolean) => {
        return modal || popup;
      }
    );
    return combinedObservables$;
  }

  private get popUp$(): Observable<boolean> {
    return this._modalService.popup$;
  }

  private get product$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.PRODUCT);
  }

  private get recommendationSelect$(): Observable<Modal | null> {
    return this.filterModalEvents(ModalType.RECOMMENDATION_SELECT);
  }

  private get timeout(): number | null {
    if (this._inactivityTimeoutHandle) {
      window.clearTimeout(this._inactivityTimeoutHandle);
    }
    if (this.isTimeoutEnabled()) {
      return this.createInactivityTimeout();
    }
    return null;
  }

  private createInactivityTimeout(): number {
    const inactivityWarningTimer = this._settings.inactivityWarningTimer;
    const inactivityCloseTimer = this._settings.inactivityCloseTimer;
    const inactivityTimeoutHandle = window.setTimeout(() => {
      if (!this.isTimeoutEnabled()) {
        this.startScreenSaverTimeout();
        return;
      }
      if (this._router.url !== '/') {
        this._popupService.open({
          component: CountdownPopUpComponent,
          componentData: {
            message: this._t.translate(55, 'Are you still here?'),
            countdownMaxValue: inactivityCloseTimer,
            countdownCallback: (): void => {
              this._popupService.close();
              // setting a timeout to let the overlay fade out
              setTimeout(() => {
                this.cancelOrderSession();
              }, 400);
            },
          },
          rightButtonContent: this._t.translate(33, 'yes'),
          rightButtonCallback: (): void => {
            this._popupService.close();
            this.startScreenSaverTimeout();
          },
          leftButtonContent: this._t.translate(5, 'cancel order'),
          leftButtonCallback: (): void => {
            this._popupService.close();
            // setting a timeout to let the overlay fade out
            setTimeout(() => {
              this.cancelOrderSession();
            }, 400);
          },
        });
      }
    }, inactivityWarningTimer);
    return inactivityTimeoutHandle;
  }

  private cancelOrderSession(): void {
    this._navigationService.resetStack();
  }

  private filterModalEvents(type: ModalType): Observable<Modal | null> {
    return this._modalService.modal$.pipe(
      filter((modal: Modal | null) => {
        if (!modal || modal.type === type) return true;
        return false;
      })
    );
  }

  private isTimeoutEnabled(): boolean {
    const timerEnabledRoutes = ['/service', '/order', '/order/review'];
    const currentUrl = this._router.url;
    if (timerEnabledRoutes.includes(currentUrl)) return true;
    if (currentUrl === '/payment') return true;
    if (currentUrl === '/sat') return true;
    return false;
  }

  private resetTimeout(): void {
    if (this._popupService.isCountdown) {
      this._popupService.close();
      this.startScreenSaverTimeout();
    }
  }
}
