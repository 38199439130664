import { Component, Input, OnInit } from '@angular/core';

import { PopUpContentComponent } from '../pop-up-content/pop-up-content.component';

@Component({
  selector: 'app-message-pop-up',
  templateUrl: './message-pop-up.component.pug',
  styleUrls: ['./message-pop-up.component.scss'],
})
export class MessagePopUpComponent implements OnInit, PopUpContentComponent {
  @Input()
  data: string;

  constructor() {}

  ngOnInit(): void {}
}
