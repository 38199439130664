import { Component, Input } from '@angular/core';

import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-basket-total-price',
  templateUrl: './basket-total-price.component.pug',
  styleUrls: ['./basket-total-price.component.scss']
})
export class BasketTotalPriceComponent {
  @Input() totalPrice: number;

  constructor(
    private _t: TranslationService,
  ) {}
}
