import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton, DotButtonType } from 'dotsdk';
import _ from 'lodash';
import { Observable } from 'rxjs';

import {
  AvailabilityService,
  ComboService,
  ContentValidationService,
  DotButtonService,
} from '@app/core/acrelec-content';
import { AppSettingsService, ContentService } from '@app/core/acrelec-external';
import { DropdownService } from '@app/core/dropdown';
import { ModalService, ModalType } from '@app/core/modal';
import { NavigationService } from '@app/core/navigation';
import { PopUpService } from '@app/ui/pop-up';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-recommendation-select',
  templateUrl: './recommendation-select.component.pug',
  styleUrls: ['./recommendation-select.component.scss'],
})
export class RecommendationSelectComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Input() products: DotButton[];
  @Input() initialProduct: DotButton;
  constructor(
    private _t: TranslationService,
    private _comboService: ComboService,
    private _contentService: ContentService,
    private _dotButtonService: DotButtonService,
    private _db: DotButtonService,
    private _availabilityService: AvailabilityService,
    private _contentValidationService: ContentValidationService,
    private _appSettings: AppSettingsService,
    private _navigationService: NavigationService,
    private _popUpService: PopUpService,
    private _dropdownService: DropdownService,
    private _modalService: ModalService
  ) {}

  /**
   * Add a product
   * @param product
   */
  private addProduct(product: DotButton) {
    this.setAction(product);
  }

  /**
   * Close the modal
   */
  private close() {
    this.closeModal.emit();
  }

  /**
   * Performs a different action in the navigation depending on the button type
   * @param button button on which the action will be based
   */
  private setAction(button: DotButton): void {
    switch (button.ButtonType) {
      case DotButtonType.MENU_BUTTON:
        this.setItemAction(button);
        break;
      case DotButtonType.ITEM_BUTTON:
        this.setItemAction(button);
        break;
      default:
        break;
    }
  }

  private setItemAction(button: DotButton): void {
    const productCopy = _.cloneDeep(button);
    this._modalService.closeModal();
    setTimeout(() => {
      this._modalService.setModal({
        type: ModalType.PRODUCT,
        data: productCopy,
      });
    });
  }
}
