import { Injectable } from '@angular/core';
import { DotButton, DotBanner } from 'dotsdk';
import * as _ from 'lodash';

import { CustomAllergen } from '@core/allergens';

@Injectable({
  providedIn: 'root'
})
export class ContentValidationService {

  constructor() { }

  /**
   * Check if an allergen is valid and has all informations need and is in the correct format
   * @param allergen allergen to check
   */
  isAllergenValid(allergen: CustomAllergen): boolean {
    const label = allergen.Label ? allergen.Label as any : null;
    return !!allergen.Name && !allergen.Name.endsWith('_m') && !!label && !!label.DEF;
  }

  /**
   * Check if an allergen is valid and has all informations need
   * @param banner banner to check
   */
  isBannerValid(banner: DotBanner): boolean {
    return !!banner.Image && this.isFileExtensionValid(banner.Image, ['mp4', 'ogg', 'jpeg', 'jpg', 'png']);;
  }

  /**
   * Check if a button is valid and has all informations need
   * @param button button to check
   */
  isButtonValid(button: DotButton): boolean {
    return this.isLinkValid(button) && this.isButtonVisible(button);
  }

  /**
   * Check if a button should be display
   * @param button button to check
   */
  isButtonVisible(button: DotButton): boolean {
    return button.Enabled === true && button.Visible === true;
  }

  /**
   * Check if a button in a dropdown is valid
   * @param button button to check
   */
  isDropdownValid(button: DotButton): boolean {
    return this.isButtonValid(button) && this.isPriceValid(button);
  }

  /**
   *
   * @param file file to check
   * @param wantedExtention array of authorized extensions
   */
  isFileExtensionValid(file: string, wantedExtention: string[]): boolean {
    if (!file) {
      return false;
    }
    const fileExtention = file.split('.').pop();
    return wantedExtention.some(extention =>
      extention.toLowerCase() === fileExtention.toLowerCase()
    );
  }

  /**
   * Check if a button link is valid
   * @param button button that contains the link to check
   */
  isLinkValid(button: DotButton): boolean {
    return typeof button.Link === 'string' && Number(button.Link) > 0;
  }

  /**
   * Check if a button price is valid
   * @param button button that contains the price to check
   */
  isPriceValid(button: DotButton): boolean {
    const price = button.MinPrice;
    if (!price) {
      return false;
    }
    return typeof price === 'number' && price > 0;
  }
}
