import { Injectable } from '@angular/core';
import {
  DotAllergen,
  DotAllergensAndNutritionalValues,
  DotAllergensLoader,
  DotBanner,
  DotBannersLoader,
  DotDPSPages,
  DotPage,
  DotPromo,
  DotPromoPagesLoader,
  DotTreeLoader,
  DotTree,
  FilesLoaderService,
  DotI18n,
  DotI18nLoader,
  DotButton,
  DotCatalogLoader,
} from 'dotsdk';
import * as _ from 'lodash';

import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public readyForUpdate = false;
  public _dotTree: DotTree;
  public firstStart = true;
  private _dotAllergens: DotAllergensAndNutritionalValues;
  private _dotBanners: DotBanner[];
  private _hiddenPages: DotPage[];
  private _internationalization: DotI18n[];
  private _promotionData: DotDPSPages;

  constructor(private appSettings: AppSettingsService) {
    // Listen for any update from DOTXIX-DataDeliveryService App:

setTimeout(() => {
  this.readyForUpdate = true
}, 30000)

    FilesLoaderService.getInstance()
      .listenForUpdates()
      .subscribe((response) => {
        if (
          response.Source.Context === 'Acrelec.DotXIX' &&
          response.Message.data_changed
        ) {
          if (this.firstStart) {
            this.firstStart = false;
            this.initialize();
          } else {
            this.readyForUpdate = true;
          }
        }
      });
  }

  /**
   * Returns application's main page
   */
  get mainPage(): DotPage {
    return this._dotTree.MainPage;
  }

  /**
   * Returns hidden pages
   */
  get hiddenPages(): DotPage[] {
    return this._hiddenPages;
  }

  /**
   * Returns internationalization's file data
   */
  get internationalization(): DotI18n[] {
    return this._internationalization;
  }

  /**
   * Returns all promotion's data
   */
  get promotionData(): DotDPSPages {
    return this._promotionData;
  }

  /**
   * Returns a list of promotions
   */
  get promotionList(): DotPromo[] | undefined {
    if (!this._promotionData) return undefined;
    return this._promotionData.PromoList;
  }

  /**
   * Returns main promo's page
   */
  get promotionPage(): DotPage | undefined {
    const hiddenPages = this.hiddenPages;
    const promoPage = hiddenPages.find((page) => page.PageType === 'MainPromo');
    if (!promoPage) return undefined;
    return _.cloneDeep(promoPage);
  }

  /**
   * Returns a list of all possible allergens
   */
  getAllergens(): DotAllergen[] {
    const allergens = this._dotAllergens.Allergens;
    const visibleAllergens = allergens.filter(
      (allergen) => !_.endsWith(allergen.Name, '_m')
    );
    return _.orderBy(visibleAllergens, ['Name'], ['asc']);
  }

  /**
   * Returns banners depending on wanted type
   * @param type Type of banner that could be : Slide, Banner, InOutBanner, MenuBanner
   */
  getBannersData(type?: string): DotBanner[] {
    if (type) {
      return this._dotBanners.filter(
        (banner) => banner.BannerType === type && banner.Active
      );
    }
    return this._dotBanners;
  }

  /**
   * @param productLink the product's Link
   * @returns the product found if any
   */
  getCatalogProductByLink(productLink: string): DotButton | undefined {
    const catalog = DotCatalogLoader.getInstance().loadedModel;
    return catalog.Buttons.find((button) => button.Link === productLink);
  }

  /**
   * Please keep in mind this is a demo!
   * For an easier demo, we search recursivly each time a page is in need.
   * You will not want to recursively check each time!
   * @param buttonLink the DotButton's jsut pressed Link
   */
  getPageByButtonLink(buttonLink: string): DotPage | undefined {
    return this.getPage(this._dotTree.MainPage, buttonLink);
  }

  /**
   * Please keep in mind this is a demo!
   * For an easier demo, we search recursivly each time a page is in need.
   * You will not want to recursively check each time!
   * @param buttonLink the DotButton's jsut pressed Link
   */
  getProductByLink(link: string): DotButton | undefined {
    return this.getButton(this._dotTree.MainPage, link);
  }

  /**
   * This function will get load in App Intializa phase, so the content will be ready when the App gets rendered
   */
  async initialize(): Promise<boolean> {
    // In example below, FilesLoaderService will register proper loaders ALL default .json files.
    FilesLoaderService.getInstance().registerDefaultLoaders(
      this.appSettings.acreBridgeAssets
    );

    // The Actual Files Load call:
    // As FilesLoaderService uses Singleton Pattern, you may use your models anywhere in your App
    await FilesLoaderService.getInstance().initialize();

    // Retrive data from DotTreeLoader (loader for pages.json)
    this._dotTree = DotTreeLoader.getInstance().loadedModel;
    this._internationalization = DotI18nLoader.getInstance().loadedModel;
    // Retrieve banners
    this._hiddenPages = this._dotTree.HiddenPages;
    console.log(DotBannersLoader.getInstance());
    this._dotBanners = DotBannersLoader.getInstance().loadedModel;
    // Retrieve allergens
    this._dotAllergens = DotAllergensLoader.getInstance().loadedModel;
    this._promotionData = DotPromoPagesLoader.getInstance().loadedModel;
    return true;
  }

  /**
   * Will search recursively for a DotButton based on Link
   * @param page
   * @param id
   */
  private getButton(page: DotPage, link: string, parent?: string): DotButton | undefined {
    if (page.Buttons) {
      for (const button of page.Buttons) {
        if (button.Link === link) {
          (button as any).parent = parent;
          return button;
        } else if (button.Page && button.Page.Buttons) {
          const buttonResult = this.getButton(button.Page, link, button.Link);
          if (buttonResult) {
            return buttonResult;
          }
        }
      }
    }
    return undefined;
  }

  /**
   * Will search recursively for a Page based on ID
   * @param page page that contains the set of pages to search
   * @param id id of the page we're looking for
   * @returns the page if found, or null if not found
   */
  private getPage(page: DotPage, id: string): DotPage | undefined {
    if (page.ID === id) {
      return page;
    }
    if (page.Buttons) {
      for (const button of page.Buttons) {
        if (button.Page) {
          const pageResult = this.getPage(button.Page, id);
          if (pageResult) {
            return pageResult;
          }
        }
      }
    }
    return undefined;
  }
}
