import { Injectable } from '@angular/core';
import { PosServingLocation, DotServiceLocationType } from 'dotsdk';

import { AppSettingsService } from './app-settings.service';


@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  constructor(private _appSettingsService: AppSettingsService) {
  }

  /**
   * @param location location value can be EatIn or TakeOut
   * check service type availability
   */
  public isServiceLocationAvailable(location: DotServiceLocationType): boolean {
    // Casting the enum variable in a number because TypeScript does weird stuff with the switch
    switch (+location) {
      case DotServiceLocationType.EAT_IN:
        if (this._appSettingsService.serviceType === PosServingLocation.OUT) {
          return false;
        }
        break;
      case DotServiceLocationType.TAKE_AWAY:
        if (this._appSettingsService.serviceType === PosServingLocation.IN) {
          return false;
        }
        break;
    }
    return true;
  }
}
