import {
  animateChild,
  query,
  trigger,
  transition,
  useAnimation,
} from '@angular/animations';
import { Component } from '@angular/core';

import { AccessibilityService } from '@core/accessibility';
import { Animations } from '@core/animations';
import { ModalService } from '@core/modal';
import { PromotionsService } from '@core/promotions';
import { ScannerService } from '@core/scanner';
import { TranslationService } from '@core/translation';
import { MessagePopUpComponent, PopUp, PopUpService } from '@ui/pop-up';

@Component({
  selector: 'app-voucher-scanner',
  templateUrl: './voucher-scanner.component.pug',
  styleUrls: ['./voucher-scanner.component.scss'],
  animations: [
    trigger('displayModal', [
      transition(':enter', [
        useAnimation(Animations.inflateModal, { params: { time: '300ms' } }),
        query('@displayChild', animateChild({ delay: '0' })),
      ]),
    ]),
    trigger('displayChild', [
      transition(':enter', [
        useAnimation(Animations.showModalChild, {
          params: { time: '300ms' },
        }),
      ]),
    ]),
  ],
})
export class VoucherScannerComponent {
  private scannerUnavailablePopUp: PopUp = {
    component: MessagePopUpComponent,
    componentData: this._t.translate(90, 'The scanner is momentarily unavailable'),
    leftButtonContent: this._t.translate(24, 'close'),
    leftButtonCallback: () => {
      this._popupService.close();
      this.cancel();
    },
  }

  private barCodeInvalidPopUp: PopUp = {
    component: MessagePopUpComponent,
    componentData: this._t.translate(91, 'The voucher is invalid'),
    leftButtonContent: this._t.translate(24, 'close'),
    leftButtonCallback: () => {
      this._popupService.close();
      this.cancel();
    },
    rightButtonContent: this._t.translate(70, 'retry'),
    rightButtonCallback: () => {
      this._popupService.close();
      this.startScanning();
    }
  }

  constructor(
    private _accessibility: AccessibilityService,
    private _modalService: ModalService,
    private _scannerService: ScannerService,
    private _promoService: PromotionsService,
    private _popupService: PopUpService,
    private _t: TranslationService,
  ) {
    this.startScanning();
  }

  /**
   * Close voucher scanner modal
   */
  private cancel(): void {
    this._modalService.closeModal();
  }

  /**
   * Check if scanner is available, then get the bar code from scanner then display promo product
   * Display a popup in case of error, which retry function starts scanning again
   */
  private async startScanning(): Promise<void> {
    const isScannerAvailable = await this._scannerService.isScannerAvailable();
    if (!isScannerAvailable) {
      this._popupService.open(this.scannerUnavailablePopUp)
      return;
    }
    const barCode = await this._scannerService.startScan();
    if (!barCode) {
      this._popupService.open(this.barCodeInvalidPopUp)
    }
    this._promoService.displayPromotion(barCode, this.startScanning);
  }
}
