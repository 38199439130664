import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';

import { ContentValidationService } from '@app/core/acrelec-content';


@Pipe({
  name: 'dropdownContent',
  pure: true
})
export class DropdownContentPipe implements PipeTransform {

  constructor(private _contentValidationService: ContentValidationService) {}

  /**
   * Returns a list of buttons filtered by the validity of their picture and file extension
   */
  transform(buttons: DotButton[]): DotButton[] {
    return buttons.filter(button => this._contentValidationService.isDropdownValid(button));
  }

}
