import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';


import { AppSettingsService } from '@core/acrelec-external'
import { TranslationService } from '@core/translation'

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {
  private _locale: string;
  private _currency: string;

  constructor(
    private _appSettingsService: AppSettingsService,
    private _translationService: TranslationService
  ) {
    this._currency = _appSettingsService.currency;
    this._locale = _translationService.locale;
  }

  transform(
    value: number,
    digitsInfo: string = '1.2-2',
  ): string | null {
    return formatCurrency(
      value,
      this._locale,
      getCurrencySymbol(this._currency, 'narrow'),
      this._currency,
      digitsInfo,
    );
  }

}
