import { Component, OnInit } from '@angular/core';

import { AccessibilityService } from '@app/core/accessibility';
import { TranslationService } from '@app/core/translation';

@Component({
  selector: 'app-payment-waiting',
  templateUrl: './payment-waiting.component.pug',
  styleUrls: ['./payment-waiting.component.scss'],
})
export class PaymentWaitingComponent implements OnInit {
  constructor(
    private _sessionService: AccessibilityService,
    private _t: TranslationService
  ) {}

  ngOnInit(): void {}

  /**
   * Check if PMR mode is enabled
   */
  private get accessibilityStatus(): boolean {
    return this._sessionService.isAccessibilityActivated;
  }
}
