import { Component, EventEmitter, Output } from '@angular/core';
import { DotButton, DotButtonType } from 'dotsdk';
import _ from 'lodash';
import { Observable } from 'rxjs';

import {
  AvailabilityService,
  ComboService,
  ContentValidationService,
  DotButtonService,
} from '@app/core/acrelec-content';
import { AppSettingsService, ContentService } from '@app/core/acrelec-external';
import { DropdownService } from '@app/core/dropdown';
import { ModalService, ModalType } from '@app/core/modal';
import { NavigationService } from '@app/core/navigation';
import { PopUpService } from '@app/ui/pop-up';
import { RecommendationSalesService } from '@core/suggestive-selling';
import { TranslationService } from '@core/translation';
import { RecommendationService } from '@app/core/recommendation';

@Component({
  selector: 'app-recommendation-selling',
  templateUrl: './recommendation-selling.component.pug',
  styleUrls: ['./recommendation-selling.component.scss'],
})
export class RecommendationSellingComponent {
  @Output() closeModal = new EventEmitter<void>();

  constructor(
    private _recommendationSalesService: RecommendationSalesService,
    private _recommendationService: RecommendationService,
    private _t: TranslationService,
    private _comboService: ComboService,
    private _contentService: ContentService,
    private _dotButtonService: DotButtonService,
    private _db: DotButtonService,
    private _availabilityService: AvailabilityService,
    private _contentValidationService: ContentValidationService,
    private _appSettings: AppSettingsService,
    private _navigationService: NavigationService,
    private _popUpService: PopUpService,
    private _dropdownService: DropdownService,
    private _modalService: ModalService
  ) {}

  /**
   * Return recommendation suggestions
   */
  private get recommendations$(): Observable<DotButton[]> {
    return this._recommendationSalesService.productBasket$;
  }

  /**
   * Add a product
   * @param product
   */
  private addProduct(product: DotButton) {
    this.setAction(product);
  }

  /**
   * Close the modal
   */
  private close() {
    this.closeModal.emit();
  }

  /**
   * Performs a different action in the navigation depending on the button type
   * @param button button on which the action will be based
   */
  private setAction(button: DotButton): void {
    switch (button.ButtonType) {
      case DotButtonType.MENU_BUTTON:
        this.setItemAction(button);
        break;
      case DotButtonType.ITEM_BUTTON:
        this.setItemAction(button);
        break;
      default:
        break;
    }
  }

  private setItemAction(button: DotButton): void {
    const parentPage = this._contentService.getPageByButtonLink(
      (button as any).parent
    );

    const productCopy = _.cloneDeep(button);

    this._modalService.closeModal();

    if (parentPage && this._dotButtonService.isDropdownPage(parentPage)) {
      setTimeout(() => {
        this._modalService.setModal({
          type: ModalType.RECOMMENDATION_SELECT,
          data: {
            initialProduct: productCopy,
            products: parentPage.Buttons.map((button) => this._recommendationService.getCatalogValues(button)),
          },
        });
      });
    } else {
      setTimeout(() => {
        this._modalService.setModal({
          type: ModalType.PRODUCT,
          data: productCopy,
        });
      });
    }
  }
}
