import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AvailabilityService } from '@core/acrelec-content/services/availability/availability.service';


@Pipe({
  name: 'buttonAvailability'
})
export class ButtonAvailabilityPipe implements PipeTransform {

  constructor(
    private _availabilityService: AvailabilityService
  ){}

  /**
   * Returns a list of buttons filtered by their availability
   */
  transform(buttons: DotButton[]): DotButton[] {
    return buttons && buttons.length > 0
      ? buttons.filter(button => this._availabilityService.isButtonAvailable(button) === true)
      : [];
  }

}
