import { Injectable } from '@angular/core';

import { AppSettingsService } from '@app/core/acrelec-external';
import { SessionService } from '@core/session';

@Injectable({
  providedIn: 'root',
})
export class SatKeyboardService {
  public _keys: any[] = [
    ['1', '2', '3', '4', '5'],
    ['6', '7', '8', '9', '0'],
  ];
  private _tentNumber = '';

  constructor(
    private _sessionService: SessionService,
    private _appSettings: AppSettingsService
  ) {
    // Subscribes to the session observable and reset tent number on session reset
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) this._tentNumber = '';
    });
  }

  /**
   * Get current barcode
   */
  get tentNumber(): number | undefined {
    if (!this._tentNumber.length) return undefined;
    return parseInt(this._tentNumber, 10);
  }

  /** Get keyboard keys */
  get keyboard() {
    return this._keys;
  }

  /**
   * Add pressed key to barcode
   * @param keyPress key that has been pressed
   */
  addKeyPress(keyPress: string): void {
    if (
      this._tentNumber.length + keyPress.length <=
      this._appSettings.digitsNumberSatCommand
    ) {
      this._tentNumber = this._tentNumber + keyPress;
    }
  }

  /**
   * Remove last character from barcode
   */
  deleteLastKeyPress(): void {
    if (this._tentNumber.length > 0) {
      this._tentNumber = this._tentNumber.slice(0, -1);
    }
  }

  /**
   * Remove barcode
   */
  clearTentNumber(): void {
    this._tentNumber = '';
  }
}
