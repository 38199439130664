import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcrelecExternalModule } from '@core/acrelec-external';
import { TranslationModule } from '@core/translation';

@NgModule({
  declarations: [],
  imports: [AcrelecExternalModule, CommonModule, TranslationModule],
})
export class AcrelecContentModule {}
