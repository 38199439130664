import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { isEqual, pick } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { AnimationsService } from '@app/core/animations/services/animations.service';
import { AppSettingsService, ContentService } from '@core/acrelec-external';
import { ModalService } from '@core/modal';
import { SessionService } from '@core/session';

import { Page } from '../types/Page';
import { ScreenType } from '../types/ScreenType';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _pageStack: Page[] = [];
  private _navSubject: BehaviorSubject<Page>;

  constructor(
    private _animation: AnimationsService,
    private _contentService: ContentService,
    private _modalService: ModalService,
    private _router: Router,
    private _sessionService: SessionService,
    private _appSettingsService: AppSettingsService
  ) {
    this._navSubject = new BehaviorSubject(this._pageStack.last());
    // Subscribes to the session observable and reset stack on session reset
    this._sessionService.resetSession$.subscribe((reset: boolean) => {
      if (reset) this.resetStack();
    });
  }

  /**
   * @returns true if there is only one order page in the stack
   */
  get isNewOrderStack(): boolean {
    const orderStack = this._pageStack.filter(
      (page) => page.type === ScreenType.ORDER_AREA
    );
    return orderStack.length && orderStack.length === 1;
  }

  /**
   * If the stack is empty, we recreate the initial navigation stack with the ScreenSaver page
   * @returns returns an Observable to which a component can subscribe
   */
  get navigationStack(): Observable<Page> {
    if (!this._pageStack.length) {
      this._pageStack = this.initialStack;
      this.updateSubscribers();
      this.navigateRouter(null, this._pageStack.last());
    }
    return this._navSubject.asObservable();
  }

  /**
   * Adds a page to the navigation stack and updates all subscribers
   * @param page The page to add to the stack
   */
  addStack(page: Page) {
    const previousPage = this._pageStack.last();
    if (isEqual(page, previousPage)) return;

    Sentry.setContext('page', null);
    Sentry.setContext('page', {
      contentType: page.type,
      content: pick(page.content, [
        'ID',
        'PageType',
        'Caption',
        'Link',
        'Name',
      ]),
    });

    Sentry.setContext('last_page', null);
    Sentry.setContext('last_page', {
      contentType: page.type,
      content: pick(page.content, [
        'ID',
        'PageType',
        'Caption',
        'Link',
        'Name',
      ]),
    });

    this._pageStack.push(page);
    this.updateSubscribers();
    this.navigateRouter(previousPage, this._pageStack.last());
  }

  /**
   * Pops the last page from the navigation stack and updates all subscribers
   * If we are navigating inside order-area, we force the animation to display
   */
  popStack() {
    const previousPage = this._pageStack.pop();
    const newPage = this._pageStack.last();
    if (!this.orderAreaTransition(previousPage, newPage)) {
      this.navigateRouter(previousPage, newPage);
      this.updateSubscribers();
    }
  }

  /**
   * Reset the navigation stack to the home page
   */
  resetHome() {
    this.resetStack({
      type: ScreenType.ORDER_AREA,
      content: this._contentService.mainPage,
    });
  }

  /**
   * Reset the navigation stack to the screensaver or to the page given as parameter
   * @param page Optional page to reset the navigation stack with
   */
  resetStack(page?: Page) {
    const previousPage = this._pageStack.last();
    this._pageStack = this.initialStack;
    if (page) {
      this._pageStack.push(page);
    }
    this._modalService.closeModal();
    this._modalService.closePopUp();
    this.navigateRouter(previousPage, this._pageStack.last());
    this.updateSubscribers();
    this.reloadApp();
  }

  /**
   * Returns the initial stack: the ScreenSaver page.
   */
  private get initialStack(): Page[] {
    return [
      {
        type: ScreenType.SCREENSAVER,
        content: null,
      },
    ];
  }

  /**
   * If we are navigation inside the order-area, we force the page transition to display.
   * @param previousPage The page we are navigating from
   * @param newPage  The page we are navigating to
   */
  private orderAreaTransition(previousPage: Page, newPage: Page): boolean {
    if (
      previousPage.type === ScreenType.ORDER_AREA &&
      newPage.type === ScreenType.ORDER_AREA
    ) {
      if (!this._appSettingsService.disabledOrangeTransition) {
        this._animation.pageSwitchTransition();
        setTimeout(() => {
          // Animation delay
          this.navigateRouter(previousPage, newPage);
          this.updateSubscribers();
        }, 400);
        return true;
      } else {
        this.navigateRouter(previousPage, newPage);
        this.updateSubscribers();
        return true;
      }
    }
    return false;
  }

  /**
   * Update all subscribers with the last page in the navigation stack.
   */
  private updateSubscribers() {
    this._navSubject.next(this._pageStack.last());
  }

  /**
   * Navigates from a page to another using the Angular router.
   * @param from The page we are navigating from
   * @param to The page we are navigating to
   */
  private navigateRouter(from: Page | null, to: Page) {
    if (!to) this.resetStack();
    switch (to.type) {
      case ScreenType.SERVICE:
        this._router.navigate(['/service']);
        break;
      case ScreenType.ORDER_REVIEW:
        this._router.navigate(['/order/review']);
        break;
      case ScreenType.ORDER_AREA:
        this._router.navigate(['/order']);
        break;
      case ScreenType.PAYMENT:
        this._router.navigate(['/payment']);
        break;
      case ScreenType.PAYMENT_COUNTER:
        this._router.navigate(['/payment/counter']);
        break;
      case ScreenType.PAYMENT_REMOTE:
        this._router.navigate(['/payment/remote']);
        break;
      case ScreenType.SERVICE_AT_TABLE:
        this._router.navigate(['/sat']);
        break;
      case ScreenType.SERVICE_AT_TABLE_COUNTER:
        this._router.navigate(['/sat/counter']);
        break;
      case ScreenType.SERVICE_AT_TABLE_FLAG_NUMBER:
        this._router.navigate(['/sat/flag-number']);
        break;
      case ScreenType.SCREENSAVER:
      default:
        this._router.navigate(['/']);
        break;
    }
  }

  private reloadApp() {
    console.log('reload', this._contentService.readyForUpdate);
    if (this._contentService.readyForUpdate && !this._contentService.firstStart) {
      location.reload();
    }
  }
}
