import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';

import { AvailabilityService } from '@core/acrelec-content';
import { ContentService } from '@core/acrelec-external';

@Pipe({
  name: 'navigationAvailabilityPipe'
})
export class NavigationAvailabilityPipe implements PipeTransform {

  constructor(
    private _contentService: ContentService,
    private _availabilityService: AvailabilityService) { }

  /**
   * Returns a list of buttons filtered by the validity of their link and their availability
   */
  transform(buttons: DotButton[]): DotButton[] {
    if (!buttons || !buttons.length) {
      return [];
    }
    const validButtons = buttons.filter(button => {
      const isNavbarButtonAvailable = this._availabilityService.isNavbarButtonAvailable(button)
      const mainPageButton = this._contentService.getProductByLink(button.Link);
      const isMainPageButtonAvailable = this._availabilityService.isButtonAvailable(mainPageButton)
      return isNavbarButtonAvailable && isMainPageButtonAvailable;
    });
    return validButtons;
  }

}
