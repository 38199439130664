import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessibilityModule } from '@core/accessibility';
import { AcrelecExternalModule } from '@core/acrelec-external';
import { ModalModule } from '@core/modal';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { PipesModule } from '@pipes';
import { SharedModule } from '@ui/shared';

import { BasketProductComponent } from './components/basket-product/basket-product.component';
import { BasketProductsComponent } from './components/basket-products/basket-products.component';
import { BasketRecommendationComponent } from './components/basket-recommendation/basket-recommendation.component';
import { BasketTotalPriceComponent } from './components/basket-total-price/basket-total-price.component';

@NgModule({
  declarations: [
    BasketProductComponent,
    BasketProductsComponent,
    BasketTotalPriceComponent,
    BasketRecommendationComponent,
  ],
  imports: [
    AcrelecExternalModule,
    AccessibilityModule,
    CommonModule,
    ModalModule,
    PipesModule,
    SessionModule,
    SharedModule,
    TranslationModule,
  ],
  exports: [
    BasketProductComponent,
    BasketProductsComponent,
    BasketTotalPriceComponent,
    BasketRecommendationComponent,
  ],
})
export class BasketModule { }
