import { Injectable } from '@angular/core';
import {
  ComboStepType,
  DotButton,
  DotCombo,
  DotComboPage,
  DotButtonType,
} from 'dotsdk';
import * as _ from 'lodash';

import { TranslationService } from '@core/translation';

import { ComboStep } from '../../types/ComboStep';
import { DotButtonService } from '../dot-button.service';
import { ModifiersService } from '../modifiers/modifiers.service';

@Injectable({
  providedIn: 'root',
})
export class ComboService {
  constructor(
    private _db: DotButtonService,
    private _modifiers: ModifiersService,
    private _t: TranslationService
  ) { }

  /**
   * Deselects each items of a combo
   * @param comboStep current combo
   */
  deselectComboStepItems(comboStep: DotCombo): void {
    if (!comboStep) return;
    comboStep.Buttons.forEach((item) => {
      item.Selected = false;
      item.quantity = 0;
    });
  }

  /**
   * Gets the base price of a combo, disregarding the size
   * @param combo current combo
   */
  getAriadnaPrice(combo: DotButton): number | undefined {
    const firstComboPage = combo.ComboPage.Combos.first()
    if (!firstComboPage) return undefined;
    return parseInt(firstComboPage.AriadnaPrice, 10);
  }

  /**
   * Gets the calories to display from main product of a combo
   * @param combo current combo
   */
  getCalories(combo: DotButton): string | null {
    if (!combo) return null;
    const comboCalories = this._db.getCalories(combo);
    if (comboCalories) return comboCalories;

    const mainProduct = this.getMainProduct(combo);
    const calories = this._db.getCalories(mainProduct);
    if (calories) return calories;

    return null;
  }

  /**
   * Returns step wanted by index
   * @param comboButton current combo
   * @param step step index
   */
  getComboStep(comboButton: DotButton, step: number): DotCombo | null {
    const comboSteps = this.getVisibleComboSteps(comboButton);
    const comboPage = comboSteps.find((comboStep) => comboStep.Level === step);
    if (!comboPage) return null;
    return comboPage;
  }

  /**
   * Returns all combo steps, visible and invisible
   * @param comboButton current combo
   * @param step step index
   */
  getComboSteps(comboButton: DotButton): DotCombo[] | undefined {
    if (!comboButton || !comboButton.ComboPage) return undefined;
    return comboButton.ComboPage.Combos;
  }

  /**
   * @param comboButton the container combo DotButton
   * @returns the main product if any, otherwise undefined
   */
  getMainProduct(comboButton: DotButton): DotButton | null {
    const firstStep = this.getMinLevel(comboButton);
    const mainProduct = this.getVisibleComboStepButtons(comboButton, firstStep);
    if (mainProduct.length === 0) return null;
    return mainProduct.first();
  }

  /**
   * Return the smallest Level from all the visible combo steps
   * @param button Entire combo button
   */
  getMinLevel(button: DotButton): number | null {
    const visibleSteps = this.getVisibleComboSteps(button);
    const minStep = visibleSteps.reduce((previous, step) => {
      if (!previous) return step;
      if (step.Level < previous.Level) return step;
      return previous;
    }, null);
    return minStep.Level;
  }

  /**
   * Return the highest Level from all the visible combo steps
   * @param button Entire combo button
   */
  getMaxLevel(button: DotButton): number {
    const visibleSteps = this.getVisibleComboSteps(button);
    const maxStep = visibleSteps.reduce((previous, step) => {
      if (!previous) return step;
      if (step.Level > previous.Level) return step;
      return previous;
    }, null);
    return maxStep.Level;
  }

  /**
   * Gets button's modifiers and transform into readable string them to display them in basket
   * @param button Button containing modifiers
   */
  getModifiersStrings(button: DotButton): string[] | null {
    const fullStrings: string[] = [];
    const comboSteps = this.getVisibleComboSteps(button);
    comboSteps.forEach((comboStep) => {
      const selectedProduct = this.getSelectedProduct(comboStep);
      if (!selectedProduct) return null;

      let fullString = this._db.getCaption(selectedProduct);
      const modifiersStrings = this._modifiers.getModifierStringsFromProduct(
        selectedProduct
      );
      if (modifiersStrings) {
        const separator = ', ';
        fullString += ` ${this._t.translate(51, 'with')} `;

        modifiersStrings.forEach(
          (modifierString: string, index: number, array: string[]) => {
            fullString += modifierString;
            if (index !== array.length - 1) fullString += separator;
          }
        );
      }
      fullStrings.push(fullString);
    });
    if (fullStrings.length === 0) return null;
    return fullStrings;
  }

  /**
   * @param comboSteps Array of all DotCombo steps
   * @returns the selected drink if any, otherwise undefined
   */
  getSelectedDrink(comboSteps: DotCombo[]): DotButton | undefined {
    const combo = comboSteps.find((page) => {
      const stepTitle = page.ComboStepName && page.ComboStepName.toLowerCase();
      return stepTitle === ComboStep.DRINK;
    });
    const side = combo
      ? combo.Buttons.find((button) => button.Selected === true)
      : undefined;
    return side;
  }

  /**
   * @param comboSteps Array of all DotCombo steps
   * @returns the selected sandwich if any, otherwise undefined
   */
  getSelectedSandwich(comboSteps: DotCombo[]): DotButton | undefined {
    const combo = comboSteps.find((page) => {
      const stepTitle = page.ComboStepName && page.ComboStepName.toLowerCase();
      return stepTitle === ComboStep.SANDWICH;
    });
    const side = combo
      ? combo.Buttons.find((button) => button.Selected === true)
      : undefined;
    return side;
  }

  /**
   * @param comboSteps Array of all DotCombo steps
   * @returns the selected side if any, otherwise undefined
   */
  getSelectedSide(comboSteps: DotCombo[]): DotButton | undefined {
    const combo = comboSteps.find((page) => {
      const stepTitle = page.ComboStepName && page.ComboStepName.toLowerCase();
      return stepTitle === ComboStep.SIDE;
    });
    const side = combo
      ? combo.Buttons.find((button) => button.Selected === true)
      : undefined;
    return side;
  }

  /**
   * @param comboStep steps
   * @returns the selected combo step product if any, otherwise null
   */
  getSelectedProduct(comboStep: DotCombo): DotButton | null {
    const selectedProduct = comboStep
      ? comboStep.Buttons.find((button) => button.Selected === true)
      : null;
    return selectedProduct;
  }

  /**
   * As described in DOT19-890, If StartSize is not present, Go to combo element which has ComboStepType equal to ComboUpSize
   * and select from its Buttons[] array the one with the lowest absolute value
   * (Link property may have a negative value, thats why we use lowest absolute value).
   * Link value will be the startSize!
   * @param button button used to find the menu size
   */
  getStartSize(button: DotButton): string | null {
    if (!button) return null;
    let startSize = button.StartSize; // please keep in mind that button can have or NOT StartSize property

    if (startSize) {
      return startSize;
    }
    const upSizeCombo = button.ComboPage.Combos.find(
      (comboStep) => comboStep.ComboStepType === ComboStepType.ComboUpSize
    );
    if (upSizeCombo) {
      const smallestLinkButton = upSizeCombo.Buttons.reduce(
        (previousButton, currentButton) => {
          const currentLink = Math.abs(parseInt(currentButton.Link, 10));
          const previousLink = Math.abs(parseInt(previousButton.Link, 10));
          return currentLink < previousLink ? currentButton : previousButton;
        }, // reducer
        upSizeCombo.Buttons[0] // initial value
      );
      startSize = smallestLinkButton.Link;
    }
    return startSize;
  }

  /**
   * Filter the products based on the menu size
   * @param button the button containing all the combo's data
   * @param comboStepButtons the buttons contained in a combo step
   * @returns the visible step buttons on the selected size
   */
  getVisibleComboStepButtons(
    comboButton: DotButton,
    step: number
  ): DotButton[] | null {
    const size = this.getStartSize(comboButton);
    const comboStep: DotCombo = this.getComboStep(comboButton, step);
    if (!comboStep) return null;
    return this.getVisibleComboButtons(comboStep.Buttons, size)
  }

  /**
   * Filter the products based on the menu size
   * @param comboButtons the buttons contained in a combo step
   * @param size the size of the combo as string
   * @returns the visible step buttons on the selected size
   */
  getVisibleComboButtons(comboButtons: DotButton[], size: string): DotButton[] | null {
    if (!comboButtons) return null;
    return comboButtons.filter((button) => button.VisibleOn === size);
  }

  /**
   * Get invisible combo steps from the Combo button as an array of [[DotCombo]]
   * @param combo the button containing all the combo's data
   */
  getInvisibleComboSteps(combo: DotButton): DotCombo[] {
    if (!combo || !combo.ComboPage) {
      return [];
    }
    return combo.ComboPage.Combos.filter((combo) => !combo.Visible);
  }

  /**
   * Get combo steps from the Combo button as an array of [[DotCombo]]
   * @param button the button containing all the combo's data
   */
  getVisibleComboSteps(button: DotButton): DotCombo[] {
    if (!button || !button.ComboPage) {
      return [];
    }
    const isPriceStep: (combo: DotCombo) => boolean = (comboStep) => {
      if (comboStep.ComboStepID) return comboStep.ComboStepID.endsWith('00');
      return false;
    };
    const isComboStep: (combo: DotCombo) => boolean = (comboStep: DotCombo) => {
      if (comboStep.Title) return comboStep.Title === 'Combo #';
      return false;
    };
    const isUpSizeStep: (combo: DotCombo) => boolean = (
      comboStep: DotCombo
    ) => {
      if (comboStep.ComboStepType === ComboStepType.ComboUpSize) return true;
      return false;
    };
    return button.ComboPage.Combos.filter((combo) => {
      return (
        combo.Visible &&
        !isPriceStep(combo) &&
        !isComboStep(combo) &&
        !isUpSizeStep(combo)
      );
    });
  }

  /**
   * Takes the original combo button as a parameter, selects the correct invisible steps for POS
   * filter the visible steps and initializes the quantity field for default products and modifiers
   * @param combo original button that will be initialized
   */
  initializeCombo(combo: DotButton): void {
    this.selectInvisibleDefaults(combo);
    const visibleSteps = this.getVisibleComboSteps(combo);
    visibleSteps.forEach((comboStep: DotCombo) => {
      const visibleButtons = this.getVisibleComboStepButtons(
        combo,
        comboStep.Level
      );
      visibleButtons.forEach((item: DotButton) => this.selectDefaults(item));
    });
  }

  /**
   * @returns True if the combo step only has a single item
   */
  isSingleItem(comboButton: DotButton, step: number): boolean {
    if (!comboButton) return false;
    const visibleButtons = this.getVisibleComboStepButtons(comboButton, step);
    if (visibleButtons.length === 1) return true;
    return false;
  }

  /**
   * @returns True if the combo step only has a single step
   */
  isSingleStep(comboButton: DotButton): boolean {
    if (!comboButton) return false;
    const comboSteps = this.getVisibleComboSteps(comboButton);
    if (comboSteps.length === 1) return true;
    return false;
  }

  /**
   * @returns True if any combo item is selected for the current combo step
   */
  isItemSelected(comboButton: DotButton, step: number): boolean {
    if (!comboButton) return false;
    const comboStepButtons = this.getVisibleComboStepButtons(comboButton, step);
    if (!comboStepButtons) return false;
    return comboStepButtons.some((button) => button.Selected === true);
  }

  /**
   * Reset all selected products and modifiers for the combo step passed as parameter
   * @param comboStep step to reset
   */
  resetStepButtons(comboStep: DotCombo) {
    comboStep.Buttons.forEach((button) => {
      button.Selected = false;
      button.quantity = 0;
      if (this._db.hasModifiers(button)) {
        this._modifiers.resetModifierButtons(button.ModifiersPage.Modifiers);
      }
    });
  }

  /**
   * Initializes a product to the default quantities for modifiers, etc.
   * @param product the product to select the default for
   */
  selectDefaults(product: DotButton, force?: boolean): void {
    this._db.selectDefaults(product, force);
    if (!this._db.hasModifiers(product)) return;
    this._modifiers.selectDefaults(product.ModifiersPage.Modifiers);
  }

  /**
   * Sets invisible POS steps to selected true
   * @param product the product to select the default for
   */
  selectInvisibleDefaults(combo: DotButton): void {
    if (!combo) return;
    const invisibleSteps = this.getInvisibleComboSteps(combo);
    if (!invisibleSteps) return;
    const size = this.getStartSize(combo);
    invisibleSteps.forEach(step => {
      const buttons = this.getVisibleComboButtons(step.Buttons, size)
      if (buttons && buttons.length === 1) {
        buttons.first().Selected = true;
        buttons.first().quantity = 1;
      }
    })
  }

  /**
   * When selecting a product in a combo step, we need to deselect all other items
   * @param selectedButton item that was selected by the user in combo step
   * @param comboStep current combo step
   */
  selectStepProduct(
    selectedButton: DotButton,
    comboStep: DotCombo,
    size: string
  ) {
    comboStep.Buttons.forEach((button) => {
      if (button.VisibleOn !== size) return;
      if (button.Link === selectedButton.Link) {
        button.Selected = true;
        button.quantity = 1;
      } else {
        button.Selected = false;
        button.quantity = 0;
      }
    });
  }
}
