import { Component, OnInit } from '@angular/core';

import { AppSettingsService } from '@core/acrelec-external';
import { NavigationService, ScreenType } from '@core/navigation';
import { PaymentService } from '@core/payment';
import { TranslationService } from '@core/translation';
import { PopUp, MessagePopUpComponent, PopUpService } from '@ui/pop-up';

import { SatKeyboardService } from '../services/sat-keyboard.service';

@Component({
  selector: 'app-sat-keyboard',
  templateUrl: './sat-keyboard.component.pug',
  styleUrls: ['./sat-keyboard.component.scss']
})
export class SatKeyboardComponent implements OnInit {
  private keys: string[] = []
  private _invalidTentNumberPopUp: PopUp = {
    component: MessagePopUpComponent,
    componentData: this._t.translate(80, 'Invalid tent number'),
    rightButtonContent: this._t.translate(70, 'retry'),
    rightButtonCallback: () => this._popupService.close(),
  };

  constructor(
    private _appSettingsService: AppSettingsService,
    private _navigationService: NavigationService,
    private _paymentService: PaymentService,
    private _popupService: PopUpService,
    private _sat: SatKeyboardService,
    private _t: TranslationService
  ) {
    this.keys = this._sat.keyboard;
  }

  ngOnInit(): void {
  }

  confirmTentNumber(): void {
    const tentNumber = this._sat.tentNumber;
    if (!tentNumber || isNaN(tentNumber)) {
      this._popupService.open(this._invalidTentNumberPopUp);
      return;
    }
    this._paymentService.tentNumber = tentNumber;
    this._navigationService.addStack({
      type: ScreenType.PAYMENT,
      content: null,
    });
  }

}
