import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { AcrelecExternalModule } from '@core/acrelec-external';
import { NavigationModule } from '@core/navigation';
import { PaymentModule } from '@core/payment';
import { SessionModule } from '@core/session';
import { TranslationModule } from '@core/translation';
import { SliderModule } from '@ui/slider';

import { ScreenSaverComponent } from './components/screen-saver.component';

@NgModule({
  declarations: [ScreenSaverComponent],
  imports: [
    AcrelecExternalModule,
    CommonModule,
    InlineSVGModule,
    NavigationModule,
    PaymentModule,
    SessionModule,
    SliderModule,
    TranslationModule,
  ],
  exports: [ScreenSaverComponent],
})
export class ScreenSaverModule {}
