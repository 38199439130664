import { Component, OnInit, Input } from '@angular/core';

import { AppSettingsService } from '@core/acrelec-external';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.pug',
  styleUrls: ['./product-tile.component.scss'],
})
export class ProductTileComponent {
  @Input() calories: string;
  @Input() caption: string;
  @Input() picture: string;

  constructor(
    private _appSettings: AppSettingsService,
    private _t: TranslationService
  ) {}

  private get showCaloriesOnItem(): boolean {
    return this._appSettings.showCaloriesOnItem;
  }
}
