import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessibilityModule } from '@core/accessibility';
import { ModalModule } from '@core/modal';
import { TranslationModule } from '@core/translation';
import { SharedModule } from '@ui/shared';

import { VoucherChoiceComponent } from './components/voucher-choice.component';

@NgModule({
  declarations: [VoucherChoiceComponent],
  imports: [
    AccessibilityModule,
    CommonModule,
    ModalModule,
    SharedModule,
    TranslationModule,
  ],
  exports: [VoucherChoiceComponent],
})
export class VoucherChoiceModule {}
