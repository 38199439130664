import { Component, Output, EventEmitter, Input } from '@angular/core';

import { AppSettingsService } from '@core/acrelec-external';
import { TranslationService } from '@core/translation';

@Component({
  selector: 'app-basket-recommendation',
  templateUrl: './basket-recommendation.component.pug',
  styleUrls: ['./basket-recommendation.component.scss'],
})
export class BasketRecommendationComponent {
  @Input() caption: string;
  @Input() picture: string;
  @Input() price: number;
  @Input() calories: number;
  @Output() buttonAdd = new EventEmitter<void>();

  private isPlusDisabled = false;

  constructor(
    private _appSettings: AppSettingsService,
    private _t: TranslationService
  ) {}

  private get showCaloriesOnItem(): boolean {
    return this._appSettings.showCaloriesOnItem;
  }

  /**
   * Add one more recommendation
   */
  private addOne(): void {
    this.buttonAdd.emit();
  }
}
